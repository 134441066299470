import React, { useEffect, useState } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import {
  useCurrentUser,
  useSiteConfig,
  useStores
} from '@/hooks'
import OptionsEquipmentComponent from '@/views/optionsEquipmentComponent/OptionsEquipmentComponent'
import {
  CELL,
  DEFAULT_LINKS,
  ERROR_MAIN_TEXT,
  USER_ROLES,
  WORK_MODE
} from '@/const'
import { observer } from 'mobx-react'
import InfoContent from '@/views/infoContent'
import { GxSpinner } from '@garpix/garpix-web-components-react'
import useContextStorage from '@/hooks/useContextStorage'
import { useNavigate } from 'react-router-dom'

interface IErrorMessage {
  message: string
  description: string
  btnHandler: () => void
}

const OptionsEquipmentPage = observer(
  (): React.ReactElement => {
    const store = useStores()
    const config = useSiteConfig()
    const navigate = useNavigate()
    const user = useCurrentUser()
    const storage = useContextStorage()
    const [errorMessage, setErrorMessage] =
      useState<null | IErrorMessage>(null)
    const [isSpinnerShow, setIsSpinnerShow] = useState(true)

    const clearErrorMessage = (): void => {
      setErrorMessage(null)
    }

    const createErrorMessage = (
      message: string,
      description: string,
      btnHandler: () => void
    ): void => {
      setErrorMessage({
        message,
        description,
        btnHandler
      })
    }

    /**
     * работает только для USER_ROLES.workman
     */
    const collectQpContentStatus = (): string => {
      if (user.user_equipment_temporarily_renter) {
        return CELL.CONTENT.TEMP_RENT
      }
      return CELL.CONTENT.AVAILABLE
    }

    /**
     * функция для редиректа в зависимости от роли при клике на верхнюю кнопку
     */
    const redirectTopByRole = (): void => {
      if (store.global.userRole === USER_ROLES.workman) {
        navigate(
          `${
            config?.links?.hand_over_in_cell ??
            DEFAULT_LINKS.hand_over_in_cell
          }?content_status=${collectQpContentStatus()}&cell_status=${
            CELL.STATUS.OCCUPIED
          }`
        )
      } else if (
        store.global.userRole === USER_ROLES.serviceman
      ) {
        navigate(config.links.seize_equipment)
      }
    }

    /**
     * функция для редиректа в зависимости от роли при клике на ниэнюю кнопку
     */
    const redirectBottomByRole = (): void => {
      if (store.global.userRole === USER_ROLES.workman) {
        return navigate(
          config?.links?.hand_over_equipment ??
            DEFAULT_LINKS.hand_over_equipment
        )
      }
      if (store.global.userRole === USER_ROLES.serviceman) {
        return navigate(
          config?.links?.hand_over_equipment ??
            DEFAULT_LINKS.hand_over_equipment
        )
      }
    }

    /**
     * Обработчик клика на верхнюю NavLink страницы
     * в зависимости от роли можно делать проверки и пускать на стр или нет
     * @param event
     */
    const handleClickTop = (): void => {
      if (user.role !== null) {
        if (store.global.userRole === USER_ROLES.workman) {
          if (!user.available_working_equipment) {
            createErrorMessage(
              ERROR_MAIN_TEXT.NO_AVALIABLE_EQUIPMENT,
              '',
              clearErrorMessage
            )
            return
          }
        } else if (
          store.global.userRole === USER_ROLES.serviceman
        ) {
          if (!user.available_equipment) {
            createErrorMessage(
              ERROR_MAIN_TEXT.NO_AVALIABLE_EQUIPMENT,
              '',
              clearErrorMessage
            )
            return
          }
        }
      }
      redirectTopByRole()
    }

    /**
     * Обработчик клика на нижнюю NavLink страницы
     * в зависимости от роли можно делать проверки и пускать на стр или нет
     * @param event
     */
    const handleClickBottom = (): void => {
      // return navigate('/test-1')
      if (
        store.global.userRole === USER_ROLES.workman ||
        store.global.userRole === USER_ROLES.serviceman
      ) {
        if (!storage.free_cell) {
          createErrorMessage(
            ERROR_MAIN_TEXT.NO_FREE_CELL,
            '',
            clearErrorMessage
          )
          return
        }
      }
      redirectBottomByRole()
    }
    console.log(
      'config?.links?.page_surrender_of_equipment',
      config?.links?.page_surrender_of_equipment
    )
    // todo: пределать ссылки на те что необходимы для текущей роли
    const content = {
      [USER_ROLES.serviceman]: {
        titleBottom: 'Загрузить оборудование',
        titleTop: 'Изъять оборудование',
        linkBottom:
          config?.links?.hand_over_equipment ??
          DEFAULT_LINKS.hand_over_equipment,
        linkTop:
          config?.links?.seize_equipment ??
          DEFAULT_LINKS.seize_equipment,
        handleClickBtnTop: handleClickTop,
        handleClickBtnBottom: handleClickBottom
      },
      [USER_ROLES.workman]: {
        titleBottom: 'Сдать оборудование',
        titleTop: 'Получить оборудование',
        linkBottom:
          config?.links?.hand_over_equipment ??
          DEFAULT_LINKS.hand_over_equipment,
        linkTop: `${
          config?.links?.hand_over_in_cell ??
          DEFAULT_LINKS.hand_over_in_cell
        }?content_status=${
          CELL.CONTENT.AVAILABLE
        }&cell_status=${CELL.STATUS.OCCUPIED}`,
        handleClickBtnTop: handleClickTop,
        handleClickBtnBottom: handleClickBottom
      },
      [USER_ROLES.engineer]: {
        titleBottom: 'Загрузить оборудование',
        titleTop: 'Изъять оборудование',
        linkBottom:
          config?.links?.hand_over_equipment ??
          DEFAULT_LINKS.hand_over_equipment,
        linkTop:
          config?.links?.cell_list ??
          DEFAULT_LINKS.cell_list,
        handleClickBtnTop: handleClickTop,
        handleClickBtnBottom: handleClickBottom
      }
    }

    useEffect(() => {
      if (store.global.userRole === undefined) {
        createErrorMessage(
          ERROR_MAIN_TEXT.NO_USER,
          '',
          clearErrorMessage
        )
      }
      setIsSpinnerShow(false)
    }, [store.global.userRole])

    /**
     * Костыль. Эта стр используется на месте Verification
     * и при логине за инженером появляется эта стр по
     * этому мы ее скрываем
     */
    if (store.global.userRole === USER_ROLES.engineer) {
      return <>{null}</>
    }

    return (
      <MainLayout visibleLinkBack={false}>
        {isSpinnerShow ||
        (config.work_mode === WORK_MODE.RANDOM &&
          store.global.userRole !==
            USER_ROLES.serviceman)
          ? (
            <GxSpinner />
            )
          : (
            <>
              {errorMessage !== null
                ? (
                  <InfoContent
                    message={errorMessage.message}
                    handlerClickBtn={errorMessage.btnHandler}
                    description={errorMessage.description}
                  />
                  )
                : (
                  <OptionsEquipmentComponent
                    {...content[store.global.userRole]}
                  />
                  )}
            </>
            )}
      </MainLayout>
    )
  }
)

export default OptionsEquipmentPage
