import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useInit,
  useSocketCollectCallbacks,
  AbstractSocket
} from '@/services'
import { runtimeConfig } from '@/config'
import {
  SOCKET_SIGNALS,
  DEFAULT_SETTINGS_PROJECT_NAME,
  TIMEOUT,
  USER_ROLES,
  SIMPLE_STATUSES,
  DEFAULT_LINKS,
  WORK_MODE,
  TIMEOUT_RECONECT
} from '@/const'
import { usePage, useSiteConfig, useStores } from '@/hooks'
import {
  ISignalRedirect,
  ILoginDataEvent,
  IScanDataEvent
} from '@/interfaces/Socket'

// [PAGES]
import LoginPage from '@/pages/login/LoginPage'
import { observer } from 'mobx-react'
// import TemporaryDeliveryEquipmentPage from '@/pages/TemporaryDeliveryEquipmentPage'

const API_WS_URL: string = runtimeConfig.API_WS_URL

interface TProp {
  children: React.ReactNode
}

const Socket: React.FC<TProp> = observer(
  ({ children }): React.ReactElement => {
    const store = useStores() // connect store
    const navigate = useNavigate()
    const page = usePage()
    const config = useSiteConfig() // получение конфигурации и всех необходимых параметров страницы

    const [currentRole, setCurrentRole] = useState<string>() // текущяя роль пользователя ( может обновится данными из сокета )
    const [isAuth, setIsAuth] = useState(false) // флаг для настройки редиректа
    const [isError, setIsError] = useState(false) // флаг для компонента LoginPage ( для подмены контента )
    let absSocket: AbstractSocket | undefined // ссылка на инстанс сокета
    let timer: NodeJS.Timeout
    let timerReconect: NodeJS.Timeout
    // let inclusionRedirect = false // булев флаг для прерывания логики роутинга
    let isPrevLogin = false // булевый флаг для храннения информации о авторизации для последующего сброса

    /**
     * Редирект на начальную для всех страницу броса урла
     */
    const resetURL = (): void => {
      navigate('/')
    }

    /**
     * Сбросит состояние авторизации до дефолтного ожидающего карту
     */
    const resetLogin = (): void => {
      setCurrentRole(undefined)
      setIsError(false)
      setIsAuth(false)
    }

    /**
     * в случае отключения сокета пытаесмся переподключится
     */
    const handleReactivateSocket = (): void => {
      absSocket?.activate()
    }

    // [SOCKET EVENTS]
    const open = (): void => {
      console.log('open')
    }
    const connect = (): void => {
      console.log('connect')
    }
    const disconnect = (): void => {
      console.log('disconnect')
      timerReconect = setTimeout(
        handleReactivateSocket,
        TIMEOUT_RECONECT
      )
    }
    const error = (): void => {
      console.log('error')
    }

    /**
     * редирект на Нужную страницу в зависимости от роли
     * после успешной авторизации
     * @param role
     */
    const redirectByRole = (dataRole: any): void => {
      const {
        user_related_equipment: isAllCellFilled,
        user_role: currentRole,
        selection_mode: selectionMode,
        redirect_url: redirectUrl,
        // available_working_equipment:
        //   availableWorkingEquipment,
        free_cell: freeCell,
        user_equipmants_on_hand: userEquipmantsOnHand,
        available_equipment: availableEquipment
      } = dataRole

      const DEFAULT_START_PAGE_BY_ROLE = {
        [USER_ROLES.engineer]:
          config?.links?.main_menu_net ??
          DEFAULT_LINKS.main_menu_net,
        [USER_ROLES.serviceman]:
          config?.links?.service_manager ??
          DEFAULT_LINKS.service_manager,
        [USER_ROLES.workman]:
          config?.links?.service_manager ??
          DEFAULT_LINKS.service_manager,
        default:
          config?.links?.service_manager ??
          DEFAULT_LINKS.service_manager
      }
      const redirectLink =
        DEFAULT_START_PAGE_BY_ROLE?.[currentRole] ??
        DEFAULT_START_PAGE_BY_ROLE.default

      // ? если выбран режим работы Временной сдачи и мануал
      if (
        Boolean(redirectUrl) &&
        selectionMode === WORK_MODE.MANUALLY &&
        config.is_hand_over
      ) {
        return navigate(redirectUrl)
      }

      // ? если выбран режим работы с рандомными ячейками
      if (selectionMode === WORK_MODE.RANDOM) {
        if (currentRole === USER_ROLES.workman) {
          // ? если есть оборудование на руках
          if (userEquipmantsOnHand === true) {
            // ? если в шкафу нет доступных ячеек
            if (freeCell === false) {
              return navigate(
                config?.links?.no_cell_available ??
                  DEFAULT_LINKS.otsutstvuyut_dostupnye_yachejki
              )
            }
          } else {
            // ? если в шкафу нет доступного оборудования
            if (availableEquipment === false) {
              return navigate(
                config?.links
                  ?.page_user_equipment_not_connected ??
                  DEFAULT_LINKS.otsutstvuet_dostupnoe_oborudovanie
              )
            }
          }
        }
        return navigate(redirectUrl)
      }

      // ? если нет привязанного оборудования
      if (
        isAllCellFilled === false &&
        currentRole === USER_ROLES.workman
      ) {
        return navigate(
          config?.links
            ?.page_user_equipment_not_connected ??
            DEFAULT_LINKS.page_user_equipment_not_connected
        )
      }

      navigate(redirectLink)
    }

    /**
     * обработчик сигнала успешного логина по скуду
     */
    const handlerLoginCard = (data: string): void => {
      const dataRole = JSON.parse(
        data
      ) as unknown as ILoginDataEvent
      setCurrentRole(dataRole.user_role)
      setIsError(false)
      isPrevLogin = true
      redirectByRole(dataRole)
      setIsAuth(true)
    }
    /**
     * обработчик сигнала ошибки авторизации по скуду
     */
    const handlerErrorCard = (): void => {
      setIsAuth(false)
      setIsError(true)
      timer = setTimeout(resetLogin, TIMEOUT._2000)
    }
    /**
     * обработчик сигнала логаута ( тригерится в случае итечения сессии на беке )
     */
    const handlerLogout = (): void => {
      setIsAuth(false)
      setCurrentRole(undefined)
      setIsError(false)
      resetURL()
    }

    /**
     * обработчик для NFC меток на оборудовании
     * для последущего предиката установить необходимые флаги
     * ошибок нет сканирование успешное
     */
    const handlerSuccessNFC = (data: string): void => {
      const dataScan = JSON.parse(
        data
      ) as unknown as IScanDataEvent
      const isLastStep =
        dataScan?.data?.count_label ===
        dataScan?.data?.count_read_label

      if (!isLastStep) {
        store.global.set<number>(
          DEFAULT_SETTINGS_PROJECT_NAME.NFC_COUNT_LABEL,
          dataScan?.data?.count_label
        )
        store.global.set<number>(
          DEFAULT_SETTINGS_PROJECT_NAME.NFC_COUNT_READ_LABLE,
          dataScan?.data?.count_read_label
        )
        return
      }
      if (dataScan?.data?.is_resending) {
        // повторная сдача оборудования
        navigate(
          config?.links?.page_resupply_of_equipment ??
            DEFAULT_LINKS.page_resupply_of_equipment
        )
      } else {
        store.global.set<boolean>(
          DEFAULT_SETTINGS_PROJECT_NAME.NFC_SUCCESS,
          true
        )
      }
      store.global.set<boolean>(
        DEFAULT_SETTINGS_PROJECT_NAME.NFC_ERROR,
        false
      )
    }

    /**
     * обработчик для NFC меток  а конкретно ошибки сканирования
     * для последущего предиката установить необходимые флаги
     * обнаружена ошибка сканирование не удачное
     */
    const handlerErrorNFC = (): void => {
      store.global.set<boolean>(
        DEFAULT_SETTINGS_PROJECT_NAME.NFC_SUCCESS,
        false
      )
      store.global.set<boolean>(
        DEFAULT_SETTINGS_PROJECT_NAME.NFC_ERROR,
        true
      )
    }
    /**
     * Обработчит сигнала физического закрытия ячейки
     * todo: уточнить что надо делать при закрытии ячейки
     */
    const handleCloseCell = (): void => {
      history.back()
    }
    /**
     * Обработчик сигнала редиректа в данных прийдёт новая ссылка
     */
    const handleRedirect = (data: string): void => {
      const { link } = JSON.parse(data) as ISignalRedirect
      console.log('REDIRECT', link)
      return navigate(link)
    }

    /**
     * Обработчик успешного подключения по wifi
     */
    const handlerSuccessConnectWifi = (): void => {
      store.currentWifiStore.changeStatus(
        SIMPLE_STATUSES.SUCCESS
      )
    }

    /**
     * Обработчик неуспешного подключения по wifi
     */
    const handlerErrorConnectWifi = (): void => {
      store.currentWifiStore.changeStatus(
        SIMPLE_STATUSES.ERROR
      )
    }

    /**
     * Обработчик дисконекта от wifi
     */
    const handlerWifiDisconnect = (): void => {
      store.currentWifiStore.deny()
    }

    // [END SOCKET EVENTS]

    const collectDefaultSocketEvent = {
      // [DEFAULT]
      open,
      connect,
      disconnect,
      error,
      // [LOGIN]
      [SOCKET_SIGNALS.ws_login_card]: handlerLoginCard,
      [SOCKET_SIGNALS.ws_login_error_card]:
        handlerErrorCard,
      [SOCKET_SIGNALS.ws_logout]: handlerLogout,
      // [NFC]
      [SOCKET_SIGNALS.ws_nfc_success]: handlerSuccessNFC,
      [SOCKET_SIGNALS.ws_nfc_error]: handlerErrorNFC,
      [SOCKET_SIGNALS.ws_close_cell]: handleCloseCell,
      [SOCKET_SIGNALS.ws_redirect]: handleRedirect,
      // [WIFI]
      [SOCKET_SIGNALS.ws_wifi_success]:
        handlerSuccessConnectWifi,
      [SOCKET_SIGNALS.ws_wifi_error]:
        handlerErrorConnectWifi,
      [SOCKET_SIGNALS.ws_wifi_disconnect]:
        handlerWifiDisconnect
    }

    useEffect(() => {
      if (currentRole !== undefined) {
        store.global.setUserRole(currentRole)
      }
    }, [currentRole])

    useEffect(() => {
      if (!isAuth && isPrevLogin) {
        resetURL()
      }
    }, [isAuth])

    /**
     * Хук отслеживающий изменение текущей страницы "page_model" из контекста страницы
     * если до этого юзер был не авторизован и "page_model" поменялся значит был логин
     * и в этот момент ты 100% имеем нужный контекст страницы
     */
    useEffect(() => {
      if (
        !isAuth &&
        currentRole !== undefined &&
        !isError
      ) {
        if (
          page?.global?.user?.user_related_equipment ===
            false &&
          WORK_MODE.MANUALLY === config.work_mode
        ) {
          navigate(
            config.links.page_user_equipment_not_connected
          )
        }
        setIsAuth(true)
      }
    }, [page?.page_model])

    // /**Для имитации работы соетов локально */
    // useEffect(() => {
    //   if (store.socketLocal.message !== '') {
    //     collectDefaultSocketEvent[store.socketLocal.message]('')
    //   }
    // }, [store.socketLocal.message])

    useEffect(() => {
      const QS = '' // для подключения к чат румам
      if (
        API_WS_URL !== undefined &&
        API_WS_URL !== '' &&
        absSocket === undefined
      ) {
        // console.log('API_WS_URL', API_WS_URL)
        absSocket = useInit(API_WS_URL, QS) // инициализация
        useSocketCollectCallbacks(
          absSocket,
          collectDefaultSocketEvent
        ) // Устарновка дефолтных параметров и кастомных
      }
      return () => {
        //! обязательно закрывать соединениие  после размонтирования  компоненты
        absSocket?.socket?.close()
        clearTimeout(timer)
        clearTimeout(timerReconect)
        resetURL()
      }
    }, [])

    // на будущее
    if (isAuth) {
      // console.log('currentRole', currentRole)
      switch (currentRole) {
        case USER_ROLES.engineer:
          return <>{children}</>
        case USER_ROLES.workman:
          return <>{children}</>
        case USER_ROLES.serviceman:
          return <>{children}</>
        default:
          return <>{children}</>
      }
    } else {
      return <LoginPage isError={isError} />
    }
  }
)

export default Socket
