import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import MainLayout from '@/components/mainLayout/MainLayout'
import { Typography } from '@/views/typography'
import CardEquipment from '@/components/choiceEquipment/cardEquipment/CardEquipment'
import Paginator from '@/components/paginator/Paginator'
import {
  useCurrentUser,
  useSiteConfig,
  useStores
} from '@/hooks'
import { GxSpinner } from '@garpix/garpix-web-components-react'
import InfoMessagePage from '@/views/infoMessagePage/InfoMessagePage'
import { STATUS_EQUIPMENT } from '@/api/interfaces/Equipment'
import { STATUS_CELL } from '@/api/interfaces/Cell'
import { DEFAULT_LINKS, USER_ROLES } from '@/const'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'
// import qs from 'query-string';

const ChoiceEquipment: React.FC =
  (): React.ReactElement => {
    const store = useStores()
    const config = useSiteConfig()
    const user = useCurrentUser()
    const [options, setOptions] = useState({
      currentPage: 1,
      pageSize: 20,
      total: 0
    }) // параметры для пагинации

    // const qsData = qs.parse(window.location.search);
    // console.log('qsData', qsData);

    const paramsRequest = {
      faultyEquips: {
        page: options.currentPage,
        cell_status: STATUS_CELL.OCCUPIED,
        content_status: STATUS_EQUIPMENT.NOT_WORKING
      },
      [USER_ROLES.workman]: {
        page: options.currentPage,
        cell_status: STATUS_CELL.OCCUPIED,
        content_status: STATUS_EQUIPMENT.AVAILABLE
      },
      [USER_ROLES.serviceman]: {
        page: options.currentPage,
        cell_status: STATUS_CELL.OCCUPIED,
        content_status: STATUS_EQUIPMENT.AVAILABLE
      },
      [USER_ROLES.engineer]: {
        page: options.currentPage,
        cell_status: STATUS_CELL.OCCUPIED,
        content_status: STATUS_EQUIPMENT.AVAILABLE
      }
    }
    console.log('user', user)
    const { data, isLoading } = useSWR(
      paramsRequest[user.role],
      store.api.equipment.getEquipmentList,
      {
        shouldRetryOnError: false // не слать повторный запрос, если пришла ошибка с бэка
      }
    )

    /**
     * функция для изменения страниц
     */
    const changeNumber = (pageNum: number): void => {
      setOptions({
        ...options,
        currentPage: pageNum
      })
    }

    /**
     * сохраняем общее кол-во элементов, которое пришло с бэка в стейт
     */
    useEffect(() => {
      setOptions({
        ...options,
        total: data?.total ?? 0
      })
    }, [data?.total])

    if (data?.results.length === 0 && !isLoading) {
      return (
        <InfoMessagePage
          linkToMain={config?.links?.verification ?? DEFAULT_LINKS.verification}
          linkBack={config?.links?.verification ?? DEFAULT_LINKS.verification}
          errorMessage='Отсутствуют доступные ячейки'
        />
      )
    }

    return (
      <MainLayout linkBack={config.links.verification}>
        <div className={styles.container}>
          <CSSTransitionGroup
            component={Typography}
            animation='fadeInTop'
            size={60}
            weight={700}
          >
            Выберите оборудование
          </CSSTransitionGroup>
          {isLoading
            ? (
              <GxSpinner
                className={styles.container__spinner}
                size='large'
              />
              )
            : (
              <div className={styles.container__content}>
                <div className={styles.container__main}>
                  {data?.results.map((elem) => (
                    <CardEquipment key={elem.id} {...elem} />
                  ))}
                </div>
                <Paginator
                  {...options}
                  changePage={changeNumber}
                />
              </div>
              )}
        </div>
      </MainLayout>
    )
  }

export default ChoiceEquipment
