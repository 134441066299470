import AbsractApi from './abstract'
import {
  PageApi,
  ProblemApi,
  EquipmentApi,
  CellApi,
  UserApi,
  NetworkApi,
  Experemental,
  ConfigApi
} from './domains'
import ErrorApiOverride from './abstract/error'
import { TLanguage } from '@/interfaces/Lang'

class Api extends AbsractApi {
  page: PageApi
  problem: ProblemApi
  equipment: EquipmentApi
  cell: CellApi
  user: UserApi
  network: NetworkApi
  experemental: Experemental
  errorHandlers: ErrorApiOverride
  config: ConfigApi
  constructor (MAIN_URL: string, language: TLanguage) {
    super(MAIN_URL, language)
    this.errorHandlers = new ErrorApiOverride(this)
    this.user = new UserApi(this)
    this.page = new PageApi(this)
    this.problem = new ProblemApi(this)
    this.equipment = new EquipmentApi(this)
    this.cell = new CellApi(this)
    this.network = new NetworkApi(this)
    this.experemental = new Experemental(this)
    this.config = new ConfigApi(this)
  }
}

export { Api }
