import React, { FC, useMemo } from 'react'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import { ChooseActionCell } from './Content/ChoseActionCell'
import { ConfirmActionCell } from './Content/ConfirmActionCell'
import { RebootModal } from './Content/RebootStore'
import styles from './style/index.module.scss'
import classNames from 'classnames'

interface IActions {
  onClose: () => void
}
interface TProps {
  isOpen: boolean
  params: IActions
  contentType?: string
}

export const BOTTOM_CONTENT_TYPES = {
  REBOOT_STORE: 'REBOOT_STORE',
  CONFIRM_ACTION_CELL: 'CONFIRM_ACTION_CELL',
  CHOOSE_ACTION_CELL: 'CHOOSE_ACTION_CELL',
  DEFAULT: 'DEFAULT'
}

const BOTTOM_CONTENTS = {
  [BOTTOM_CONTENT_TYPES.REBOOT_STORE]: RebootModal,
  [BOTTOM_CONTENT_TYPES.CONFIRM_ACTION_CELL]: ConfirmActionCell,
  [BOTTOM_CONTENT_TYPES.CHOOSE_ACTION_CELL]: ChooseActionCell,
  [BOTTOM_CONTENT_TYPES.DEFAULT]: null
}

export const ModalBottom: FC<TProps> = ({
  isOpen,
  contentType = '',
  params
}) => {
  const { onClose } = params

  const Content: React.FC<unknown> | null = useMemo(() => BOTTOM_CONTENTS?.[contentType] ??
    BOTTOM_CONTENTS[BOTTOM_CONTENT_TYPES.DEFAULT]
  , [contentType])

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    const target = e.target as HTMLDivElement
    if (target?.attributes?.['data-test']?.value === 'backdrop') {
      onClose()
    }
  }

  return (
    <CSSTransitionGroup
      data-test='backdrop'
      className={styles.backdrop}
      onClick={handleBackgroundClick}
      data-show={isOpen}
    >
      <CSSTransitionGroup
        animation='fadeInBottom'
        data-open={isOpen}
        className={
          classNames(
            styles.container,
            {
              [styles['container_choose-action']]:
              contentType === BOTTOM_CONTENT_TYPES.CHOOSE_ACTION_CELL,
              [styles['container_confirm-action']]:
                contentType === BOTTOM_CONTENT_TYPES.CONFIRM_ACTION_CELL
            }
          )
        }
      >
        {Content?.({ ...params })}
      </CSSTransitionGroup>
    </CSSTransitionGroup>
  )
}
