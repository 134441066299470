export interface ICell {
  id: number
  cell_number: number
  cell_size: number
  cell_status: string
  reserved_for_equipment__isnull: boolean
}

export interface ITime {
  [x: string]: number
  time: number
}

export enum STATUS_CELL {
  FREE = 'FREE',
  OCCUPIED = 'OCCUPIED',
}
