import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './style/index.module.scss'
import { Spinner } from '../spinner'

interface IButton
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void
  color?: 'default' | 'orange' | 'white'
  isShadow?: boolean
  positionLabel?: 'center' | 'right' | 'left'
  size?: 'large' | 'medium'
  type?: 'button' | 'submit' | 'reset'
  load?: boolean
  children: React.ReactNode
  lightShadow?: boolean
  className?: string
}

export const Button: FC<IButton> = ({
  onClick,
  color = 'default',
  isShadow = true,
  positionLabel = 'center',
  size = 'large',
  children,
  lightShadow,
  type = 'button',
  load = false,
  className,
  ...props
}) => {
  return (
    <button
      className={classNames(
        styles.button,
        styles[`button__${color}`],
        styles[`button__position_${positionLabel}`],
        styles[`button__size_${size}`],
        {
          [styles.button__load]: load,
          [styles.button__shadow]: isShadow,
          [styles.button__shadow_light]: lightShadow
        },
        className
      )}
      onClick={onClick}
      type={type}
      {...props}
    >
      {load
        ? (
          <div>
            <Spinner size='small' />
          </div>
          )
        : (
          <>{children}</>
          )}
    </button>
  )
}
