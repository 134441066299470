import React, { useState } from 'react'
import {
  GxIcon
} from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import { Typography } from '@/views/typography'
import {
  HiddenPasswordIcon,
  VisiblePasswordIcon
} from '@/assets/icon'
import { IInput } from '@/interfaces/Common'
import style from './style/index.module.scss'
import { EMPTY_CONSTS } from '@/const'

const preventHandler = (e): void => e.preventDefault()

export const Input: React.ForwardRefExoticComponent<
IInput & {
  children?: React.ReactNode
} & React.RefAttributes<HTMLInputElement>
> = React.forwardRef(
  (
    {
      type = 'text',
      value = EMPTY_CONSTS.STR,
      label = EMPTY_CONSTS.STR,
      message = EMPTY_CONSTS.STR,
      error = EMPTY_CONSTS.FALSE,
      disabled = EMPTY_CONSTS.FALSE,
      textCenter = EMPTY_CONSTS.FALSE,
      errorMessage = EMPTY_CONSTS.STR,
      onChange = EMPTY_CONSTS.FUNC,
      onFocus = EMPTY_CONSTS.FUNC,
      onBlur = EMPTY_CONSTS.FUNC,
      outerStyles = '',
      inputSize = 'medium',
      ...props
    },
    ref: React.RefObject<HTMLInputElement>
  ): React.ReactElement => {
    const [isHide, setIsHide] = useState<boolean>(type === 'password')

    const handleHide = (): void => {
      setIsHide(true)
    }

    const handleShow = (): void => {
      setIsHide(false)
    }

    const inputClasses = classNames(
      style.input,
      style[`input--${inputSize}`],
      {
        [style['input--text-center']]: textCenter,
        [style.input_error]: error,
        [style.input_disabled]: disabled
      },
      outerStyles
    )

    return (
      <div className={style.container}>
        <Typography
          size={36}
          weight={700}
          className={classNames(style.container__title, {
            [style.container__title_error]: error,
            [style.container__title_disabled]: disabled
          })}
        >
          {label}
        </Typography>
        {!(disabled ?? false) && (
          <Typography
            size={24}
            weight={400}
            className={classNames(style.container__message)}
          >
            {message}
          </Typography>
        )}

        <div
          className={inputClasses}
        >
          <input
            ref={ref}
            title=''
            data-disabled={disabled}
            {...props}
            onInput={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            value={value ?? ''}
            type={isHide ? 'password' : 'text'}
            autoComplete='off'
          />
          {type === 'password' &&
            <>
              <button
                onClick={handleHide}
                data-show={!isHide}
                className={style.input__buttons}
              >
                <GxIcon
                  slot='show-password-icon'
                  src={VisiblePasswordIcon}
                  className={style.input__icon}
                  onClick={preventHandler}
                />
              </button>
              <button
                onClick={handleShow}
                data-show={isHide}
                className={style.input__buttons}
              >
                <GxIcon
                  slot='hide-password-icon'
                  src={HiddenPasswordIcon}
                  className={style.input__icon}
                  onClick={preventHandler}
                />
              </button>
            </>}
        </div>
        {Boolean(error) && (
          <Typography
            size={30}
            weight={500}
            className={style.container__error}
          >
            {errorMessage}
          </Typography>
        )}
      </div>
    )
  }
)
