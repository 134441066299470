import React, { FC, useState } from 'react'
import { FormStaticConnection } from '@/views/formStaticConnection'
import { Button } from '@/views/button'
import { Form, Formik, FormikHelpers } from 'formik'
import { NETWORK_CONNECTION_DINAMIC_LAN, NETWORK_CONNECTION_STATIC_LAN } from '@/utils/validation'
import { IConnectWiFi } from '@/api/interfaces/Network'
import { FORM_NAMES } from '@/utils/formNames'
import styles from './style/index.module.scss'
import { KeyboardLayout } from '../keyboard/Keyboard'
import { EMPTY_CONSTS } from '@/const'
import { IObjectValuesLan } from '@/interfaces/ObjectPage'
import { IFocusInputEvent } from '@/interfaces/Common'

interface TLanConnectionForm {
  onSubmit: (
    values: IConnectWiFi,
    formik: FormikHelpers<IConnectWiFi>
  ) => void
  values: IConnectWiFi
  isStatic: boolean
  isLoading?: boolean
}
const LanConnectionForm: FC<TLanConnectionForm> = ({
  onSubmit,
  values,
  isStatic,
  isLoading = false
}): React.ReactElement => {
  const [isKeyBoard, setIsKeybard] = useState(EMPTY_CONSTS.FALSE)
  const [keyboardValue, setKeyboardValue] = useState<string | null>(null)
  const [keyboardFild, setKeyboardField] = useState(EMPTY_CONSTS.STR)

  /**
   * объект с задизейблеными полями в форме
   * при динамике форму нельзя менять и перезаписывать
   */
  const disabledInputs = {
    [FORM_NAMES.IP]: !isStatic,
    [FORM_NAMES.MASK]: !isStatic,
    [FORM_NAMES.GATEWAY]: !isStatic,
    [FORM_NAMES.DNS]: !isStatic
  }

  /**
   * Handler Event onFocus Input
   */
  const onFocusInput = (
    event: IFocusInputEvent
  ): void => {
    setKeyboardValue(event.target.value)
    setKeyboardField(event.target.name)
    setIsKeybard(EMPTY_CONSTS.TRUE)
  }

  const closeKeyboard = (): void => {
    setIsKeybard(false)
  }

  /**
    * Декоратнутая Отправка значений из формы на бэк
    */
  const decoradedSubmit = (
    formikValues: IObjectValuesLan,
    formHelpers: FormikHelpers<IObjectValuesLan>
  ): void => {
    onSubmit(formikValues, formHelpers)
    closeKeyboard()
  }

  return (
    <Formik
      onSubmit={decoradedSubmit}
      initialValues={values}
      enableReinitialize
      validationSchema={isStatic
        ? NETWORK_CONNECTION_STATIC_LAN
        : NETWORK_CONNECTION_DINAMIC_LAN}
    >
      {({ handleSubmit, setFieldValue }) => {
        /**
       * Simple Handler input Value and set to form values
       */
        const handleInput = (value: string): void => {
          setKeyboardValue(value)
          void setFieldValue(keyboardFild, value)
        }

        /**
         * Handler Event onInput from Virtual Keyboard Input
        */
        const onKeyboardInput = (input: string): void => {
          void handleInput(input)
        }
        return (
          <KeyboardLayout
            onChange={onKeyboardInput}
            open={isKeyBoard}
            value={keyboardValue}
            handlecontinue={closeKeyboard}
          >
            <Form onSubmit={handleSubmit} data-keyboard={isKeyBoard} className={styles.form}>
              <FormStaticConnection
                disabledInputs={disabledInputs}
                onFocusInput={onFocusInput}
              />
              <Button
                type='submit'
                className={styles.container__submit}
                load={isLoading}
              >
                Сохранить
              </Button>
            </Form>
          </KeyboardLayout>
        )
      }}
    </Formik>
  )
}

export default LanConnectionForm
