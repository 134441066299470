import React, { FC, useState } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import styles from './style/index.module.scss'
import CardInfo from '@/components/cardInfo/CardInfo'
import {
  useCurrentDevice,
  useCurrentUser,
  useSiteConfig,
  useStores
} from '@/hooks'
import EquipmentStatusComponent from '@/views/equipmentStatusComponent/equipmentStatusComponent'
import { CELL, DEFAULT_LINKS, USER_ROLES, WORK_MODE } from '@/const'
import { objToQs } from '@/utils'
import { useNavigate } from 'react-router-dom'
import ErrorPipeLine from '@/components/ErrorPipline'
import { TWorkMode } from '@/interfaces/ObjectPage'

const EquipmentStatus: FC = () => {
  const [isLoadBtnSpin, setIsLoadBtnSpin] = useState(false)
  const [isError, setIsError] = useState(false)
  const { api } = useStores()
  const navigate = useNavigate()
  const device = useCurrentDevice()
  const config = useSiteConfig()
  const { role } = useCurrentUser()

  const isHideBackLink = role === USER_ROLES.workman && config?.work_mode === WORK_MODE.RANDOM
  const linkTop = {
    cell_status: CELL.STATUS.FREE
  }

  const linkCellList = `${config?.links?.hand_over_in_cell ?? DEFAULT_LINKS.hand_over_in_cell
    }${objToQs(linkTop)}`

  /**
   * @info отправляет запрос на открытие рандомной ячейки
   * resolve - в ответе ничего не приходит
   * reject - пробросить выше
   * Бэк по сокетам присылает ссылку для редиректа
   * Редирект происходит автоматом реакцией на message
   */
  const openRandomCell = (): void => {
    api.cell.openRandomCell().catch(err => {
      throw new Error(err)
    })
  }

  /**
   * обработчик нажатия на кнопку ответсвенную за отправку на бек сообщения что
   * девайс починен и последующего
   * редиректа на список ячеек
   */
  const handleCLickProperlyDevice = (): void => {
    setIsLoadBtnSpin(true)
    try {
      api.equipment
        .setFixedEqip(device.id, { is_resolved: true })
        .then(() => {
          setIsLoadBtnSpin(false)
          if (role === USER_ROLES.workman && config?.work_mode === TWorkMode.Random) {
            openRandomCell()
          } else {
            navigate(linkCellList)
          }
        })
        .catch(() => {
          setIsLoadBtnSpin(false)
          setIsError(true)
        })
    } catch (error) {
      setIsError(true)
    }
  }

  if (isError) {
    return (
      <ErrorPipeLine
        linkBack={config.links.verification}
        errorMessage='Произошла неожиданная ошибка!'
      />
    )
  }

  return (
    <MainLayout visibleLinkBack={!isHideBackLink} linkBack={config?.links?.verification ?? DEFAULT_LINKS.verification}>
      <div className={styles.container}>
        <CardInfo
          equipmentType={device?.equipment_type.title}
          name={device?.title_ru}
        />
        <EquipmentStatusComponent
          isButtonTop
          isLoadBtnSpin={isLoadBtnSpin}
          handleClickBtnTop={handleCLickProperlyDevice}
          titleTop='Оборудование исправно'
          titleBottom='Оборудование неисправно'
          linkBottom={config.links.problems}
        />
      </div>
    </MainLayout>
  )
}
export default EquipmentStatus
