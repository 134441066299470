import AbsractApi from '@/api/abstract'
import { IPageApi } from '@/api/interfaces'
import {
  IColorSheme,
  IResultGetPage,
  Page,
  PageModelType
} from '@/api/interfaces/Page'
import { QueryParams } from '@garpix/base-api'
import { AxiosRequestConfig } from 'axios'
import {
  defaultSerializer,
  errorSerializer,
  notFoundSerializer,
  permissionDeniedSerializer,
  settingsLanSerializer
} from './serializers'

/**
 * Класс ответвенный за получение данных по странице - конекстам
 */
class PageApi implements IPageApi {
  ctx: AbsractApi
  PAGES: {
    [key in PageModelType]: (data: any) => any;
  }

  constructor (ctx: AbsractApi) {
    this.ctx = ctx
    this.PAGES = {
      CellAdminPage: defaultSerializer,
      Verification: defaultSerializer,
      Problems: defaultSerializer,
      NoHardwareAvailable: defaultSerializer,
      HandOverInCell: defaultSerializer,
      SelectAnotherCellPage: defaultSerializer,
      HomePage: defaultSerializer,
      MainMenuNet: defaultSerializer,
      AddNewWiFiConnect: defaultSerializer,
      LoadableEquipmentPage: defaultSerializer,
      LoadMoreEquipmentPage: defaultSerializer,
      SeizeAllEquipmentPage: defaultSerializer,
      UserEquipmentNotConnectedPage: defaultSerializer,
      SeizeEquipmentPage: defaultSerializer,
      ConnectedWiFiSettings: defaultSerializer,
      LANSettings: settingsLanSerializer,
      NetSettings: defaultSerializer,
      SettingsWiFi: defaultSerializer,
      FaultyEquipmentPage: defaultSerializer,
      Equipment: defaultSerializer,
      DescribeTheProblem: defaultSerializer,
      OpenCell: defaultSerializer,
      ConnectWiFi: defaultSerializer,
      HandOverEquipment: defaultSerializer,
      ConditionEquipment: defaultSerializer,
      EquipmentListPage: defaultSerializer,
      CellListPage: defaultSerializer,
      Page: defaultSerializer,
      ServiceManagerPage: defaultSerializer,
      TypesOfEquipmentPage: defaultSerializer,
      Locker: defaultSerializer,
      Cell: defaultSerializer,
      Page403: permissionDeniedSerializer,
      Page404: notFoundSerializer,
      AllCellsFilled: defaultSerializer,
      SeizeFaultyType: defaultSerializer,
      NoCellAvailable: defaultSerializer,
      ResupplyOfEquipment: defaultSerializer,
      SeizeAllAvailable: defaultSerializer,
      PageNotFound: notFoundSerializer,
      PageErrorServer: errorSerializer,
      SurrenderOfEquipment: defaultSerializer
    }
  }

  getColorSheme = async (): Promise<IColorSheme> => {
    const res = await this.ctx.get<IColorSheme>(
      '/colors/',
      {}
    )
    return res.data
  }

  getPage = async (
    pathname: string,
    queryParams: QueryParams<unknown> | undefined,
    axiosParams: AxiosRequestConfig
  ): Promise<IResultGetPage> => {
    try {
      const path =
        pathname.charAt(0) === '/'
          ? pathname
          : `/${pathname}`
      const res: { data: Page } = await this.ctx.get(
        `/page${path}`,
        queryParams,
        axiosParams
      )
      const page = res.data
      console.log('page', page)
      const serializer = this.PAGES[page.page_model]
      return {
        pageType: page.page_model,
        page: serializer(page),
        statusCode: 200
      }
    } catch (error: any) {
      console.log('error', error)
      if (error?.response?.status === 404) {
        const serializer = this.PAGES.Page404
        return {
          pageType: 'Page404',
          statusCode: 404,
          page: serializer(error.response.data)
        }
      }
      return {
        pageType: 'PageErrorServer',
        page: errorSerializer(),
        statusCode: 500
      }
    }
  }
}

export default PageApi
