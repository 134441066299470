import React from 'react'
import { Button } from '@/views/button'
import { EMPTY_CONSTS } from '@/const'
import styles from './styles/index.module.scss'
import IPExtraField from '@/views/IPExtraField'
import { ISettingsWifiDinamicStaticFields } from './interfaces'

const SettingsWifiDinamicStaticFields: React.FC<ISettingsWifiDinamicStaticFields> = ({
  isDinamicIP = EMPTY_CONSTS.TRUE,
  values,
  errors,
  handleChangeIpType = EMPTY_CONSTS.FUNC,
  handleInput = EMPTY_CONSTS.FUNC,
  handleFocusInput = EMPTY_CONSTS.FUNC,
  handleBlurInput = EMPTY_CONSTS.FUNC
}): React.ReactElement => {
  /**
   * Обработчик Клика по табу Динамический
   * переключает тип IP
   */
  const handleClickDinamic = (): void => {
    handleChangeIpType(EMPTY_CONSTS.TRUE)
  }

  /**
   * Обработчик Клика по табу Статический
   * переключает тип IP
   */
  const handleClickStatic = (): void => {
    handleChangeIpType(EMPTY_CONSTS.FALSE)
  }

  return (
    <>
      <div className={styles.options}>
        <Button
          color={isDinamicIP ? 'orange' : 'white'}
          onClick={handleClickDinamic}
          isShadow={EMPTY_CONSTS.FALSE}
          positionLabel='left'
          size='medium'
          data-testid='Button_Dinamic'
        >
          Динамический
        </Button>
        <Button
          onClick={handleClickStatic}
          isShadow={EMPTY_CONSTS.FALSE}
          color={!isDinamicIP ? 'orange' : 'white'}
          positionLabel='left'
          size='medium'
          data-testid='Button_Static'
        >
          Статический IP
        </Button>
      </div>
      <div>
        <IPExtraField
          disabled={isDinamicIP}
          values={values}
          errors={errors}
          handleInput={handleInput}
          handleFocusInput={handleFocusInput}
          handleBlurInput={handleBlurInput}
        />
      </div>
    </>
  )
}

export default SettingsWifiDinamicStaticFields
