import { Typography } from '@/views/typography'
import React, { FC } from 'react'
import AddConnectionForm from './AddConnectionForm'
import { AutoConnection } from '@/views/autoConnection'
import { Button } from '@/views/button'
import { IAddWifiForm } from './interfaces'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'

const AddWifiForm: FC<IAddWifiForm> = ({
  isKeyBoard,
  values,
  errors,
  isDinamicIP,
  securitiOptions,
  isPassword,
  securityValue,
  isLoading,
  protocols,
  currentProtocol,
  isEnterprise,
  handleChangeProtocol,
  handleSubmit,
  changeIpType,
  handleSwith,
  handleSelectChange,
  handleChange,
  onFocusInput,
  onBlurInput
}) => {
  return (
    <form
      className={styles['container-form']}
      data-keyboard={isKeyBoard}
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <CSSTransitionGroup
        component={Typography}
        animation='fadeInTop'
        size={60}
        weight={700}
        className={styles.container__title}
      >
        Добавить сеть
      </CSSTransitionGroup>
      <AddConnectionForm
        protocols={protocols}
        currentProtocol={currentProtocol}
        isEnterprise={isEnterprise}
        handleChangeProtocol={handleChangeProtocol}
        values={values}
        errors={errors}
        isDinamicIP={isDinamicIP}
        securitiOptions={securitiOptions}
        isPassword={isPassword}
        securityValue={securityValue}
        handleSelect={handleSelectChange}
        handleInput={handleChange}
        handleFocusInput={onFocusInput}
        handleBlurInput={onBlurInput}
        handleChangeIpType={changeIpType}
      />
      <AutoConnection
        value={values.autoconnect}
        handleSwitch={handleSwith}
      />
      <CSSTransitionGroup>
        <Button type='submit' load={isLoading}>
          Подключиться
        </Button>
      </CSSTransitionGroup>
    </form>
  )
}

export default AddWifiForm
