import React, { useEffect, useState } from 'react'

import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import { Typography } from '@/views/typography'

import { useStores } from '@/hooks'
import { IHistoryScan } from '@/api/interfaces/Equipment'
import { formatDateTime } from '@/utils'

import CurrentStyle from './styles/index.module.scss'
import styles from '../styles/index.module.scss'

const HistoryScan = (): React.ReactElement => {
  const [historyList, sethistoryList] = useState<
  IHistoryScan[]
  >([])
  const { api } = useStores()

  useEffect(() => {
    void api.config
      .getHistoryScanList()
      .then((res) => sethistoryList(res))
  }, [])

  return (
    <>
      <CSSTransitionGroup>
        <Typography
          className={styles.curd__typography__title}
          size={60}
        >
          История сканирования меток
        </Typography>
      </CSSTransitionGroup>
      <CSSTransitionGroup>
        <div className={CurrentStyle.hs_table_container}>
          <table className={CurrentStyle.hs_table}>
            <thead>
              <th>Метка</th>
              <th>Время</th>
              <th>Дата</th>
            </thead>
            <tbody>
              {historyList.map((history, i) => {
                const { day, month, year, hours, minutes } =
                  formatDateTime(history.created_at)
                return (
                  <tr key={i}>
                    <td>{history.card_number}</td>
                    <td>
                      {hours}:{minutes}
                    </td>
                    <td>
                      {day}/{month}/{year}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </CSSTransitionGroup>
    </>
  )
}

export default HistoryScan
