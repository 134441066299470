import React, { FC } from 'react'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import styles from '../style/index.module.scss'
import classNames from 'classnames'

interface TProps {
  title: string
  onClick: () => void
  onClose: () => void
}

export const ConfirmActionCell: FC<TProps> = ({
  title,
  onClick,
  onClose
}) => {
  return (
    <>
      <Typography
        size={60}
        weight={400}
        className={classNames(
          styles.container__title,
          styles['content_confirm-action_title']
        )}
      >
        {title}
      </Typography>
      <div className={styles.container__buttons}>
        <Button
          onClick={onClick}
        >
          Выполнить
        </Button>
        <Button
          onClick={onClose}
          color='white'
          lightShadow
        >
          Отменить
        </Button>
      </div>
    </>
  )
}
