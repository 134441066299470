import { makeAutoObservable } from 'mobx'
import { RootStore } from './Root'
import {
  DEFAULT_SETTINGS_PROJECT,
  USER_ROLES
} from '@/const'

type TGlobalStore = {
  [key: string]: unknown
} & typeof DEFAULT_SETTINGS_PROJECT

export class GlobalStore {
  rootStore: RootStore
  globalStore: TGlobalStore
  userRole: string
  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.globalStore = DEFAULT_SETTINGS_PROJECT
    this.userRole = USER_ROLES.workman
    makeAutoObservable(this)
  }

  setUserRole = (newRole: string): string => {
    this.userRole = newRole
    return this.userRole
  }

  initColorSheme = (): void => {
    void this.rootStore.api.page
      .getColorSheme()
      .then(({ color }) => {
        try {
          const root =
            globalThis?.document?.documentElement
          if (root !== undefined) {
            for (const key in color) {
              const val = color[key]
              root.style.setProperty(key, val)
            }
          }
        } catch (error) {
          console.error('[ERROR] - setColorSheme', error)
        }
      })
  }

  reset = (): void => {
    this.globalStore = DEFAULT_SETTINGS_PROJECT
  }

  get = (): TGlobalStore => {
    return this.globalStore
  }

  set = <TValue>(
    key: string,
    value: TValue
  ): TGlobalStore => {
    this.globalStore[key] = value
    return this.globalStore
  }
}
