import React from 'react'
import { Input } from '../input'
import { FORM_NAMES } from '@/utils/formNames'
import { EMPTY_CONSTS } from '@/const'
import { IIPExtraField } from './interfaces'
import styles from './styles/index.module.scss'

const IPExtraField: React.FC<IIPExtraField> = ({
  show = EMPTY_CONSTS.TRUE,
  disabled = EMPTY_CONSTS.FALSE,
  values,
  errors,
  handleInput = EMPTY_CONSTS.FUNC,
  handleFocusInput = EMPTY_CONSTS.FUNC,
  handleBlurInput = EMPTY_CONSTS.FUNC
}): React.ReactElement => {
  if (show === EMPTY_CONSTS.FALSE) return <></>

  return (
    <div className={styles.container}>
      <Input
        id={FORM_NAMES.IP}
        name={FORM_NAMES.IP}
        data-testid={FORM_NAMES.IP}
        error={Boolean(errors?.ip)}
        errorMessage={errors?.ip}
        disabled={disabled}
        value={values?.ip}
        placeholder='255.255.255.255'
        label='IP - адрес'
        autoComplete='off'
        onChange={handleInput}
        onBlur={handleBlurInput}
        onFocus={handleFocusInput}
      />
      <Input
        id={FORM_NAMES.MASK}
        name={FORM_NAMES.MASK}
        data-testid={FORM_NAMES.MASK}
        error={Boolean(errors?.mask)}
        errorMessage={errors?.mask}
        value={values?.mask}
        disabled={disabled}
        placeholder='255.255.255.255'
        label='Маска сети'
        autoComplete='off'
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        onChange={handleInput}
      />
      <Input
        id={FORM_NAMES.GATEWAY}
        name={FORM_NAMES.GATEWAY}
        data-testid={FORM_NAMES.GATEWAY}
        error={Boolean(errors?.gateway)}
        errorMessage={errors?.gateway}
        value={values?.gateway}
        disabled={disabled}
        placeholder='255.255.255.255'
        autoComplete='off'
        label='Шлюз'
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        onChange={handleInput}
      />
      <Input
        id={FORM_NAMES.DNS}
        name={FORM_NAMES.DNS}
        data-testid={FORM_NAMES.DNS}
        error={Boolean(errors?.dns)}
        errorMessage={errors?.dns}
        value={values?.dns}
        placeholder='255.255.255.255'
        message='Не обязательное поле'
        label='DNS'
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        onChange={handleInput}
        autoComplete='off'
        disabled={disabled}
      />
    </div>
  )
}

export default IPExtraField
