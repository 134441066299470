import { EMPTY_CONSTS } from '@/const'
/**
 * * тулза для рендера текста в ячейке
 * @param isEquipment флаг говорящий о том что в ячейке есть оборудования
 * @param showReserved флаг говорящий о том что ячека зарезервирована каким либо оборудованием
 * @param equipmentText текст при isEquipment === true
 * @param reservedText текст при showReserved === true
 * @param defaultText дефолтный текст если что то пошло не так
 */
export const renderText = ({
  isEquipment,
  showReserved,
  equipmentText,
  reservedText,
  defaultText = EMPTY_CONSTS.STR
}: {
  isEquipment: boolean
  showReserved: boolean
  equipmentText: string
  reservedText: string
  defaultText?: string
}): string => {
  if (isEquipment) {
    return equipmentText
  } else if (showReserved) {
    return reservedText
  } else {
    return defaultText
  }
}
