import { makeAutoObservable } from 'mobx'
import { RootStore } from './Root'
import { IWifi } from '@/api/interfaces/Network'
import { SIMPLE_STATUSES } from '@/const'

export interface ICurrentWifi
  extends Omit<IWifi, 'in_use'> {
  status: keyof typeof SIMPLE_STATUSES
}

const autoDenyTimeoutTime = 10000

export class CurrentWifiStore {
  currentWifi: ICurrentWifi | null = null
  timeout: ReturnType<typeof setTimeout> | null = null
  rootStore: RootStore

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  autoDeny = (): void => {
    this.timeout = setTimeout(() => {
      this.deny()
    }, autoDenyTimeoutTime)
  }

  set = (wifi: ICurrentWifi): void => {
    this.currentWifi = wifi
    const uselessKeys = ['in_use', 'password']
    this.currentWifi = Object.fromEntries(
      Object.entries(wifi).filter(
        ([key]) => !uselessKeys.includes(key)
      )
    ) as ICurrentWifi
    if (wifi.status === SIMPLE_STATUSES.LOADING) {
      this.autoDeny()
    }
  }

  changeStatus = (
    status: keyof typeof SIMPLE_STATUSES
  ): void => {
    if (this.currentWifi === null) return
    if (
      status !== SIMPLE_STATUSES.LOADING &&
      this.timeout !== null
    ) {
      clearTimeout(this.timeout)
    } else {
      this.autoDeny()
    }
    const newWifi = {
      ...this.currentWifi,
      status: status
    }
    this.set(newWifi)
  }

  deny = (): void => {
    this.currentWifi = null
  }

  get = (): ICurrentWifi | null => this.currentWifi
}
