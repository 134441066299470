import React, { FC, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Typography } from '@/views/typography'
import { WiFiSignal } from '@/views/wifiSignal'
import { WifiConnectionCard } from '../wifiConnectionCard'
import { NetworkMessage } from '@/views/networkMessage'
import ConnectTowifi from '../ConnectToWifi'
import { useSiteConfig, useStores } from '@/hooks'
import useWifiList from '@/hooks/useWifiList'
import { isScrollEnd, log } from '@/utils'
import { WiFiIcon } from '@/assets/icon'
import {
  DEFAULT_LINKS,
  EMPTY_CONSTS,
  IP_TYPE,
  SIMPLE_STATUSES
} from '@/const'
import { IAllowWifiList, IWifi, IWifiList, TScrollEvent } from './interfaces'
import styles from './style/index.module.scss'
import { IAllWiFiConnections, TAllProtocols } from '@/api/interfaces/Network'
import { observer } from 'mobx-react'
import { SkeletonWifiList } from './SkeletonWifiList'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'

/**
 * Component rendering
 * 1. Message with empty list
 * 2. Nothing if we have current wifi and nowing allowWifi
 * 3. Wifi list if we have
 */
const AllowWifiList: FC<IAllowWifiList> = ({ list, handleScrollListNetwork, chooseWifi }) => {
  if (list === EMPTY_CONSTS.NULL) return <SkeletonWifiList />
  if (list.length === EMPTY_CONSTS.ZERO) {
    return (
      <NetworkMessage
        message='Кажется в данный момент у вас нету доступных сетей'
        icon={WiFiIcon}
      />
    )
  }

  return (
    <div
      className={styles.container__list}
      onScroll={handleScrollListNetwork}
    >
      {list.map((elem: IWifi, i) => {
        return (
          <button
            key={i}
            onClick={() => chooseWifi(elem.bssid)}
            className={styles.item}
          >
            <WiFiSignal
              value={elem.signal_strength}
              lock={elem.is_password}
            />
            <Typography
              size={40}
              weight={500}
              className={styles.item__label}
            >
              {elem.ssid}
            </Typography>
          </button>
        )
      })}
    </div>
  )
}

const WiFiList: FC<IWifiList> = ({ wifiToConnect, setWifiToConnect }): React.ReactElement => {
  const config = useSiteConfig()
  const { api, currentWifiStore } = useStores()
  const [protocols, setProtocols] = useState<TAllProtocols>([])
  const [filteredList, setFilteredList] = useState<IWifi[] | null>(EMPTY_CONSTS.NULL) // wifi list without connecded Wifi

  const addNewNetURL =
    config?.links?.add_new_wifi_connect ??
    DEFAULT_LINKS.add_new_wifi_connect

  /**
   * Event after success load List
   * @param data
   */
  const onSuccessLoad = (
    data: IAllWiFiConnections
  ): void => {
    log('after Success Load WifiList', 'info')
  }

  /**
   * Event after error load List
   * @param err
   */
  const onErrorLoad = (err: any): void => {
    log('after Eror Load WifiList catch 500', 'info')
    console.log(err)
  }

  /**
   * using hook  Wifi list
   */
  const { list, isFirstLoading, nextPage, revalidateData } =
    useWifiList({
      isInfinity: true,
      onError: onErrorLoad,
      onSuccess: onSuccessLoad
    })

  /**
   * handler to choose wifi
   * choose wifi with password or without
   * @param id id Wifi
   * @returns
   */
  const chooseWifi = (bssid: string): void => {
    // если в данный момент идет подключение к вайфай
    if (
      currentWifiStore
        .currentWifi?.status ===
      SIMPLE_STATUSES.LOADING ||
      filteredList === EMPTY_CONSTS.NULL
    ) return
    const element = filteredList.find((el) => el.bssid === bssid)
    if (element == null) return
    if (element.is_password === EMPTY_CONSTS.FALSE) {
      api.network
        .connectToWiFi({
          bssid: element.bssid,
          ssid: element.ssid,
          autoconnect: EMPTY_CONSTS.FALSE
        })
        .then(() => {
          currentWifiStore.set(
            {
              ...element,
              autoconnect: EMPTY_CONSTS.FALSE,
              status: SIMPLE_STATUSES.LOADING,
              connection_type: IP_TYPE.DYNAMIC
            }
          )
        })
        .catch((e) => {
          log('chooseWifi Error', 'info')
          console.log(e)
        })
    } else {
      setWifiToConnect(element)
    }
  }

  /**
   * handler when we scroll list block
   * tell us about scroll end block
   * @param e
   */
  const handleScrollListNetwork = (e: TScrollEvent): void => {
    const isEnd = isScrollEnd(e)
    if (isEnd) {
      nextPage()
    }
  }

  /**
   * Mount and we already have conected Wifi
   * @returns
   */
  const firstLoadWithActiveWifi = (): void => {
    if (
      list === EMPTY_CONSTS.NULL ||
      list === EMPTY_CONSTS.UNDEFINED
    ) { return }
    const element = list.find(
      (el) => el.in_use === EMPTY_CONSTS.TRUE
    )
    // случай если у нас в сторе сеть подключена, а пофакту по каким то причинам отключилась  на бэке
    // при препосещении на эту страницу уберем текущий вайфай из стора
    if (element === undefined && currentWifiStore.currentWifi?.status !== SIMPLE_STATUSES.ERROR) {
      currentWifiStore.deny()
    }
    if (
      element === EMPTY_CONSTS.UNDEFINED ||
      element.bssid === currentWifiStore.currentWifi?.bssid
    ) { return }
    // сработает если в списке вайфаев есть подключенная сеть, и в сторе тоже
    // и их bssid не совпадают
    // тогда обновим в сторе сеть
    currentWifiStore.set({
      ...element,
      status: SIMPLE_STATUSES.SUCCESS
    })
  }

  /**
   * filtering wifi list
   * delete current wifi
   */
  useEffect(() => {
    if (list === null) setFilteredList(null)
    if (list === EMPTY_CONSTS.NULL || currentWifiStore?.currentWifi?.status === SIMPLE_STATUSES.LOADING) return
    const newList: IWifi[] =
      currentWifiStore.currentWifi === EMPTY_CONSTS.NULL
        ? list
        : list.filter(
          (el) => el.in_use === EMPTY_CONSTS.FALSE
        )
    setFilteredList(newList)
  }, [list, currentWifiStore.currentWifi])

  /**
   * revalidate if current wifi was denied by socket (ws_wifi_disconnect)
   */
  useEffect(() => {
    if (
      currentWifiStore.currentWifi === null &&
      isFirstLoading !== true
    ) {
      revalidateData()
    }
  }, [currentWifiStore.currentWifi])

  /**
   * suscribe to Firstlading list and show or hide Spinner
   * FirstLoading happen if we havn`t cash data
   * and component is Mount
   */
  useEffect(() => {
    if (isFirstLoading === EMPTY_CONSTS.FALSE) {
      firstLoadWithActiveWifi()
    }
  }, [isFirstLoading])

  useEffect(() => {
    const fetchProtocols = (): void => {
      void api.network.getAllProticols().then((data) => {
        setProtocols(data)
      })
    }
    void fetchProtocols()
  }, [])

  return (
    <CSSTransitionGroup className={styles.container}>
      {
        wifiToConnect !== EMPTY_CONSTS.NULL &&
          <ConnectTowifi
            protocols={protocols}
            wifiToConnect={wifiToConnect}
            setWifiToConnect={setWifiToConnect}
          />
      }
      {
        (currentWifiStore.currentWifi !== EMPTY_CONSTS.NULL && isFirstLoading === false) &&
          <WifiConnectionCard
            handleReconnectWifi={chooseWifi}
            {...currentWifiStore.currentWifi}
          />
      }
      <div className={styles.list_header}>
        <Typography
          size={40}
          weight={700}
        >
          Доступные сети
        </Typography>
        <button
          className={styles.list_header__update}
          onClick={revalidateData}
          type='button'
        >
          Обновить
        </button>
      </div>
      <AllowWifiList
        list={filteredList}
        chooseWifi={chooseWifi}
        handleScrollListNetwork={handleScrollListNetwork}
        isCurrentWifi={Boolean(
          currentWifiStore.currentWifi
        )}
      />
      <div className={styles['add-wifi__wrap']}>
        <NavLink
          to={addNewNetURL}
          className={styles['add-wifi__btn']}
        >
          Добавить сеть
        </NavLink>
      </div>
    </CSSTransitionGroup>
  )
}

export default observer(WiFiList)
