import { IMainApi, IProblemApi } from '@/api/interfaces'
import { IProblemList } from '@/api/interfaces/Equipment'
import { IProblem } from '@/interfaces/Problem'
import { QueryData, QueryParams } from '@garpix/base-api'

class ProblemApi implements IProblemApi {
  ctx: IMainApi
  constructor (ctx: IMainApi) {
    this.ctx = ctx
  }

  /**
   * Получить список проблем
   */
  getProblems = async (
    params: QueryParams<IProblemList> | undefined
  ): Promise<IProblemList> => {
    const res =
      await this.ctx.getWithErrorHandler<IProblemList>(
        '/equipment-problem/',
        params,
        undefined,
        this.ctx.errorHandlers.handleErrorRedirect
      )
    return res.data
  }

  /**
   * сказать что у прибора есть проблемма
   */
  setProblemForEquip = async (
    params: any
  ): Promise<IProblemList['results']> => {
    const res = await this.ctx.post<
    IProblemList['results']
    >('/equipment-problem-user/', params)

    return res.data
  }

  /**
   * добавить описание для проблемы
   */
  sendDescProblem = async (
    params: QueryData<IProblem[]>
  ): Promise<IProblemList['results']> => {
    const res = await this.ctx.post<
    IProblemList['results']
    >('/equipment-problem/', params)

    return res.data
  }
}

export default ProblemApi
