import { IMainApi, INetworkApi } from '@/api/interfaces'
import { IObjectValuesLan } from '@/interfaces/ObjectPage'
import {
  IAllWiFiConnections,
  IConnectStaticLan,
  IConnectToWifi,
  IConnectWiFi,
  IGetCurrentWifi,
  IGetAllWiFiConnections,
  ISettingsConnectedWifi,
  IConnectToWifiEnterprise,
  TAllProtocols,
  TLanTurnOn
} from '@/api/interfaces/Network'
import {
// serializeGetAllWiFiConnections
} from './serializers'

class NetworkApi implements INetworkApi {
  ctx: IMainApi
  constructor (ctx: IMainApi) {
    this.ctx = ctx
  }

  connectStaticLan = async (
    values: IConnectWiFi
  ): Promise<IConnectStaticLan> => {
    const res = await this.ctx.post<IConnectStaticLan>(
      '/wifi_lan/lan/connect_static_lan/',
      values
    )
    return res.data
  }

  connectToWiFi = async (
    options: IConnectToWifi
  ): Promise<IConnectWiFi> => {
    // return await new Promise((resolve, reject) => {
    //   setTimeout(()=>{
    //     resolve({ status: 200 })
    //   }, 1000)
    // })
    const res = await this.ctx.post<IConnectWiFi>(
      '/wifi_lan/wifi/connect_to_wifi/',
      options
    )
    return res.data
  }

  changeSettingsConnectedWifi = async (
    options: ISettingsConnectedWifi
  ): Promise<any> => {
    // return await new Promise((resolve, reject) => {
    //   setTimeout(()=>{
    //     resolve({ status: 200 })
    //   }, 1000)
    // })
    const res = await this.ctx.post<IConnectWiFi>(
      '/wifi_lan/wifi/change_settings_connected_wifi/',
      options
    )
    return res.data
  }

  wiFiTurnOn = async (): Promise<IConnectWiFi> => {
    // return await new Promise((resolve, reject) => {
    //     resolve({ status: 200 })
    // })
    const res = await this.ctx.post<IConnectWiFi>(
      '/wifi_lan/wifi/wifi_turn_on/',
      {}
    )
    return res.data
  }

  wiFiTurnOff = async (): Promise<IConnectWiFi> => {
    // return await new Promise((resolve, reject) => {
    //     resolve({ status: 200 })
    // })
    const res = await this.ctx.post<IConnectWiFi>(
      '/wifi_lan/wifi/wifi_turn_off/',
      {}
    )
    return res.data
  }

  connectDHCPWiFi = async (): Promise<any> => {
    const res = await this.ctx.post(
      '/wifi_lan/lan/connect_dhcp_lan/',
      {}
    )
    return res.data
  }

  getSettingDHCPWiFi =
  async (): Promise<IObjectValuesLan> => {
    const res = await this.ctx.get<IObjectValuesLan>(
      '/wifi_lan/lan/get_dhcp_settings/',
      {}
    )
    return res.data
  }

  connectLanTurnOn = async (): Promise<TLanTurnOn> => {
    const res = await this.ctx.post<TLanTurnOn>(
      '/wifi_lan/lan/lan_turn_on_off/',
      {}
    )
    return res.data
  }

  getCurrentWifi = async (): Promise<IGetCurrentWifi> => {
    const res = await this.ctx.get<any>(
      '/wifi_lan/wifi/get_info_about_connected_wifi/'
    )
    return res.data
  }

  getAllWiFiConnections = async (
    options: IGetAllWiFiConnections
  ): Promise<IAllWiFiConnections> => {
    // return await new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(serializeGetAllWiFiConnections({} as IAllWiFiConnections, options))
    //   }, 2000)
    // })
    const res = await this.ctx.getWithErrorHandler<any>(
      '/wifi_lan/wifi/get_all_wifi_connections/',
      options,
      undefined,
      this.ctx.errorHandlers.handleErrorRedirect
    )
    return res.data
  }

  getMethodAuth = async (): Promise<any> => {
    const res = await this.ctx.get<any>(
      '/wifi_lan/wifi/get_method_auth/',
      {}
    )
    return res.data
  }

  getProtocols = async (): Promise<any> => {
    const res = await this.ctx.get<any>(
      '/wifi_lan/wifi/get_protocols/',
      {}
    )
    return res.data
  }

  onReboot = async (): Promise<any> => {
    const res = await this.ctx.post<any>(
      '/wifi_lan/reboot/',
      {}
    )
    return res.data
  }

  getAllProticols = async (): Promise<TAllProtocols> => {
    const res = await this.ctx.get<any>(
      '/wifi_lan/wifi/get_protocols/'
    )
    return res.data
  }

  getAllAuthMethods = async (): Promise<string[]> => {
    const res = await this.ctx.get<any>(
      '/wifi_lan/wifi/get_method_auth/'
    )
    return res.data
  }

  connectToWiFiEnterprise = async (
    options: IConnectToWifiEnterprise
  ): Promise<IConnectToWifiEnterprise> => {
    // return await new Promise((resolve, reject) => {
    //   setTimeout(()=>{
    //     resolve({ status: 200 })
    //   }, 1000)
    // })
    const res = await this.ctx.post<IConnectToWifiEnterprise>(
      '/wifi_lan/wifi/connect_to_wifi_enterprise/',
      options
    )
    return res.data
  }
}

export default NetworkApi
