import React from 'react'

import { Typography } from '@/views/typography'

import styles from './styles/index.module.scss'
import { EMPTY_CONSTS } from '@/const'

interface IProps {
  label: string
  value?: string
}

const Row: React.FC<IProps> = ({ label, value }) => {
  return (
    <div className={styles.item}>
      <Typography
        size={40}
        weight={500}
        className={styles.item__label}
      >
        {label}:
      </Typography>
      <Typography
        size={40}
        weight={700}
        className={styles.item__value}
      >
        {value === EMPTY_CONSTS.STR ||
        value === EMPTY_CONSTS.UNDEFINED
          ? '-'
          : value}
      </Typography>
    </div>
  )
}

export default Row
