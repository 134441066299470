import React, { useEffect, useState } from 'react'

import MainLayout from '@/components/mainLayout/MainLayout'
import ErrorPipeLine from '@/components/ErrorPipline'
import { Spinner } from '@/views/spinner'
import Core from './Core'

import { useStores } from '@/hooks'
import { IStorageConfigSerialized } from '@/api/interfaces/Config'
import { EMPTY_CONSTS, THEME_MODAL } from '@/const'
/**
 * @component
 * * Компонент Модалки с информацией о шкафе
 */
const DeviceInformation: React.FC =
  (): React.ReactElement => {
    const [storageConfig, setStorageConfig] =
      useState<IStorageConfigSerialized>()
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    const store = useStores()

    const handleCloseModal = (): void => {
      store.modalStore.setTheme(THEME_MODAL.DEFAULT)
      store.modalStore.close()
    }
    /**
     * @effect
     * * Запрашиваем данные о шкафе
     */
    useEffect(() => {
      store.api.config
        .getStorageConfig()
        .then(setStorageConfig)
        .catch(() => {
          setIsError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, [])

    return (
      <MainLayout
        logoutCallback={handleCloseModal}
        handleBack={handleCloseModal}
      >
        {isLoading || storageConfig === EMPTY_CONSTS.UNDEFINED
          ? (
            <Spinner isFullPage />
            )
          : !isError
              ? (
                <Core storageConfig={storageConfig} />
                )
              : (
                <ErrorPipeLine
                  hideBtn
                  linkBack='/'
                  errorMessage='Произошла неожиданная ошибка!'
                />
                )}
      </MainLayout>
    )
  }

export default DeviceInformation
