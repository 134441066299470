import * as Yup from 'yup'
import schemas from '@/utils/schemas'
import { FORM_NAMES } from '@/utils/formNames'
import { AddWifiFormSchema, ConnectWifiFormSchema, SettingCurrentWifiFormSchema } from './interfaces'

const NETWORK_CONNECTION_STATIC_LAN = Yup.object().shape({
  [FORM_NAMES.MASK]: schemas.mask,
  [FORM_NAMES.IP]: schemas.ip,
  [FORM_NAMES.GATEWAY]: schemas.gateway,
  [FORM_NAMES.DNS]: schemas.dns
})

const NETWORK_CONNECTION_DINAMIC_LAN = Yup.object().shape({
  [FORM_NAMES.MASK]: schemas.notRequiredString,
  [FORM_NAMES.IP]: schemas.notRequiredString,
  [FORM_NAMES.GATEWAY]: schemas.notRequiredString,
  [FORM_NAMES.DNS]: schemas.notRequiredString
})

const addWifiFormSchem = (
  isDinamicIP: boolean,
  isPassword: boolean,
  isEnterprise: boolean
): AddWifiFormSchema => {
  return Yup.object({
    ssid: schemas.ssid,
    security: schemas.security,
    autoconnect: schemas.autoConnect,
    password: isEnterprise ? schemas.requiredString : isPassword ? schemas.password : schemas.notRequiredString,
    ip: isDinamicIP ? schemas.notRequiredString : schemas.ip,
    mask: isDinamicIP ? schemas.notRequiredString : schemas.mask,
    gateway: isDinamicIP ? schemas.notRequiredString : schemas.gateway,
    dns: isDinamicIP ? schemas.notRequiredString : schemas.dns,
    login: isEnterprise ? schemas.login : schemas.notRequiredString,
    domain: schemas.notRequiredString,
    protocol_choices: isEnterprise ? schemas.requiredString : schemas.notRequiredString
  })
}

const connectWifiFormSchem = (
  isDinamicIP: boolean,
  isEnterprise: boolean
): ConnectWifiFormSchema => {
  return Yup.object({
    ssid: schemas.ssid,
    autoconnect: schemas.autoConnect,
    password: isEnterprise ? schemas.requiredString : schemas.password,
    ip: isDinamicIP ? schemas.notRequiredString : schemas.ip,
    mask: isDinamicIP ? schemas.notRequiredString : schemas.mask,
    gateway: isDinamicIP ? schemas.notRequiredString : schemas.gateway,
    dns: isDinamicIP ? schemas.notRequiredString : schemas.dns,
    login: isEnterprise ? schemas.login : schemas.notRequiredString,
    domain: schemas.notRequiredString,
    protocol_choices: isEnterprise ? schemas.requiredString : schemas.notRequiredString
  })
}

const settingCurrentWifiFormSchem = (
  isDinamicIP: boolean,
  isEnterprise: boolean
): SettingCurrentWifiFormSchema => {
  return Yup.object({
    autoconnect: schemas.autoConnect,
    ip: isDinamicIP ? schemas.notRequiredString : schemas.ip,
    mask: isDinamicIP ? schemas.notRequiredString : schemas.mask,
    gateway: isDinamicIP ? schemas.notRequiredString : schemas.gateway,
    dns: isDinamicIP ? schemas.notRequiredString : schemas.dns,
    password: isEnterprise ? schemas.requiredString : schemas.notRequiredString,
    login: isEnterprise ? schemas.login : schemas.notRequiredString,
    domain: schemas.notRequiredString,
    protocol_choices: isEnterprise ? schemas.requiredString : schemas.notRequiredString
  })
}

export { NETWORK_CONNECTION_STATIC_LAN, NETWORK_CONNECTION_DINAMIC_LAN, addWifiFormSchem, connectWifiFormSchem, settingCurrentWifiFormSchem }
