import { useEffect, useState } from 'react'
import { useStores } from './useStores'
import {
  ISpinnerProps,
  IUseGlobalSpinner
} from '@/interfaces/Hooks'

/**
 * Global Spinner which face all page without header
 * Spinner Placed in MainLayout
 * connected to along right right place
 *
 * @param props
 * @returns
 */
const useGlobalSpinner = (
  props?: ISpinnerProps
): IUseGlobalSpinner => {
  const store = useStores()
  const { show, hide, isShow } = store.spinerStore
  const [isSpinnerShow, setIsSpinnerShow] =
    useState(isShow)
  const [spinnerProps, setSpinnerProps] = useState(props)

  /**
   * handler showSpinner
   */
  const showSpinner = (): void => {
    show()
  }

  /**
   * handler hide Spinner
   */
  const hideSpinner = (): void => {
    hide()
  }

  /**
   * handler to set (change) spinner props
   */
  const setSpinnerProp = (
    key: string,
    value: boolean | string
  ): void => {
    setSpinnerProps({
      ...spinnerProps,
      [key]: value
    })
  }

  /**
   * suscribe to track mobx spinner store
   */
  useEffect(() => {
    if (isShow !== isSpinnerShow) {
      setIsSpinnerShow(isShow)
    }
  }, [isShow])

  return {
    isSpinnerShow,
    spinnerProps,
    showSpinner,
    hideSpinner,
    setSpinnerProp
  }
}

export default useGlobalSpinner
