import React, { FC } from 'react'
import classNames from 'classnames'
import { Typography } from '@/views/typography'
import { Switch } from '@/views/switch'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'

interface IProps {
  value: boolean
  handleSwitch: () => void
}

export const AutoConnection: FC<IProps> = ({
  value,
  handleSwitch
}) => {
  return (
    <CSSTransitionGroup className={styles.container}>
      <Typography
        size={40}
        className={classNames(styles.container__title)}
      >
        Подключаться автоматически
      </Typography>
      <Switch checked={value} onChange={handleSwitch} />
    </CSSTransitionGroup>
  )
}
