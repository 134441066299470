import React, { FC, useEffect, useState } from 'react'
import Keyboard from 'react-simple-keyboard'
import {
  displayKeyboard,
  layoutKeyboard
} from '@/components/keyboard/const'
import {
  EnumTypeKeyboard,
  TypeKeyboard
} from '@/components/keyboard/types'
import './style/index.scss'
import classNames from 'classnames'
import styles from './style/style.module.scss'

interface IKeyboardLayout {
  value: string | null
  className?: string
  open: boolean
  onChange: (value: string) => void
  children: React.ReactNode
  handlecontinue?: (props?: unknown) => unknown
}

interface IKeyboardRef {
  setInput: (value: string) => void
}

/**
 * Компонент HOC Клавиатуры
 * оборачивает контент формы
 * при клике на новый импут пустой или нет (value приходящее нужно заменить)
 * @param
 * value - value текущего поля с которым работаем
 * onChange - вернет измененное value (допечатанно или удаленно)
 * open - статус, открыта клавиаткра или закрыта
 * className - доп стили
 * children - контент
 * @returns
 */
export const KeyboardLayout: FC<IKeyboardLayout> = ({
  value,
  className,
  open,
  onChange,
  children,
  handlecontinue
}) => {
  const [keyboard, setKeyboard] =
    useState<IKeyboardRef | null>(null)
  const [layoutName, setLayoutName] =
    useState<EnumTypeKeyboard>(TypeKeyboard.default)

  const onKeyPress = (button: string): void => {
    if (button === '{capslock}') {
      if (layoutName === TypeKeyboard.numeric) return
      if (layoutName !== TypeKeyboard.english && layoutName !== TypeKeyboard.englishBig) {
        setLayoutName(
          layoutName === TypeKeyboard.default
            ? TypeKeyboard.shift
            : TypeKeyboard.default
        )
      } else {
        setLayoutName(
          layoutName === TypeKeyboard.english
            ? TypeKeyboard.englishBig
            : TypeKeyboard.english
        )
      }
    }

    if (button === '{language}') {
      if (layoutName === TypeKeyboard.numeric) {
        setLayoutName(
          TypeKeyboard.default
        )
      } else if (layoutName !== TypeKeyboard.english && layoutName !== TypeKeyboard.englishBig) {
        setLayoutName(
          TypeKeyboard.english
        )
      } else {
        setLayoutName(
          TypeKeyboard.default
        )
      }
    }

    if (button === '{controlleft}') {
      setLayoutName(
        layoutName === TypeKeyboard.numeric
          ? TypeKeyboard.default
          : TypeKeyboard.numeric
      )
    }

    if (button === '{altright}' && (handlecontinue != null)) {
      handlecontinue()
    }
  }

  const commonKeyboardOptions = {
    onChange: (input: string) => onChange(input),
    onKeyPress: (button) => onKeyPress(button),
    theme: `hg-theme-default hg-theme-${layoutName} hg-layout-default hg-layout-${layoutName}`
  }

  const keyboardOptions = {
    ...commonKeyboardOptions,
    layout: layoutKeyboard,
    display: {
      ...displayKeyboard,
      '{controlleft}':
        layoutName === TypeKeyboard.numeric
          ? 'АБВ'
          : '?123'
    }
  }

  useEffect(() => {
    if (typeof value === 'string') {
      keyboard?.setInput(value)
    }
  }, [value])

  return (
    <>
      {children}
      <div
        className={classNames(
          styles['keyboard-wrap'],
          className
        )}
        data-open={open}
      >
        <div className='keyboard'>
          <div className='keyboard-container'>
            <Keyboard
              keyboardRef={(r) => setKeyboard(r)}
              layoutName={layoutName}
              {...keyboardOptions}
            />
          </div>
        </div>
      </div>
    </>
  )
}
