import React, { FC } from 'react'
import styles from './style/index.module.scss'
import { Button } from '@/views/button'

interface IProps {
  isDinamicIP: boolean
  handleChangeIpType: (newvalue: boolean) => void
}

export const DynamicStaticButtons: FC<IProps> = ({
  isDinamicIP,
  handleChangeIpType
}) => {
  const handleClickDinamic = (): void => {
    handleChangeIpType(true)
  }

  const handleClickStatic = (): void => {
    handleChangeIpType(false)
  }

  return (
    <div className={styles.options}>
      <Button
        color={isDinamicIP ? 'orange' : 'white'}
        onClick={handleClickDinamic}
        isShadow={false}
        positionLabel='left'
        size='medium'
        data-testid='Button_Dinamic'
      >
        Динамический
      </Button>
      <Button
        onClick={handleClickStatic}
        isShadow={false}
        color={!isDinamicIP ? 'orange' : 'white'}
        positionLabel='left'
        size='medium'
        data-testid='Button_Static'
      >
        Статический IP
      </Button>
    </div>
  )
}

export default DynamicStaticButtons
