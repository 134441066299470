import React from 'react'
import EmptyCells from '@/views/infoMessagePage/InfoMessagePage'
import { usePage, useSiteConfig } from '@/hooks'
import { DEFAULT_LINKS } from '@/const'

const SeizeFaultyType: React.FC =
  (): React.ReactElement => {
    const config = useSiteConfig()
    const page = usePage()

    return (
      <EmptyCells
        success
        typeName={
          page?.init_state?.equipment_type ??
          'Не известный тип'
        }
        errorMessage='Всё неисправное оборудование изъято'
        linkBack={undefined} // явно указал что б он использовал методы навигации по страницам назад
        linkToMain={config?.links?.verification ?? DEFAULT_LINKS.verification}
      />
    )
  }

export default SeizeFaultyType
