import React, { FC } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import { Typography } from '@/views/typography'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { CheckIcon, ErrorClose } from '@/assets/icon'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import styles from './styles/index.module.scss'
import { usePage } from '@/hooks'

const SETTINGS = {
  linkBack: '/',
  visibleLinkBack: true,
  success: false
}

const ReturnAgainEquipment: FC = () => {
  const page = usePage()
  const { linkBack, visibleLinkBack, success } = SETTINGS
  const linkNext =
    page?.init_state?.cell_slug !== undefined
      ? page?.init_state?.cell_slug
      : '/'
  const colorNextLink = 'white'
  return (
    <MainLayout
      visibleLinkBack={visibleLinkBack}
      linkBack={linkBack}
    >
      <div className={styles.container}>
        <div
          className={classNames(styles.container__content)}
        >
          <div
            className={classNames(styles.icon__container, {
              [styles.icon__container_success]: success
            })}
          >
            <GxIcon
              src={success ? CheckIcon : ErrorClose}
              className={classNames(styles.icon, {
                [styles.icon_success]: success
              })}
            />
          </div>
          <Typography
            size={80}
            weight={700}
            className={styles.container__title}
          >
            Оборудование числится сданным, продолжить? Отчет
            о проблеме отправлен ответсвенным.
          </Typography>
          {/* <NavLink
            to={linkToMain}
            className={styles.container__button}
          >
            Вернуться в меню
          </NavLink> */}
          <NavLink
            to={linkNext}
            className={classNames(
              styles.container__button,
              {
                [styles[colorNextLink]]: true
              }
            )}
          >
            Далее
          </NavLink>
        </div>
      </div>
    </MainLayout>
  )
}

export default ReturnAgainEquipment
