import React from 'react'

import { GxIcon } from '@garpix/garpix-web-components-react'
import { ShiftEndIcon } from '@/assets/icon'

import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import { Button } from '../button'
import { Typography } from '../typography'

import style from './style/index.module.scss'
import classNames from 'classnames'

interface IProps {
  handleClickTopButton: () => void
  handleClickBottonButton: () => void
  isLoadEndDelvEquip: boolean
  isLoadTempDelvEquip: boolean
}
/**
 * @view
 * * вьха страницы временной сдачи
 * @param handleClickTopButton     клик на верхнюю кнопку
 * @param handleClickBottonButton  клик на нижнюю кнопку
 */
const TemporaryDeliveryEquipmentViews: React.FC<IProps> = ({
  handleClickTopButton,
  handleClickBottonButton,
  isLoadEndDelvEquip,
  isLoadTempDelvEquip
}) => {
  console.log('isLoadEndDelvEquip', isLoadEndDelvEquip)
  return (
    <CSSTransitionGroup
      className={style['temporary-delivery']}
    >
      <Button
        isShadow={false}
        color='white'
        onClick={handleClickTopButton}
        className={style['temporary-delivery__button']}
      >
        <div className={classNames(style.clock)}>
          <div
            className={classNames(style.hand, {
              [style['hour-hand']]: true,
              [style['hour-hand--animate']]:
                isLoadTempDelvEquip
            })}
          />
          <div
            className={classNames(style.hand, {
              [style['minute-hand']]: true,
              [style['minute-hand--animate']]:
                isLoadTempDelvEquip
            })}
          />
        </div>
        <Typography size={60}>Сдать временно</Typography>
      </Button>
      <Button
        isShadow={false}
        color='white'
        onClick={handleClickBottonButton}
        className={style['temporary-delivery__button']}
      >
        <GxIcon
          className={style['temporary-delivery__icon']}
          src={ShiftEndIcon}
        />
        <Typography size={60}>Конец смены</Typography>
      </Button>
    </CSSTransitionGroup>
  )
}

export default TemporaryDeliveryEquipmentViews
