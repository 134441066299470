import React, { FC, useEffect, useState } from 'react'
import {
  GxButton
} from '@garpix/garpix-web-components-react'
import { KeyboardLayout } from '@/components/keyboard/Keyboard'
import { Typography } from '@/views/typography'
import styles from './styles/index.module.scss'
import { TextArea } from '@/views/textArea'
import { ERROR_MAIN_TEXT } from '@/const'
import { IFocusTextAreaEvent } from '@/interfaces/Common'

interface TDesc {
  description: string
  reqiured?: boolean
  setDescription: (V: string) => void
  sendDescriptionProblem: () => void
}

const DescriptionProblem: FC<TDesc> = ({
  description,
  reqiured = false,
  sendDescriptionProblem,
  setDescription
}) => {
  const [value, setValue] = useState<string>('')
  const [isOpenKeyboard, ssetIsOpenKeyboard] = useState(false)
  const [error, setError] = useState('')

  const onChangeValue = (val: string): void => {
    setDescription(val)
    setValue(val)
  }

  const onFocusTextArea = (e: IFocusTextAreaEvent): void => {
    const value = e.target.value
    ssetIsOpenKeyboard(true)
    onChangeValue(value)
  }

  const decorateSend = (): void => {
    if (description.length === 0 && reqiured) {
      setError(ERROR_MAIN_TEXT.NO_DESCRIPTION)
    } else {
      sendDescriptionProblem()
    }
  }

  useEffect(() => {
    if (description !== '' && error !== '') {
      setError('')
    }
  }, [description])

  return (
    <KeyboardLayout
      onChange={onChangeValue}
      open={isOpenKeyboard}
      value={value}
    >
      <div className={styles.container}>
        <Typography
          size={60}
          className={styles.container__title}
        >
          Опишите проблему
        </Typography>
        <TextArea
          value={description}
          resize='none'
          required={reqiured}
          error={Boolean(error)}
          errorMessage={error}
          onFocus={onFocusTextArea}
          outerStyles={styles.textarea}
        />
        <GxButton
          onClick={decorateSend}
          className={styles.container__send}
        >
          Отправить
        </GxButton>
      </div>
    </KeyboardLayout>
  )
}

export default DescriptionProblem
