import { usePage as usePageCms } from '@garpix/cms'
import { IContextStorage } from '@/interfaces/ObjectPage'

const useContextStorage = (): IContextStorage => {
  const value = usePageCms()
  const defaultUserData: IContextStorage = {
    free_cell: false
  }
  const userData: IContextStorage =
    value?.page?.init_state?.global?.storage
  if (userData === undefined) {
    return defaultUserData
  }
  return userData
}

export default useContextStorage
