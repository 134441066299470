import React, {
  FC,
  useEffect,
  useRef,
  useState
} from 'react'
import { Timer } from '@/utils/timer'
import { StyleTimer } from '@/components/timer/const'
import { useStores } from '@/hooks'
import { TIMEOUT } from '@/const'
import EmptyTimer from '@/views/emptyTimer/EmptyTimer'
import { notify } from '@/utils/notify'
import './style/index.scss'

interface TTimerComponent {
  cellNumber: number
  setIsClose: React.Dispatch<React.SetStateAction<boolean>>
}

const TimerComponent: FC<TTimerComponent> = ({
  cellNumber,
  setIsClose
}) => {
  const store = useStores()

  const secondsRef = useRef(30)
  const [seconds, setSeconds] = useState(30)
  const onChangeSeconds = (val: number): void => {
    secondsRef.current = val
  }

  /**
   * функция для получения таймера с бэка
   */
  const pingTimer = (): void => {
    store.api.cell
      .getTime(cellNumber)
      .then((res) => {
        if (res.time === 0) {
          setSeconds(0)
          onChangeSeconds(0) // сохранение значения таймера с бэка
          setIsClose(true)
        } else {
          onChangeSeconds(seconds - 1)
          setSeconds(seconds - 1)
        }
      })
      .catch(() =>
        notify.error('Ошибка получения таймера!')
      )
  }

  const checkTimer = (): void => {
    /**
     * запускаем таймер, когда секунд осталось больше 0 и
     * когда НЕ запускаем получение секунд с бэкэ
     */
    if (
      secondsRef.current > 0 &&
      secondsRef.current % 10 !== 0
    ) {
      onChangeSeconds(secondsRef.current - 1)
      setSeconds(secondsRef.current - 1)
    }
    /**
     * раз в 10 секунд запускаем получение таймера с бэка для синхронизации
     */
    if (seconds % 10 === 0) {
      pingTimer()
    }
  }

  /**
   * запуск таймера на фронте
   */
  useEffect(() => {
    const timer = setTimeout(checkTimer, TIMEOUT._1000)
    if (secondsRef.current !== seconds) {
      setSeconds(secondsRef.current)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [seconds])

  if (seconds < 1) {
    return <EmptyTimer />
  }

  return (
    <Timer
      value={(30 - seconds) * 3.3333}
      text={`${secondsRef.current}`}
      background
      strokeWidth={50}
      classes={StyleTimer(seconds)}
    />
  )
}

export default TimerComponent
