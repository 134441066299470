import React, { FC, useEffect, useState } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import { useCurrentUser, useSiteConfig, useStores } from '@/hooks'
import EquipmentStatusComponent from '@/views/equipmentStatusComponent/equipmentStatusComponent'
import { Typography } from '@/views/typography'
import { CELL, DEFAULT_LINKS, ERROR_MAIN_TEXT } from '@/const'
import { objToQs } from '@/utils'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'
import InfoContent from '@/views/infoContent'
import { useNavigate } from 'react-router-dom'

interface IErrorMessage {
  message: string
  description: string
  btnHandler: () => void
}

// todo: delete default params link
const EquipmentStatusAdmin: FC = () => {
  const { api } = useStores()
  const navigate = useNavigate()
  const config = useSiteConfig()
  const user = useCurrentUser()
  const [errorMessage, setErrorMessage] = useState<null | IErrorMessage>(null)

  const clearErrorMessage = (): void => {
    setErrorMessage(null)
  }

  const createErrorMessage = (
    message: string,
    description: string,
    btnHandler: () => void
  ): void => {
    setErrorMessage({
      message,
      description,
      btnHandler
    })
  }

  /**
   * получит список ячеек и выберет статус на
   * который необходиммо настроить редирект
   */
  const getForInfoCellList = (): void => {
    void api.cell.getAllStatusesCells().then((res) => {
      console.log('res', res)
    })
  }

  const linkTop = {
    cell_status: CELL.STATUS.OCCUPIED,
    content_status: CELL.CONTENT.AVAILABLE
  }

  /**
   * Обработчик клика на верхнюю NavLink страницы
   * в зависимости от роли можно делать проверки и пускать на стр или нет
   * @param event
   */
  const handleClickTop = (): void => {
    if (!user.available_working_equipment) {
      createErrorMessage(
        ERROR_MAIN_TEXT.NO_AVALIABLE_WORK_EQUIPMENT,
        '',
        clearErrorMessage
      )
      return
    }
    navigate(`${config?.links?.hand_over_in_cell ?? DEFAULT_LINKS.hand_over_in_cell
      }${objToQs(linkTop)}`)
  }

  /**
  * Обработчик клика на нижнюю NavLink страницы
  * в зависимости от роли можно делать проверки и пускать на стр или нет
  * @param event
  */
  const handleClickBottom = (): void => {
    if (!user.available_not_working_equipment) {
      createErrorMessage(
        ERROR_MAIN_TEXT.NO_AVALIABLE_UNWORK_EQUIPMENT,
        '',
        clearErrorMessage
      )
      return
    }
    navigate(config.links.faulty_equipment)
  }

  useEffect(() => {
    getForInfoCellList()
  }, [])

  return (
    <MainLayout linkBack={config?.links?.verification ?? DEFAULT_LINKS.verification}>
      <div className={styles.container}>
        {
          errorMessage !== null
            ? <InfoContent
                message={errorMessage.message}
                handlerClickBtn={errorMessage.btnHandler}
                description={errorMessage.description}
              />
            : (
              <>
                <CSSTransitionGroup
                  animation='fadeInTop'
                  component={Typography}
                  size={60}
                  weight={700}
                  className={styles.container__title}
                >
                  Изъять оборудование
                </CSSTransitionGroup>
                <EquipmentStatusComponent
                  isButtonTop
                  isButtonBottom
                  titleTop='Оборудование исправно'
                  titleBottom='Оборудование неисправно'
                  handleClickBtnTop={handleClickTop}
                  handleClickBtnBottom={handleClickBottom}
                />
              </>)
        }
      </div>
    </MainLayout>
  )
}
export default EquipmentStatusAdmin
