import React, { FC } from 'react'
import styles from './style/index.module.scss'
import { Button } from '@/views/button'
import { TYPE_CONNECTION } from '@/components/lanConnection/const'

interface TDynamicStaticButtons {
  values: string
  onChange: (TYPE_CONNECTION) => void
}
export const DynamicStaticButtons: FC<
TDynamicStaticButtons
> = ({ values, onChange }) => {
  return (
    <div className={styles.options}>
      <Button
        color={
          values === TYPE_CONNECTION.DYNAMIC
            ? 'orange'
            : 'white'
        }
        onClick={() => onChange(TYPE_CONNECTION.DYNAMIC)}
        isShadow={false}
        positionLabel='left'
        size='medium'
      >
        Динамический
      </Button>
      <Button
        onClick={() => onChange(TYPE_CONNECTION.STATIC)}
        isShadow={false}
        color={
          values === TYPE_CONNECTION.STATIC
            ? 'orange'
            : 'white'
        }
        positionLabel='left'
        size='medium'
      >
        Статический IP
      </Button>
    </div>
  )
}
