import {
  IEquipment,
  IEquipmentList
} from '@/api/interfaces/Equipment'

export const equipListSerializer = (
  res: any
): IEquipmentList => {
  return {
    total: res.count,
    currentPage: res.page,
    results: res.results.map(
      (
        elem: {
          id: any
          title: any
          equipment: {
            serial_number: any
            equipment_type: { title: any }
            title_ru: string
          }
          cell_number: any
          url: any
        } & { [key: string]: any }
      ) => {
        return {
          ...elem,
          id: elem.id,
          title: elem.title,
          serialNumber:
            elem?.equipment?.serial_number ?? '',
          equipmentTitle:
            elem?.equipment?.equipment_type?.title ?? '',
          equipmentDesc: elem?.equipment?.title_ru ?? '',
          cellNumber: elem.cell_number,
          url: elem.url,
          reservedTitle: elem?.reserved_for_equipment?.equipment_type?.title,
          reservedDesc: elem?.reserved_for_equipment?.title_ru
        }
      }
    )
  }
}

export const equipSerializer = (
  res: Array<{
    title_ru: string
    serial_number: any
  }>
): IEquipment => {
  return {
    title: res[0].title_ru,
    serialNumber: res[0].serial_number
  }
}
