import React, { FC } from 'react'
import classNames from 'classnames'
import { Pagination as PaginationComponent } from '@garpix/pagination/react'
import { IPagination } from '@garpix/pagination/core'
import Button from '@/components/paginator/Button'
import styles from './style/index.module.scss'

/**
 * @param total - кол-во данных
 * @param pageSize - размер страницы
 * @param currentPage - текущая страница
 * @param changePage - функция на изменение страницы
 */
interface PaginatorType {
  total: number
  pageSize: number
  currentPage: number
  changePage: (num: number) => void
}

const Paginator: FC<PaginatorType> = ({
  total,
  currentPage,
  pageSize,
  changePage
}) => {
  /**
   * обработчик для кнопки назад
   */
  const actionPrevBtn = (): void => {
    changePage(currentPage - 1)
  }
  /**
   * обработчик для кнопки вперед
   */
  const actionNextBtn = (): void => {
    changePage(currentPage + 1)
  }

  return (
    <PaginationComponent
      currentPage={currentPage}
      total={total}
      pageSize={pageSize}
    >
      {({ hasNextPage, hasPreviousPage }: IPagination) => {
        return (
          <div className={styles.container}>
            {hasPreviousPage && (
              <Button
                action={actionPrevBtn}
                className={styles.container__button}
                classNameIcon={classNames(
                  styles.container__button_icon,
                  styles.container__button_prev
                )}
              />
            )}

            {hasNextPage && (
              <Button
                action={actionNextBtn}
                className={classNames(
                  styles.container__button,
                  styles.container__button_next
                )}
                classNameIcon={
                  styles.container__button_icon
                }
              />
            )}
          </div>
        )
      }}
    </PaginationComponent>
  )
}

export default Paginator
