import React, { useRef } from 'react'
import { Typography } from '@/views/typography'
import useConnectToWifiForm from '@/hooks/useConnextToWifiForm'
import ConnectTowifiForm from '@/views/connectToWifiForm'
import { KeyboardLayout } from '../keyboard/Keyboard'
import { IWifi, TAllProtocols, TWifiConnectionType } from '@/api/interfaces/Network'
import { log } from '@/utils'
import { useStores } from '@/hooks'
import { AUTJORIZATION_METHODS, SIMPLE_STATUSES } from '@/const'
import { IFormConnectToWifiValues } from '@/interfaces/Hooks'
import styles from './style/index.module.scss'

interface IProps {
  wifiToConnect: IWifi
  setWifiToConnect: (wifi: IWifi | null) => void
  protocols: TAllProtocols
}

const ConnectTowifi: React.FC<IProps> = ({
  wifiToConnect,
  protocols,
  setWifiToConnect
}): React.ReactElement => {
  const { currentWifiStore } = useStores()
  const isEnterprise = useRef(wifiToConnect.security === AUTJORIZATION_METHODS['WPA2-Enterprise'])

  const beforeSubmit = (formObj: {connection_type: TWifiConnectionType} & IFormConnectToWifiValues): void => {
    log('beforeSubmit ConnectTowifi', 'info')
  }

  const afterSuccess = (formObj: {connection_type: TWifiConnectionType} & IFormConnectToWifiValues): void => {
    log('afterSuccess ConnectTowifi', 'info')
    currentWifiStore.set({ ...wifiToConnect, ...formObj, status: SIMPLE_STATUSES.LOADING })
    setWifiToConnect(null)
  }

  const afterError = (): void => {
    log('afterError ConnectTowifi', 'info')
  }

  const {
    values,
    errors,
    isDinamicIP,
    isKeyBoard,
    keyboardValue,
    selectProtololOptions,
    currentProtocol,
    changeIpType,
    handleSubmit,
    handleChange,
    handleSwith,
    onKeyboardInput,
    onFocusInput,
    onBlurInput,
    closeKeyboard,
    handleChangeProtocol
  } = useConnectToWifiForm({
    protocols,
    isEnterprise: isEnterprise.current,
    ssid: wifiToConnect.ssid,
    bssid: wifiToConnect.bssid,
    afterSuccess,
    afterError,
    beforeSubmit
  })

  return (
    <div className={styles.container} data-keyboard={isKeyBoard}>
      <KeyboardLayout
        onChange={onKeyboardInput}
        open={isKeyBoard}
        value={keyboardValue}
        handlecontinue={closeKeyboard}
      >
        <Typography
          size={60}
          weight={700}
          className={styles.container__title}
        >
          {wifiToConnect.ssid}
        </Typography>
        <ConnectTowifiForm
          values={values}
          errors={errors}
          isEnterprise={isEnterprise.current}
          protocols={selectProtololOptions}
          currentProtocol={currentProtocol}
          isDinamicIP={isDinamicIP}
          onChangeProtocol={handleChangeProtocol}
          changeIpType={changeIpType}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleSwith={handleSwith}
          onFocusInput={onFocusInput}
          onBlurInput={onBlurInput}
        />
      </KeyboardLayout>
    </div>
  )
}

export default ConnectTowifi
