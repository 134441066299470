import { TWifiConnectionType } from '@/api/interfaces/Network'
import { USER_ROLES } from '@/const'
// import { Page } from '@/api/interfaces/Page'

export interface IResponsePage {
  pageType: string
  page: any
  statusCode: number
}

export type IInitStatePage = IInitStatePageWifiList &
IInitStatePageNetSettings &
IInitStatePageSettingsWifi &
IInitStatePageLAN

export interface IEquipment {
  id: number
  serial_number: string
  slug: string
  title: string
}

export interface IObjectPage {
  id: number
  seo_title: string
  title: string
  seo_description: string
  description: string
  seo_keywords: string
  seo_image: string
  seo_author: string
  seo_og_type: string
  cell_number: number
  equipment: IEquipment
  error: number
  is_active: boolean
  display_on_sitemap: boolean
  slug: string
  content_status: string
  // страница настройка подключения лан
  dynamicValues: IObjectValuesLan | null
  staticValues: IObjectValuesLan | null
}

export type IObjectValuesLan = Record<string, string>

export interface IObjectPageLan extends IObjectPage {
  typeConnection: string
  lanConnected: boolean
  dynamicValues: IObjectValuesLan | null
  staticValues: IObjectValuesLan | null
}

export interface IObjectPageLanData {
  init_state: IInitStatePageLAN & {
    object: IObjectPageLan
  }
}

export interface IInitStatePageLAN {
  ip: string
  mask: string
  gateway: string
  dns: string
  connection_type: string
  lan_connected: boolean
}

export interface IInitStatePageWifiList {
  connected_wifi: boolean
}

export interface IInitStatePageNetSettings {
  connected_ssid: string | boolean
  connected_wifi: boolean
}

export interface IInitStatePageSettingsWifi {
  info_connected_wifi: ICurrentWifiPage
}
export interface ICurrentWifiPage {
  id: number | null
  ssid: string | null
  in_use: boolean | null
  is_password: boolean | null
  autoconnect: boolean | null
  signal_strength: string | null
  security: string | null
  ip: string | null
  mask: string | null
  gateway: string | null
  dns: string | null
  connection_type: TWifiConnectionType | null
}

export interface ILinks {
  administrirovanie_yacheek: string
  no_cell_available: string
  otsutstvuet_dostupnoe_oborudovanie: string
  page_user_equipment_not_connected: string
  page_resupply_of_equipment: string
  problems: string
  add_new_wifi_connect: string
  cell_list: string
  condition_equipment: string
  connect_wifi: string
  describe_the_problem: string
  faulty_equipment: string
  hand_over_equipment: string
  hand_over_in_cell: string
  home: string
  lan_settings: string
  load_more_equipment: string
  loadable_equipment: string
  main_menu_net: string
  net_settings: string
  open_cell: string
  getProblems: string
  seize_equipment: string
  seize_all_equipmentgetProblems: string
  seize_equipmentgetProblems: string
  select_another_cellgetProblems: string
  service_manager: string
  settings_connected_wifi: string
  settings_wifi: string
  types_of_equipment: string
  verification: string
  all_cells_filled: string
  page_surrender_of_equipment: string
  cell_admin_page: string
}

export interface ILogo {
  logo: string
  logo_url: string
  home_logo_url: string
  home_logo: string
}

export enum TWorkMode {
  Random = 'random',
  Manual = 'manually',
}

export interface ISiteConfig {
  links: ILinks
  logo: ILogo
  work_mode?: TWorkMode
  is_hand_over: boolean
}

export type TUserRole = keyof typeof USER_ROLES

export interface IUser {
  user_equipment_temporarily_renter: boolean
  role: string
  available_equipment: boolean
  available_working_equipment: boolean
  available_not_working_equipment: boolean
  user_related_equipment: boolean
}

export interface IContextStorage {
  free_cell: boolean
}
