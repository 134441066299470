import React, { FC } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import OptionEquipmentAdmin from '@/views/optionEquipmentAdmin/OptionEquipmentAdmin'
import { useSiteConfig, useStores } from '@/hooks'
import { objToQs } from '@/utils'
import useSWR from 'swr'
import { CELL, DEFAULT_LINKS } from '@/const'
import InfoMessagePage from '@/views/infoMessagePage/InfoMessagePage'
import { Spinner } from '@/views/spinner'

const OptionsFaultyEquipment: FC = () => {
  const { api } = useStores()
  const config = useSiteConfig()
  const qs = {
    cell_status: CELL.STATUS.OCCUPIED,
    content_status: CELL.CONTENT.NOT_WORKING
  }
  const { data, isLoading } = useSWR(
    qs,
    api.equipment.getEquipmentList
  )

  const OptionsFaultyEquipmentValue = [
    {
      id: 1,
      title: 'Забрать конкретное оборудование',
      value: `${config?.links?.hand_over_in_cell ?? DEFAULT_LINKS.hand_over_in_cell}${objToQs(
        qs
      )}`
    },
    {
      id: 2,
      title: 'Забрать конкретный тип оборудования',
      value: config?.links?.types_of_equipment ?? DEFAULT_LINKS.types_of_equipment
    }
    // {
    //   id: 3,
    //   label: 'Забрать всё неисправное оборудование',
    //   value: `${config.links.cell_list}?filter=${FILTERS_QS.CELL_LIST.GET_CONTEXT_EQUIP}`,
    // },
  ]

  const linkBack = config?.links?.seize_equipment ?? DEFAULT_LINKS.seize_equipment

  if (isLoading) {
    return (
      <MainLayout>
        <Spinner />
      </MainLayout>
    )
  }

  if (data?.results.length === 0) {
    return (
      <InfoMessagePage
        linkBack='/'
        errorMessage='Отсутствует неисправное оборудование'
        linkToMain=''
      />
    )
  }

  return (
    <MainLayout linkBack={linkBack}>
      <OptionEquipmentAdmin
        title='Неисправное оборудование'
        isDefaultUse={false}
        option={OptionsFaultyEquipmentValue}
      />
    </MainLayout>
  )
}

export default OptionsFaultyEquipment
