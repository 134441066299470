import React, { FC } from 'react'
import DynamicStaticButtons from '@/views/dynamicStaticButtons/addNewWifi'
import FormStaticConnection from '@/views/formStaticConnection/addNewWifi'
import { Input } from '@/views/input'
import { Select } from '@/views/select'
import { IAddConnectionProps } from './interfaces'
import { EMPTY_CONSTS } from '@/const'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'

const AddConnectionForm: FC<IAddConnectionProps> = ({
  values,
  errors,
  isDinamicIP = EMPTY_CONSTS.TRUE,
  isPassword = EMPTY_CONSTS.TRUE,
  securitiOptions,
  securityValue,
  protocols,
  currentProtocol,
  isEnterprise,
  handleChangeProtocol,
  handleChangeIpType,
  handleSelect,
  handleInput,
  handleFocusInput,
  handleBlurInput
}): React.ReactElement => {
  return (
    <CSSTransitionGroup className={styles.container}>
      <Input
        id='ssid'
        name='ssid'
        data-testid='ssid'
        label='Название сети'
        onChange={handleInput}
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        value={values.ssid}
        error={Boolean(errors.ssid)}
        errorMessage={errors.ssid}
        autoComplete='off'
      />
      <Select
        id='security'
        name='security'
        data-testid='security'
        label='Метод авторизации'
        onChange={handleSelect}
        options={securitiOptions}
        value={securityValue}
        isSearchable={EMPTY_CONSTS.FALSE}
      />
      {isPassword && (
        <Input
          id='password'
          name='password'
          data-testid='password'
          label='Пароль'
          type='password'
          onChange={handleInput}
          value={values.password}
          error={Boolean(errors.password)}
          errorMessage={errors.password}
          autoComplete='off'
          onFocus={handleFocusInput}
          onBlur={handleBlurInput}
        />
      )}
      {isEnterprise
        ? <Input
            id='login'
            name='login'
            data-testid='login'
            value={values.login}
            error={Boolean(errors.login)}
            errorMessage={errors.login}
            label='Логин'
            onChange={handleInput}
            onFocus={handleFocusInput}
            onBlur={handleBlurInput}
            autoComplete='off'
          />
        : null}
      {isEnterprise
        ? <Input
            id='domain'
            name='domain'
            data-testid='domain'
            value={values.domain}
            message='Не обязательное поле'
            error={Boolean(errors.domain)}
            errorMessage={errors.domain}
            label='Домен'
            onChange={handleInput}
            onFocus={handleFocusInput}
            onBlur={handleBlurInput}
            autoComplete='off'
          />
        : null}
      {isEnterprise
        ? <Select
            onChange={handleChangeProtocol}
            label='Протокол безопасности'
            value={currentProtocol}
            options={protocols}
          />
        : null}
      <DynamicStaticButtons
        isDinamicIP={isDinamicIP}
        handleChangeIpType={handleChangeIpType}
      />
      {isDinamicIP === EMPTY_CONSTS.FALSE && (
        <FormStaticConnection
          values={values}
          errors={errors}
          handleInput={handleInput}
          handleFocusInput={handleFocusInput}
          handleBlurInput={handleBlurInput}
        />
      )}
    </CSSTransitionGroup>
  )
}

export default AddConnectionForm
