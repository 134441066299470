import * as Yup from 'yup'
import { FORM_NAMES } from '@/utils/formNames'

export const REGEX = {
  [FORM_NAMES.IP]: /^(?:\d{1,3}\.){3}\d{1,3}$/
}

export const MESSAGE = {
  [FORM_NAMES.DNS]: 'Некорректный DNS',
  [FORM_NAMES.GATEWAY]: 'Некорректный шлюз',
  [FORM_NAMES.IP]: 'Некорректный IP',
  [FORM_NAMES.MASK]: 'Некорректная маска сети',
  [FORM_NAMES.PASSWORD]:
    'Пароль не должен быть меньше 8 символов',
  required_field: 'Обязательное поле'
}

const notRequiredString = Yup.string().notRequired()
const requiredString = Yup.string().required(
  MESSAGE.required_field
)

const login = Yup.string().required(
  MESSAGE.required_field
)

const gateway = requiredString.matches(
  REGEX[FORM_NAMES.IP],
  MESSAGE[FORM_NAMES.GATEWAY]
)
const ip = requiredString.matches(
  REGEX[FORM_NAMES.IP],
  MESSAGE[FORM_NAMES.IP]
)
const mask = requiredString.matches(
  REGEX[FORM_NAMES.IP],
  MESSAGE[FORM_NAMES.MASK]
)
const dns = Yup.string()
  .notRequired()
  .matches(REGEX[FORM_NAMES.IP], MESSAGE[FORM_NAMES.DNS])

const ssid = requiredString
const security = requiredString
const password = requiredString.min(
  8,
  MESSAGE[FORM_NAMES.PASSWORD]
)

const autoConnect = Yup.boolean().required(
  MESSAGE.required_field
)

export default {
  login,
  gateway,
  ip,
  mask,
  dns,
  ssid,
  password,
  autoConnect,
  security,
  notRequiredString,
  requiredString
}
