import {
  IIpTypes,
  ISimpleStatuses
} from '@/interfaces/consts'

export const PATHS = {
  ALL: {
    path: '*'
  }
}

export const EMPTY_CONSTS = {
  ARR: [],
  OBJ: {},
  TRUE: true,
  FALSE: false,
  NULL: null,
  UNDEFINED: undefined,
  STR: '',
  ZERO: 0,
  UNIT: 1,
  FUNC: () => {}
}

export const SIMPLE_STATUSES: ISimpleStatuses = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
}

export const SOCKET_SIGNALS = {
  ws_wifi_success: 'ws_wifi_success',
  ws_wifi_error: 'ws_wifi_error',
  ws_wifi_disconnect: 'ws_wifi_disconnect',
  ws_login_card: 'ws_login_card',
  ws_login_error_card: 'ws_login_error_card',
  ws_logout: 'ws_logout',
  ws_nfc_success: 'ws_nfc_success',
  ws_nfc_error: 'ws_nfc_error',
  ws_close_cell: 'ws_close_cell',
  ws_redirect: 'ws_redirect'
}

export const MOCKS_DATA = {
  OptionsEquipmentPage: {
    url_get_equip: '/spisochnaya-stranica-oborudovaniya',
    url_drop_equip: '/sostoyanie-oborudovaniya'
  },
  ReturnEquipment: {},
  Problems: {
    name_equipment: 'TSD',
    serial_number: '123'
  }
}

export const DEFAULT_SETTINGS_PROJECT = {
  NFC_SUCCESS: false,
  NFC_ERROR: false,
  NFC_COUNT_LABEL: 1,
  NFC_COUNT_READ_LABLE: 1,
  IS_TEMP_DELIVE_EQUIP: false,
  IS_END_WORK_DELIVE_EQUIP: false
}

export const DEFAULT_SETTINGS_PROJECT_NAME = {
  NFC_SUCCESS: 'NFC_SUCCESS',
  NFC_ERROR: 'NFC_ERROR',
  NFC_COUNT_LABEL: 'NFC_COUNT_LABEL',
  NFC_COUNT_READ_LABLE: 'NFC_COUNT_READ_LABLE'
}

export const TIMEOUT = {
  _2000: 2000,
  _1000: 1000
}

export const CELL = {
  STATUS: {
    FREE: 'FREE',
    OCCUPIED: 'OCCUPIED',
    UNKNOWN: 'UNKNOWN',
    REPEAT: 'REPEAT'
  },
  CONTENT: {
    NEW: 'new',
    TEMP_RENT: 'temporarily_rented',
    AVAILABLE: 'available',
    AT_WORK: 'at_work',
    NOT_WORKING: 'not_working',
    WIDTHDRAWN: 'withdrawn'
  },
  SIZE: {
    SMALL: 'SMALL',
    AVERAGE: 'AVERAGE',
    BIG: 'BIG'
  }
}

export const STATUS_CODES = {
  _404: 404,
  _500: 500
}

export const AUTJORIZATION_METHODS = {
  Открытая: 'Открытая',
  'WPA2-Personal': 'WPA2-Personal',
  'WPA2-Enterprise': 'WPA2-Enterprise'
}

export const IP_TYPE: IIpTypes = {
  STATIC: 'static',
  DYNAMIC: 'dynamic'
}

export const USER_ROLES = {
  engineer: 'engineer',
  workman: 'workman',
  serviceman: 'serviceman'
}

export const DEFAULT_LINKS = {
  page_surrender_of_equipment: '',
  surrender_of_equipment: '/surrender_of_equipment',
  cell_admin_page: '/administrirovanie-yacheek',
  otsutstvuyut_dostupnye_yachejki:
    '/otsutstvuyut-dostupnye-yachejki',
  otsutstvuet_dostupnoe_oborudovanie:
    '/otsutstvuet-dostupnoe-oborudovanie',
  page_user_equipment_not_connected:
    '/ne-privyazano-oborudovanie',
  add_new_wifi_connect: '/dobavlenie-novoj-seti-wi-fi',
  cell_list: '/spisochnaya-stranica-yacheek',
  condition_equipment: '/sostoyanie-oborudovaniya',
  connect_wifi: '/podklyuchenie-seti-wi-fi',
  describe_the_problem: '/opisanie-problemy',
  faulty_equipment: '/neispravnoe-oborudovanie',
  hand_over_equipment: '/sdacha-oborudovaniya',
  hand_over_in_cell:
    '/vybor-yachejki-dlya-sdachi-oborudovaniya',
  home: '/glavnaya-stranica',
  lan_settings: '/nastrojka-seti-lan',
  load_more_equipment: '/zagruzit-eshyo-oborudovanie',
  loadable_equipment: '/zagruzhaemoe-oborudovanie',
  main_menu_net: '/glavnoe-menyu-nastrojki-seti',
  net_settings: '/nastrojki-seti',
  open_cell: '/otkrytie-yachejki',
  problems: '/vybor-neispravnosti',
  seize_all_equipment: '/izyato-vsyo-oborudovanie',
  seize_equipment: '/izyat-oborudovanie',
  select_another_cell: '/vybrat-eshyo-odnu-yachejku',
  service_manager: '/stranica-servisnogo-menedzhera',
  settings_connected_wifi:
    '/nastrojka-podklyuchennoj-seti-wi-fi',
  settings_wifi: '/nastrojki-seti-wi-fi',
  types_of_equipment: '/tipy-oborudovaniya',
  error_server: '/stranica-oshibki-servera',
  not_found: '/stranica404',
  page_resupply_of_equipment:
    '/povtornaya-sdacha-oborudovaniya',
  verification: '/'
}

export const FILTERS_QS = {
  CELL_LIST: {
    GET_CONTEXT_EQUIP: 'GET_CONTEXT_EQUIP',
    GET_CONTEXT_TYPE_EQUIP: 'GET_CONTEXT_TYPE_EQUIP'
  }
}

export const WIFI_SIGNALS = {
  VERYLOW: 1,
  LOW: 2,
  MIDDLE: 3,
  HIGH: 4
}

export const MODAL_TYPES = {
  SET_CURD_NUMBER: 'SET_CURD_NUMBER',
  SET_NFC_NUMBER: 'SET_NFC_NUMBER',
  HISTORY_SCAN: 'HISTORY_SCAN',
  STORAGE_INFO: 'STORAGE_INFO',
  EMPTY: 'EMPTY'
}

export const THEME_MODAL = {
  DEFAULT: 'default',
  FULL: 'full'
}

export const DEFAULT_PAGES = {
  VERIFICATION: 'Verification',
  PROBLEMS: 'Problems',
  HAND_OVER_IN_CELL: 'HandOverInCell',
  ALL_CELLS_ARE_FULL_PAGE: 'AllCellsFilled',
  SELECT_ANOTHER_CELL_PAGE: 'SelectAnotherCellPage',
  HOME_PAGE: 'HomePage',
  MAIN_MENU_NET: 'MainMenuNet',
  ADD_NEW_WIFI_CONNECT: 'AddNewWiFiConnect',
  LOADEBLE_EQUIPMENT_PAGE: 'LoadableEquipmentPage',
  LOAD_MORE_EQUIPMENT_PAGE: 'LoadMoreEquipmentPage',
  SEIIZE_ALL_EQUIPMENT_PAGE: 'SeizeAllEquipmentPage',
  SEIZE_EQUIPMENT_PAGE: 'SeizeEquipmentPage',
  LAN_SETTINGS: 'LANSettings',
  CONNECTED_WIFI_SETTINGS: 'ConnectedWiFiSettings',
  NET_SETTINGS: 'NetSettings',
  SETTINGS_WIFI: 'SettingsWiFi',
  FAULTY_EQUIPMENT_PAGE: 'FaultyEquipmentPage',
  EQUIPMENT: 'Equipment',
  DESCRIBE_THE_PROBLEM: 'DescribeTheProblem',
  OPEN_CELL: 'OpenCell',
  CONNECT_WIFI: 'ConnectWiFi',
  HAND_OVER_EQUIPMENT: 'HandOverEquipment',
  CONDITION_EQUIPMENT: 'ConditionEquipment',
  EQUIPMENT_LIST_PAGE: 'EquipmentListPage',
  CELL_LIST_PAGE: 'CellListPage',
  PAGE: 'Page',
  SERVICE_MANAGER_PAGE: 'ServiceManagerPage',
  TYPES_OF_EQUIPMENT_PAGE: 'TypesOfEquipmentPage',
  LOCKER: 'Locker',
  CELL: 'Cell',
  SEIZE_FAULTY_TYPE: 'SeizeFaultyType',
  PAGE_403: 'Page403',
  404: '404',
  500: '500'
}

export const ERROR_MAIN_TEXT = {
  NO_AVALIABLE_EQUIPMENT:
    'Отсутствует доступное оборудование',
  NO_AVALIABLE_WORK_EQUIPMENT:
    'Отсутствует исправное оборудование',
  NO_AVALIABLE_UNWORK_EQUIPMENT:
    'Отсутствует неисправное оборудование',
  NO_FREE_CELL: 'Отсутствуют доступные ячейки',
  NO_USER:
    'Пользователь не обнаружен, обратитесь к администратору',
  NO_DESCRIPTION: 'Описание не может быть пустым!',
  ERROR_NFC_SCAN: 'Метка не обнаружена! Повторите действие'
}

export const TEXT = {
  SCAN_NFC_EQUIPMENT: 'Отсканируйте метку оборудования',
  SCAN_NFC_EQUIPMENT_AGAIN: 'Отсканируйте следующую метку'
}

// ? перечисление режимов работы который можно выбрать в админке
export const WORK_MODE = {
  MANUALLY: 'manually',
  RANDOM: 'random'
}

export const TIMEOUT_RECONECT = 1000

export const CONTENT_STATUSES = {
  TEMPORARY: 'temporarily_rented'
}
