import React, { FC, useEffect, useState } from 'react'
import { DynamicStaticButtons } from '@/views/dynamicStaticButtons'
import { TYPE_CONNECTION } from '@/components/lanConnection/const'
import { usePage, useStores } from '@/hooks'
import { Typography } from '@/views/typography'
import { FORM_NAMES } from '@/utils/formNames'
import LanConnectionForm from '@/components/lanConnectionForm/LanConnectionForm'
import { IObjectValuesLan } from '@/interfaces/ObjectPage'
import { notify } from '@/utils/notify'
import { getObjectErrorsFormik } from '@/utils'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'
import { FormikHelpers } from 'formik'

interface TSettingsValues {
  [TYPE_CONNECTION.DYNAMIC]: IObjectValuesLan | null
  [TYPE_CONNECTION.STATIC]: IObjectValuesLan | null
}

/**
 * Дефолтные значения формы
 */
export const defaultValuesLanForm: IObjectValuesLan = {
  [FORM_NAMES.IP]: '',
  [FORM_NAMES.MASK]: '',
  [FORM_NAMES.GATEWAY]: '',
  [FORM_NAMES.DNS]: ''
}

const LanConnection: FC = (): React.ReactElement => {
  const { api } = useStores()
  const page = usePage()
  const { ip, mask, gateway, dns, connection_type: connectionType } = page.init_state
  const [isLoading, setIsLoading] = useState(false)
  /**
   * Тип соединения
  */
  const [type, setType] = useState(connectionType ?? TYPE_CONNECTION.DYNAMIC)

  /**
   * получить поля для лана из контекста
   * если мы на форме статики и в контексте поля для статике то их
   * если мы на форме динамики и в контексте поля для динамики то их
   * если же разные то null
   */
  const getContexFields = (paramsType: TYPE_CONNECTION): IObjectValuesLan | null => {
    if (paramsType === type) {
      return {
        ip, mask, dns, gateway
      }
    }
    return null
  }

  /**
   * Начальные значения формы для динамики и статики
   */
  const [values, setValues] = useState<TSettingsValues>({
    [TYPE_CONNECTION.DYNAMIC]:
      getContexFields(TYPE_CONNECTION.DYNAMIC) ?? defaultValuesLanForm,
    [TYPE_CONNECTION.STATIC]:
      getContexFields(TYPE_CONNECTION.STATIC) ?? defaultValuesLanForm
  })

  /**
     * Ошибка получения данных для динамического соединения
     */
  const [errorGetDynamic, setErrorGetDynamic] =
      useState(false)

  /**
   * обработка ошибки при отправке запроса на подключение статика\динамика
   */
  const connectError = (
    { e, setErrors, setSubmitting }): void => {
    const errors = getObjectErrorsFormik(e)
    setErrors(errors)
    notify.error('Ошибка подключения')
    setSubmitting(false)
  }

  /**
   * отправка формы динамики на нужный ендпоинт
   */
  const submitDinamic = ({ setErrors, setSubmitting }): void => {
    api.network
      .connectDHCPWiFi()
      .then(() => {
        notify.success('Подключение успешно завершено') // по доке редиректа никакого нет, выводим успех
      })
      .then(() => {
        getInformation()
      })
      .catch((e) => {
        connectError({ e, setErrors, setSubmitting })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  /**
   * отправка формы статики на нужный ендпоинт
   */
  const submitStatic = ({ val, formikValues, setErrors, setSubmitting }): void => {
    api.network
      .connectStaticLan(val)
      .then(() => {
        notify.success('Подключение успешно завершено') // по доке редиректа никакого нет, выводим успех
        // сохраняем значения для случая, чтобы при переключении таб данные всегда были актуальными
        setValues({
          ...values,
          [TYPE_CONNECTION.STATIC]:
            formikValues ?? defaultValuesLanForm
        })
      })
      .catch((e) => {
        connectError({ e, setErrors, setSubmitting })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  /**
   * Отправка значений из формы на бэк
   */
  const onSubmit = (
    formikValues: IObjectValuesLan,
    { setErrors, setSubmitting }: FormikHelpers<IObjectValuesLan>
  ): void => {
    setIsLoading(true)
    const val = {
      ...formikValues,
      [FORM_NAMES.DNS]:
        formikValues[FORM_NAMES.DNS] !== ''
          ? formikValues[FORM_NAMES.DNS]
          : undefined
    }
    if (type === TYPE_CONNECTION.DYNAMIC) {
      submitDinamic({ setErrors, setSubmitting })
    } else {
      submitStatic({ val, formikValues, setErrors, setSubmitting })
    }
  }

  /**
   * Получения данных для динамического соединения
   */
  const getInformation = (): void => {
    api.network
      .getSettingDHCPWiFi()
      .then((res) => {
        setValues({
          ...values,
          [TYPE_CONNECTION.DYNAMIC]:
            res ?? defaultValuesLanForm
        }) // сохранения значений в форму
        setErrorGetDynamic(false)
      })
      .catch(() => setErrorGetDynamic(true))
  }

  const checkType = (type: TYPE_CONNECTION): void => {
    if (type === TYPE_CONNECTION.STATIC) {
      getInformation()
    }
  }

  /**
   * Изменение типа формы (динамика или статика)
   * и перезапись значений в форме
   */
  const onChangeType = (value: TYPE_CONNECTION): void => {
    checkType(value)
    setType(value)
  }

  /**
   * начальное получение данных для формы при динамическом типе
   */
  useEffect(() => {
    if (type === TYPE_CONNECTION.DYNAMIC) {
      getInformation()
    }
  }, [])

  const isStatic = type === TYPE_CONNECTION.STATIC

  /**
   * вывод ошибки только при динамическом соединении
   */
  const visibleError =
    errorGetDynamic && type === TYPE_CONNECTION.DYNAMIC

  return (
    <CSSTransitionGroup className={styles.container}>
      <DynamicStaticButtons
        values={type}
        onChange={onChangeType}
      />
      {visibleError
        ? (
          <Typography size={50}>
            Ошибка получения данных для динамического
            подключения.
          </Typography>
          )
        : (
          <LanConnectionForm
            isLoading={isLoading}
            values={values[type]}
            onSubmit={onSubmit}
            isStatic={isStatic}
          />
          )}
    </CSSTransitionGroup>
  )
}

export default LanConnection
