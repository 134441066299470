import { useEffect, useState, useMemo } from 'react'
import { useFormik, FormikHelpers } from 'formik'
import {
  cleanObjFromEmptyValues,
  getInfoValuesSettingsCurrentWifi,
  getInitValuesSettingsCurrentWifi,
  getObjectErrorsFormik
} from '@/utils'
import { FORM_NAMES } from '@/utils/formNames'
import { settingCurrentWifiFormSchem } from '@/utils/validation'
import { notify } from '@/utils/notify'
import { useStores } from './useStores'
import { usePage } from '@/hooks'
import { ICurrentWifi } from '@/services/store/CurrentWifi'
import {
  IHookSettingWifiReturn,
  IFormSettingWifiValues,
  IHookSettingWifi,
  IFormSettingWifiErrors,
  TSelectProtocol
} from '@/interfaces/Hooks'
import {
  AUTJORIZATION_METHODS,
  EMPTY_CONSTS,
  IP_TYPE,
  SIMPLE_STATUSES
} from '../const'
import {
  ISettingsConnectedWifi
} from '@/api/interfaces/Network'
import { IBlurInputEvent, IFocusInputEvent } from '@/interfaces/Common'

/**
 *? Хук для использования SettingsWifi Формы
 */
const useSettingsCurentWifiForm = ({
  afterSuccess = EMPTY_CONSTS.FUNC,
  afterError = EMPTY_CONSTS.FUNC
}: IHookSettingWifi): IHookSettingWifiReturn => {
  const { api } = useStores()
  const page = usePage()
  const { currentWifiStore: { set: setCureenttWifi, currentWifi } } = useStores()

  const [isLoading, setIsLoading] = useState<boolean>(EMPTY_CONSTS.FALSE)
  const [isKeyBoard, setIsKeybard] = useState(EMPTY_CONSTS.FALSE)
  const [keyboardValue, setKeyboardValue] = useState<string | null>(null)
  const [keyboardFild, setKeyboardField] = useState(EMPTY_CONSTS.STR)
  const [isDinamicIP, setIsDinamicIP] = useState(
    page.init_state?.info_connected_wifi?.connection_type === IP_TYPE.DYNAMIC
      ? EMPTY_CONSTS.TRUE
      : currentWifi?.connection_type === IP_TYPE.DYNAMIC
        ? EMPTY_CONSTS.TRUE
        : EMPTY_CONSTS.FALSE
  ) // IP Вариантt Статический/Динамический

  const [protocols, setProtocols] = useState<TSelectProtocol[]>([])
  const [currentProtocol, setProtocol] = useState<TSelectProtocol>()

  const infoValues = useMemo(() => {
    return getInfoValuesSettingsCurrentWifi(
      page.init_state?.info_connected_wifi,
      currentWifi
    )
  }, [])

  /**
     * обработчик при успешном подключении показать уведомление
     */
  const onSuccess = (formValues: IFormSettingWifiValues): void => {
    afterSuccess(formValues)
    void notify.success('Успешное подключение', {
      position: 'top-right'
    })
  }

  /**
     * обработчик при неуспешном подключении показать уведомление
     */
  const onError = (formValues: IFormSettingWifiErrors): void => {
    afterError(formValues)
    void notify.error('Ошибка подключения', {
      position: 'top-right'
    })
  }

  /**
     * закрыт клавиатуру
     */
  const closeKeyboard = (): void => {
    setIsKeybard(false)
  }

  /**
   * @info Обработчик отправки формы
   * фильтрация значений формы на пустые поля
   * установка Loading
   * обработка ответа
   */
  const onSubmit = (
    values: IFormSettingWifiValues,
    { setErrors }: FormikHelpers<IFormSettingWifiValues>
  ): void => {
    setIsLoading(EMPTY_CONSTS.TRUE)
    setIsKeybard(false)

    const filteredValue =
      cleanObjFromEmptyValues<ISettingsConnectedWifi>(values)

    api.network
      .changeSettingsConnectedWifi(filteredValue)
      .then((data) => {
        const newCurrentWifi = {
          ...filteredValue,
          ...infoValues,
          connection_type: isDinamicIP ? IP_TYPE.DYNAMIC : IP_TYPE.STATIC,
          status: SIMPLE_STATUSES.LOADING
        }
        setCureenttWifi(newCurrentWifi as ICurrentWifi)
        onSuccess(values)
      })
      .catch((e) => {
        if (e.response?.data?.error?.length > EMPTY_CONSTS.ZERO) {
          const errors = getObjectErrorsFormik(e)
          setErrors(errors)
        } else {
          onError(errors)
        }
      })
      .finally(() => {
        setIsLoading(EMPTY_CONSTS.FALSE)
      })
  }

  /**
     * Обработчик события Focus на input
     * открытие клавиатуры
     */
  const onFocusInput = (
    event: IFocusInputEvent
  ): void => {
    setKeyboardValue(event.target.value)
    setKeyboardField(event.target.name)
    setIsKeybard(EMPTY_CONSTS.TRUE)
  }

  /**
     * Обработчик события Blur на input
     */
  const onBlurInput = (
    event: IBlurInputEvent
  ): void => {
    // setIsKeybard(false)
  }

  /**
     * Обработчик События OnInput с Виртуальной Клавиатуры
     */
  const onKeyboardInput = (input: string): void => {
    void handleInput(input)
  }

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    setErrors,
    values,
    errors
    // ...formikData
  } = useFormik<IFormSettingWifiValues>({
    initialValues: getInitValuesSettingsCurrentWifi(
      page.init_state.info_connected_wifi,
      currentWifi
    ),
    onSubmit: onSubmit,
    validationSchema: () =>
      settingCurrentWifiFormSchem(isDinamicIP, isEnterprise),
    validateOnMount: EMPTY_CONSTS.FALSE,
    validateOnChange: EMPTY_CONSTS.FALSE,
    validateOnBlur: EMPTY_CONSTS.TRUE
  })

  /**
     * Обработчик Смены Значения типа IP при нажатии на табы
     * Статический \ Динамический
     */
  const changeIpType = (value: boolean): void => {
    setIsDinamicIP(value)
  }

  /**
     * Изменение здачения инпута в форме
     * Изменяется поле записанное в keyboardFild
     */
  const handleInput = (value: string): void => {
    setKeyboardValue(value)
    void setFieldValue(keyboardFild, value)
  }

  /**
  * @info Handler change Protocol SELECT
  */
  const handleChangeProtocol = (elem: TSelectProtocol): void => {
    setProtocol(elem)
  }

  /**
     * Обработчик смены Свитчера и изменение значения в форме
     */
  const handleSwitch = (): void => {
    void setFieldValue(
      FORM_NAMES.AUTOCONNECT,
      !values.autoconnect
    )
  }

  /**
     * Очистка полей Статического IP адреса
     * и сброс до первоначальныз значений
     */
  const clearField = (): void => {
    // const initialValues = getInitValues()
    void setValues({
      ...values,
      [FORM_NAMES.IP]: '',
      [FORM_NAMES.MASK]: '',
      [FORM_NAMES.GATEWAY]: '',
      [FORM_NAMES.DNS]: ''
    })
  }

  /**
   * @info Очистка полей Статического IP адреса
   * и сброс до первоначальныз значений
   */
  const fillContextValues = (): void => {
    const initialValues = getInitValuesSettingsCurrentWifi(
      page.init_state.info_connected_wifi,
      currentWifi
    )
    void setValues({
      ...values,
      [FORM_NAMES.IP]: initialValues[FORM_NAMES.IP],
      [FORM_NAMES.MASK]: initialValues[FORM_NAMES.MASK],
      [FORM_NAMES.GATEWAY]: initialValues[FORM_NAMES.GATEWAY],
      [FORM_NAMES.DNS]: initialValues[FORM_NAMES.DNS]
    })
  }

  /**
   * @info
   * If wifi security is 'Enterprise' === not domain & login & protocol
   */
  const isEnterprise =
page.init_state.info_connected_wifi?.[FORM_NAMES.SECURITY] ===
  AUTJORIZATION_METHODS['WPA2-Enterprise'] ||
currentWifi?.[FORM_NAMES.SECURITY] ===
  AUTJORIZATION_METHODS['WPA2-Enterprise']

  /**
* @info Текущий тип IP по которому подключены
* либо берется из контекста если мы подклчены успешно
* либо из стора если подключенны с ошибкой
*/
  const currentTypeIp = page.init_state?.info_connected_wifi?.connection_type !== undefined
    ? page.init_state?.info_connected_wifi?.connection_type
    : currentWifi?.connection_type

  /**
* @info Reaction on change Protocol SELECT
*/
  useEffect(() => {
    if (currentProtocol != null) {
      void setFieldValue(FORM_NAMES.PROTOCOL_CHOICES, currentProtocol.value)
    }
  }, [currentProtocol])

  /**
* @info Реакция на изменение Типа IP Статический \ Динамический
* сброс значений статического поля до начальных значений
*/
  useEffect(() => {
    const updateFields = (): void => {
      setErrors({})
      if (currentTypeIp === IP_TYPE.STATIC && isDinamicIP) {
        clearField()
      } else if (currentTypeIp === IP_TYPE.STATIC && !isDinamicIP) {
        fillContextValues()
      } else if (currentTypeIp === IP_TYPE.DYNAMIC && isDinamicIP) {
        fillContextValues()
      }
    }
    updateFields()
  }, [isDinamicIP])

  /**
* @info Если текущ подключение Enterprise
* то при Mount подгрузить список протоколов
* найти в нем текущий протокол и установить в setProtocol()
*/
  useEffect(() => {
    const fetchProtocols = (): void => {
      void api.network.getAllProticols().then((data) => {
        const init = getInitValuesSettingsCurrentWifi(
          page.init_state.info_connected_wifi,
          currentWifi
        )
        setProtocol(
          data.find((el) => el.value === init.protocol_choices)
        )
        setProtocols(data)
      })
    }
    isEnterprise && fetchProtocols()
  }, [])

  return {
    values,
    infoValues,
    errors,
    isDinamicIP,
    isKeyBoard,
    keyboardValue,
    isLoading,
    isEnterprise,
    protocols,
    currentProtocol,
    handleChangeProtocol,
    changeIpType,
    handleSubmit,
    handleChange,
    handleSwitch,
    onKeyboardInput,
    onFocusInput,
    closeKeyboard,
    onBlurInput
  }
}

export default useSettingsCurentWifiForm
