import React, { FC } from 'react'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import { Select } from '@/views/select'
import { EMPTY_CONSTS } from '@/const'
import styles from '../style/index.module.scss'
import classNames from 'classnames'

interface ISelectOption {
  value: number
  label: string
}

interface TProps {
  selectOptions: ISelectOption[]
  selectValue: ISelectOption
  onChange: (value: ISelectOption) => void
  onClick: () => void
}

export const ChooseActionCell: FC<TProps> = ({
  onChange,
  selectOptions,
  selectValue,
  onClick
}) => {
  return (
    <>
      <Typography
        size={60}
        weight={400}
        className={classNames(
          styles.container__title,
          styles['content_chose-action_title']
        )}
      >
        Выберите действие
      </Typography>
      <Select
        id='actions'
        name='actions'
        data-testid='actions'
        onChange={onChange}
        options={selectOptions}
        value={selectValue}
        isSearchable={EMPTY_CONSTS.FALSE}
      />
      <div className={
        classNames(
          styles.container__buttons,
          styles['content_chose-action_btn']
        )
      }
      >
        <Button onClick={onClick}>Выполнить</Button>
      </div>
    </>
  )
}
