import { makeAutoObservable } from 'mobx'
import { RootStore } from './Root'

enum messages {
  ws_wifi_success = 'ws_wifi_success',
  ws_wifi_error = 'ws_wifi_error',
  ws_login_card = 'ws_login_card',
  ws_login_error_card = 'ws_login_error_card',
  ws_logout = 'ws_logout',
  ws_nfc_success = 'ws_nfc_success',
  ws_nfc_error = 'ws_nfc_error',
  ws_close_cell = 'ws_close_cell',
  ws_redirect = 'ws_redirect',
}

type TMessages = keyof typeof messages

class SocketLocal {
  private readonly rootStore: RootStore
  message: TMessages | '' = ''

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  private setMessage (newmessage: TMessages | '' = ''): void {
    this.message = newmessage
  }

  setNewMessage = (newmessage: TMessages | '' = ''): void => {
    this.setMessage(newmessage)
    setTimeout(() => {
      this.setMessage('')
    }, 100)
  }
}

export default SocketLocal
