import React from 'react'
import { useLocation } from 'react-router-dom'
import { PageProvider, usePageContext } from '@garpix/cms'
import { PageModelType } from '@/api/interfaces/Page'
import {
  // useCurrentUser,
  // useCurrentUser,
  // useSiteConfig,
  useStores
} from '@/hooks'
import Modals from '@/views/Modal'

import CustomVh from '@/components/CustomVh'

// [PAGES]
import OptionsEquipmentPage from './optionsEquipment/OptionsEquipmentPage'
import ChoiceEquipment from './choiceEquipment/ChoiceEquipment'
import CurrentCell from './currentCell/CurrentCell'
import Problems from './problems/Problems'
import ReturnEquipment from './returnEquipment/ReturnEquipment'
import EquipmentStatus from './equipmentStatus/EquipmentStatus'
import HandOverInCell from './handOverInCell'
import Error from '@/pages/error/error'
import OptionsFaultyEquipment from './optionsFaultyEquipment'
import MenuEngineer from './engineer/menuEngineer'
import NetworkConfiguration from './engineer/networkConfiguration'
import NetworkConfigurationWiFi from './engineer/networkConfigurationWiFi'
import EquipmentStatusAdmin from './equipmentStatusAdmin/EquipmentStatusAdmin'
import OptionsFaultyEquipmentType from './optionsFaultyEquipmentType'
import SelectAnotherCell from './selectAnotherCell'
import SeizeAllEquipmentPage from './seizeAllEquipmentPage'
import LoadableEquipmentPage from './loadableEquipmentPage'
import AddConnection from './engineer/addConnection'
import AllCellsFilled from './AllCellsAreFullPage'
import SeizeFaultyType from './SeizeFaultyType'
import SeizeAllAvailable from './SeizeAllAvailable'
import SettingsCurrentWifi from './engineer/settingCurrentWiFi'
import NetworkConfigurationLan from '@/pages/engineer/networkConfigurationLan'
import ResupplyOfEquipment from '@/pages/ResupplyOfEquipment'
import UserEquipmentNotConnectedPage from '@/pages/UserEquipmentNotConnectedPage'
import NotAllowEquipment from './NotAllowEquipment'
import NoCellAvailable from './NoCellAvailable'
import EditCells from './EditCells'
import TemporaryDeliveryEquipmentPage from './TemporaryDeliveryEquipmentPage'

import Socket from '@/apps/socket'
import Notify from '@/components/notify/Notify' // сингловое соединение
import ErrorBoundary from '@/components/ErrorBoundary'
import { runtimeConfig } from '@/config'

const PAGE_TYPES: Partial<{
  [key in PageModelType]: React.FC
}> = {
  /** @info Главные страницы Srviceman \ Worker */
  Verification: OptionsEquipmentPage, // todo вроде не используется
  ServiceManagerPage: OptionsEquipmentPage, // Главная страница для Srviceman \ Worker
  SurrenderOfEquipment: TemporaryDeliveryEquipmentPage,

  /** @info Инженер */
  MainMenuNet: MenuEngineer, // Главное меню Инженера (НАСТРОЙКИ)
  NetSettings: NetworkConfiguration, // настройки сети WIFI/LAN
  CellAdminPage: EditCells, // Администрирование ячеек

  /** @info WIFI */
  SettingsWiFi: NetworkConfigurationWiFi, // список сетей WIFI \ настройки сети WIFI
  ConnectedWiFiSettings: SettingsCurrentWifi, // форма настройки подключеной сети WIFI
  AddNewWiFiConnect: AddConnection, // форма добавления новой сети
  /** @info LAN */
  LANSettings: NetworkConfigurationLan, // настройки сети LAN

  /** @info Проблеммы */
  Problems: Problems, // Список проблемм
  FaultyEquipmentPage: OptionsFaultyEquipment, // Выбать проблему

  /** @info Работа с оборудованием */
  HandOverInCell: HandOverInCell, //  Выберите ячейку
  CellListPage: HandOverInCell, //  Выберите ячейку
  Cell: CurrentCell, // Ячейка
  HandOverEquipment: ReturnEquipment, // Ввести метку
  SeizeEquipmentPage: EquipmentStatusAdmin, // Изъять оборудование
  EquipmentListPage: ChoiceEquipment, //  Выберите оборудование
  ConditionEquipment: EquipmentStatus, // Статус оборудования + кнопки Исправно\Неиспрвавно
  TypesOfEquipmentPage: OptionsFaultyEquipmentType, // тип оборудования перечисление

  /** @info  Pages */
  ResupplyOfEquipment, //  Оборудование числится сданным, продолжить?
  SelectAnotherCellPage: SelectAnotherCell, // Выбрать еще одну ячейку?
  LoadableEquipmentPage, // todo(мож используется, либо нижняя)  Загрузить еще оборудование?
  LoadMoreEquipmentPage: LoadableEquipmentPage, // todo(мож используется, либо верхняя) Загрузить еще оборудование?

  /** @info Сommunication with USER */
  SeizeAllEquipmentPage, // Все неисправное оборудование изъято
  AllCellsFilled, //  Все ячейки заполнены
  SeizeFaultyType, //  Всё неисправное оборудование изъято
  SeizeAllAvailable, //  Всё исправное оборудование изъято
  UserEquipmentNotConnectedPage, // Вам не присвоенно не одно оборудование
  NoHardwareAvailable: NotAllowEquipment, // Отсутсвует доступное оборудование
  NoCellAvailable, // Отсутсвует доступные ячейки

  /** @info Errors */
  Page403: Error,
  Page404: Problems,
  PageNotFound: Error,
  PageErrorServer: Error
}

const CleanCash = ({
  children
}): React.ReactElement | null => {
  const context = usePageContext()
  context.handleClearAllCaches()

  return children
}

const PageStarter = (): React.ReactElement | null => {
  const { page: data } = usePageContext()
  console.log('page', data)
  // const config = useSiteConfig()
  // const user = useCurrentUser()

  const pageType = data?.pageType
  const page = data?.page
  if (pageType === undefined) {
    return null
  }
  if (runtimeConfig.IS_DEV === 'true') {
    // console.log('pageType', pageType)
    // console.log('page', page)
  }

  // console.log('config', config)
  // console.log('data', data);
  // console.log('user', user)
  const Page = PAGE_TYPES[pageType]

  return <Page {...page} />
}

const Combine = ({ staticContext }): React.ReactElement => {
  const location = useLocation()
  const store = useStores()

  return (
    <PageProvider
      scrollRestoration='auto'
      isScrollTo={false}
      fetchData={store.api.page.getPage}
      pathname={location.pathname}
      queryParams={location.search}
      initData={staticContext?.initData}
    >
      <CleanCash>
        <Socket>
          <ErrorBoundary>
            <CustomVh />
            <Notify />
            <PageStarter />
          </ErrorBoundary>
        </Socket>
        <Modals />
      </CleanCash>
    </PageProvider>
  )
}

export default Combine
