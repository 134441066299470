import React from 'react'
import ErrorPipline from '@/components/ErrorPipline'
import MainLayout from '../mainLayout/MainLayout'

interface IState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<any, IState> {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch (): void {
    this.setState({ hasError: true })
  }

  render (): any {
    if (this.state.hasError) {
      return (
        <MainLayout visibleLinkBack={false}>
          <ErrorPipline
            hideBtn
            linkBack='/'
            errorMessage='Упс... Что-то пошло не так!'
          />
        </MainLayout>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
