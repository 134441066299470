import React, { useEffect, useRef, useState } from 'react'
import { Input } from '@/views/input'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from '../styles/index.module.scss'
import { useStores } from '@/hooks'
import { Spinner } from '@/views/spinner'
import { IFocusInputEvent } from '@/interfaces/Common'

const SetCurdNumber = ({
  closeModal,
  openKeyboard,
  closeKeyboard,
  setValueKeyboard,
  valueKeyboard
}): React.ReactElement => {
  const [isLoading, setisLoading] = useState(false)
  const [errorMessange, setErrorMessange] = useState<
  string | null
  >(null)
  const { api } = useStores()
  const inputRef: React.RefObject<HTMLInputElement> =
    useRef(null)

  const sendCurdKey = (): void => {
    setisLoading(true)
    void api.experemental
      .scanNFC({
        hid_number: valueKeyboard
      })
      .then(() => {
        setisLoading(false)
        closeModal()
      })
      .catch((err) => {
        console.log('err', err)
        setErrorMessange(err.response.data.message)
        setisLoading(false)
      })
  }

  const handleChangeInput = (e: any): void => {
    setValueKeyboard(e.target.value)
  }

  /**
     * Обработчик события Focus на input
     * открытие клавиатуры
     */
  const onFocusInput = (
    event: IFocusInputEvent
  ): void => {
    setValueKeyboard(event.target.value)
    openKeyboard()
  }

  useEffect(() => {
    // todo: починить фокус ( Толин компонент работает не стабильно )
    inputRef.current?.focus()
  }, [inputRef.current])

  useEffect(() => {
    openKeyboard()
    return () => {
      closeKeyboard()
    }
  }, [])

  return (
    <>
      <CSSTransitionGroup>
        <Typography
          className={styles.curd__typography__title}
          size={60}
        >
          Введите номер карты
        </Typography>
      </CSSTransitionGroup>
      <form>
        <Input
          ref={inputRef}
          onFocus={onFocusInput}
          value={valueKeyboard}
          onChange={handleChangeInput}
          outerStyles={styles.input}
          error={errorMessange !== null}
          errorMessage={errorMessange ?? ''}
          textCenter
          inputSize='large'
        />
      </form>
      <CSSTransitionGroup>
        <Button onClick={sendCurdKey}>
          {isLoading ? <Spinner /> : 'Продолжить'}
        </Button>
      </CSSTransitionGroup>
    </>
  )
}

export default SetCurdNumber
