import { IProblem } from '@/interfaces/Problem'

export interface IEquipment {
  serialNumber: string
  title: string
}

export interface IEquipmentCell {
  id: number
  is_active: boolean
  is_reserved: boolean
  is_blocked: boolean
  title: string
  serialNumber: string
  cellNumber: number
  url: string
  equipmentTitle: string
  equipmentDesc: string
  isEquipment: boolean
  error?: boolean
  isEmpty?: boolean
  cell_number: number
  cell_size: number
  cell_status: string
  content_name: string
  content_status: string
  type_of_hardware_content: string
  equipment: any
  reserved_for_equipment?: { // non serialized
    title_ru: string
    equipment_type: {
      title: string
    }
  }
  reservedTitle: string // serialized
  reservedDesc: string // serialized
}

export interface IQp {
  page: number
  cell_status?: string | null
  content_status?: string | null
  type_of_hardware_content?: null | string
  equipments__equipment_type__id?:
  | string
  | number
  | null
  | undefined
  reserved_for_equipment__isnull?: boolean
  is_active?: boolean
  is_blocked?: boolean
}

export interface IEquipmentList {
  total: number
  currentPage: number
  results: IEquipmentCell[]
}

export interface IEquipmentTypesList {
  total: number
  currentPage: number
  results: any[]
}

export interface IProblemList {
  count: number
  total: number
  currentPage: number
  results: IProblem[]
}

export enum STATUS_EQUIPMENT {
  AVAILABLE = 'available',
  AT_WORK = 'at_work',
  NOT_WORKING = 'not_working',
  UNDER_REPAIR = 'under_repair',
}

export interface IHistoryScan {
  card_number: string
  created_at: string
}
