import { usePage as usePageCms } from '@garpix/cms'
import { IUser } from '@/interfaces/ObjectPage'
import { USER_ROLES } from '@/const'

const useCurrentUser = (): IUser => {
  const value = usePageCms()
  const defaultUserData: IUser = {
    role: USER_ROLES.workman,
    available_equipment: false,
    available_working_equipment: false,
    available_not_working_equipment: false,
    user_related_equipment: false,
    user_equipment_temporarily_renter: false
  }
  const userData: IUser | undefined =
    value?.page?.init_state?.global?.user

  if (userData === undefined) {
    return defaultUserData
  }
  return userData
}

export default useCurrentUser
