import React from 'react'
import ReactDOM from 'react-dom'
import { GxModal } from '@garpix/garpix-web-components-react'
import style from './styles/index.module.scss'
import { THEME_MODAL } from '@/const'

interface BaseModalProps {
  handleClose?: () => void
  theme?: string
  children: React.ReactNode
  open: boolean | undefined
}

const BaseModal: React.FC<BaseModalProps> = ({
  handleClose,
  children,
  theme = THEME_MODAL.DEFAULT,
  ...props
}) => {
  return ReactDOM.createPortal(
    <GxModal {...props} className={style[theme]}>
      {children}
    </GxModal>,
    document.body
  )
}

export default BaseModal
