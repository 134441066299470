export const FORM_NAMES = {
  IP: 'ip',
  MASK: 'mask',
  GATEWAY: 'gateway',
  DNS: 'dns',
  SSID: 'ssid',
  BSSID: 'bssid',
  SECURITY: 'security',
  AUTOCONNECT: 'autoconnect',
  PASSWORD: 'password',
  SIGNAL_STRENGTH: 'signal_strength',
  DOMAIN: 'domain',
  LOGIN: 'login',
  PROTOCOL_CHOICES: 'protocol_choices'
}
