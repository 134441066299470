import React, { FC } from 'react'
import SettingsWifiDinamicStaticFields from '@/components/SettingSurrentWifi/SettingsWifiFormExtraField'
import { ISettingWifiForm } from '../interfaces'
import { Button } from '@/views/button'
import { Input } from '@/views/input'
import { Select } from '@/views/select'
import styles from '../styles/index.module.scss'

const SettingWifiForm: FC<ISettingWifiForm> = ({
  handleSubmit,
  isLoading,
  isEnterprise,
  protocols,
  currentProtocol,
  handleChangeProtocol,
  handleInput,
  values,
  handleFocusInput,
  handleBlurInput,
  errors,
  ...props
}) => {
  return (
    <form
      className={styles.form} onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      {isEnterprise && (
        <div className={styles.enterprise_wrap}>
          <Input
            id='password'
            name='password'
            data-testid='password'
            label='Пароль'
            type='password'
            onChange={handleInput}
            value={values.password}
            error={Boolean(errors.password)}
            errorMessage={errors.password}
            autoComplete='off'
            onFocus={handleFocusInput}
            onBlur={handleBlurInput}
          />
          <Input
            id='login'
            name='login'
            data-testid='login'
            value={values.login}
            error={Boolean(errors.login)}
            errorMessage={errors.login}
            label='Логин'
            onChange={handleInput}
            onFocus={handleFocusInput}
            onBlur={handleBlurInput}
            autoComplete='off'
          />
          <Input
            id='domain'
            name='domain'
            data-testid='domain'
            value={values.domain}
            error={Boolean(errors.domain)}
            errorMessage={errors.domain}
            label='Домен'
            onChange={handleInput}
            onFocus={handleFocusInput}
            onBlur={handleBlurInput}
            autoComplete='off'
          />
          <Select
            onChange={handleChangeProtocol}
            label='Протокол безопасности'
            value={currentProtocol}
            options={protocols}
            isError={Boolean(errors.protocol_choices)}
            errorMessage={errors.protocol_choices}
          />
        </div>
      )}
      <div className={styles.form__connection}>
        <SettingsWifiDinamicStaticFields
          handleInput={handleInput}
          values={values}
          handleFocusInput={handleFocusInput}
          handleBlurInput={handleBlurInput}
          errors={errors}
          {...props}
        />
      </div>
      <Button type='submit' load={isLoading}>
        Подключиться
      </Button>
    </form>
  )
}

export default SettingWifiForm
