import { useEffect } from 'react'

const CustomVh = (): null => {
  useEffect(() => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    const handlerResizable = (): void => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    window.addEventListener('resize', handlerResizable)
    return () => {
      window.removeEventListener('resize', handlerResizable)
    }
  }, [])

  return null
}

export default CustomVh
