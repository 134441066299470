import React from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import { useSiteConfig } from '@/hooks'
import SettingCurrentWifi from '@/components/SettingSurrentWifi'
import { DEFAULT_LINKS } from '@/const'

const SettingsCurrentWifi: React.FC =
  (): React.ReactElement => {
    const config = useSiteConfig()

    return (
      <MainLayout linkBack={config?.links?.settings_wifi ?? DEFAULT_LINKS.settings_wifi}>
        <SettingCurrentWifi />
      </MainLayout>
    )
  }

export default SettingsCurrentWifi
