import React, { useEffect, useRef } from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { Typography } from '@/views/typography'
import classNames from 'classnames'
import {
  ArrowHeadDownIcon,
  ArrowHeadSmallDownIcon,
  CardIcon,
  LogoIcon,
  PanelMain
} from '@/assets/icon'
import { useSiteConfig, useStores } from '@/hooks'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import { Button } from '@/views/button'
import { MODAL_TYPES } from '@/const'
import { runtimeConfig } from '@/config'
import styles from './style/index.module.scss'
// import GlobalEvents from '@/apps/GlobalEvents'

interface TProp {
  isError: boolean
}
const minutes = 5

const LoginPage: React.FC<TProp> = ({
  isError
}): React.ReactElement => {
  const config = useSiteConfig()
  const { modalStore } = useStores()

  const reloadTime = minutes * 60 * 1000

  const timeoutId = useRef<NodeJS.Timeout>()

  const resetTimeout = (): void => {
    if (timeoutId.current !== undefined) {
      clearTimeout(timeoutId.current)
    }
  }

  const startTimeout = (): void => {    
    timeoutId.current = setTimeout(() => {
      window.location.reload()
    }, reloadTime)
  }

  const resetTimeoutOnActivityWait = (): void => {
    resetTimeout()
    startTimeout()
  }

  useEffect(() => {
    /**
     * @description
     * запускаем таймер даже если не было никаких действий от пользователя
     */
    startTimeout()

    window.addEventListener(
      'mousemove',
      resetTimeoutOnActivityWait
    )
    window.addEventListener(
      'keydown',
      resetTimeoutOnActivityWait
    )
    console.log('MOUNT')
    return () => {
      console.log('UN MOUNT')
      resetTimeout() // Очищаем таймер перед размонтированием
      window.removeEventListener(
        'mousemove',
        resetTimeoutOnActivityWait
      )
      window.removeEventListener(
        'keydown',
        resetTimeoutOnActivityWait
      )
    }
  }, [])

  const handleCLickSetCardNumber = (): void => {
    modalStore.open(MODAL_TYPES.SET_CURD_NUMBER)
  }

  const handleClickCheckHistory = (): void => {
    modalStore.open(MODAL_TYPES.HISTORY_SCAN)
  }

  const handleClickReloadPage = (): void => {
    window.location.reload()
  }

  return (
    <div className={styles.container}>
      <button
        className={styles.hiddenbtn_left}
        onClick={handleClickReloadPage}
      />
      <button
        className={styles.hiddenbtn_right}
        onClick={handleClickCheckHistory}
      />
      <CSSTransitionGroup animation='fadeInTop'>
        <img
          src={config?.logo?.home_logo_url ?? LogoIcon}
          className={styles.container__logo}
          alt='логотип'
        />
      </CSSTransitionGroup>
      {runtimeConfig.IS_DEV === 'true'
        ? (
          <CSSTransitionGroup animation='fadeInTop'>
            <Button
              onClick={handleCLickSetCardNumber}
              className={styles.enter__card__btn}
            >
              Ввести номер карты
            </Button>
          </CSSTransitionGroup>
          )
        : null}
      <CSSTransitionGroup
        animation='fadeInBottom'
        className={styles.container_block}
      >
        <GxIcon
          src={PanelMain}
          className={styles.container_panel}
        />

        <div className={styles.container__content}>
          <Typography
            size={80}
            color={isError ? 'red' : undefined}
            className={classNames(styles.container__title, {
              [styles.container__title_error]: isError
            })}
          >
            {!isError
              ? 'Приложите карту'
              : 'Неверная карта! Повторите действие'}
          </Typography>
          <GxIcon
            src={CardIcon}
            className={classNames(styles.container__card, {
              [styles.container__card_error]: isError
            })}
          />
        </div>
        <div className={styles.container__footer}>
          <GxIcon
            src={ArrowHeadSmallDownIcon}
            className={classNames(
              styles.container__arrow_secondary,
              {
                [styles.container__arrow_error]: isError
              }
            )}
          />
          <GxIcon
            src={ArrowHeadDownIcon}
            className={classNames(
              styles.container__arrow_main,
              {
                [styles.container__arrow_error]: isError
              }
            )}
          />
        </div>
      </CSSTransitionGroup>
    </div>
  )
}

export default LoginPage
