import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'

import MainLayout from '@/components/mainLayout/MainLayout'
import TemporaryDeliveryEquipmentViews from '@/views/TemporaryDeliveryEquipmentViews'
import SpinerWithMainLayout from '@/views/spinner/SpinerWithMainLayout'
import InfoContent from '@/views/infoContent'

import { useSiteConfig, useStores } from '@/hooks'
import useContextStorage from '@/hooks/useContextStorage'

import { IErrorMessage } from '@/interfaces/Common'

import {
  CELL,
  DEFAULT_LINKS,
  ERROR_MAIN_TEXT,
  WORK_MODE
} from '@/const'
import { objToQs } from '@/utils'

/**
 * @component
 * * страница выбора выбора сдачи оборудования временно/конец смены
 * ! только для роли рабочий
 */
const TemporaryDeliveryEquipmentPage =
  (): React.ReactElement => {
    const store = useStores()
    const config = useSiteConfig()
    const navigate = useNavigate()
    const [isLoadTempDelvEquip, setisLoadTempDelvEquip] =
      useState(false)
    const [isLoadEndDelvEquip, setisLoadEndDelvEquip] =
      useState(false)
    const storage = useContextStorage()
    const [isSpinnerShow, setIsSpinnerShow] = useState(true)

    const [errorMessage, setErrorMessage] =
      useState<null | IErrorMessage>(null)

    let timeoutTrotl: NodeJS.Timeout

    const clearErrorMessage = (): void => {
      setErrorMessage(null)
    }

    const linkTop = {
      cell_status: CELL.STATUS.FREE
    }

    const createErrorMessage = (
      message: string,
      description: string,
      btnHandler: () => void
    ): void => {
      setErrorMessage({
        message,
        description,
        btnHandler
      })
    }

    const handleClickTopButton = (): void => {
      setisLoadTempDelvEquip(true)
      store.global.set<boolean>(
        'IS_TEMP_DELIVE_EQUIP',
        true
      )
      if (WORK_MODE.MANUALLY === config.work_mode) {
        navigate(
          `${
            config?.links?.hand_over_in_cell ??
            DEFAULT_LINKS.hand_over_in_cell
          }${objToQs(linkTop)}`
        )
      }
      store.api.equipment
        .setTempSurender({})
        .finally(() => {
          timeoutTrotl = setTimeout(() => {
            setisLoadTempDelvEquip(false)
          }, 1000)
        })
    }

    const handleClickBottonButton = (): void => {
      setisLoadEndDelvEquip(true)
      if (!storage.free_cell) {
        return createErrorMessage(
          ERROR_MAIN_TEXT.NO_FREE_CELL,
          '',
          clearErrorMessage
        )
      }
      store.global.set<boolean>(
        'IS_END_WORK_DELIVE_EQUIP',
        true
      )
      navigate(config?.links?.condition_equipment)
    }
    /**
     * @description
     * * проверка на авторизованного юзера
     */
    useEffect(() => {
      if (store.global.userRole === undefined) {
        createErrorMessage(
          ERROR_MAIN_TEXT.NO_USER,
          '',
          clearErrorMessage
        )
      }
      setIsSpinnerShow(false)
    }, [store.global.userRole])

    useEffect(() => {
      // store.global.reset()

      return () => {
        clearTimeout(timeoutTrotl)
      }
    }, [])

    return (
      <MainLayout visibleLinkBack={false}>
        {isSpinnerShow
          ? (
            <SpinerWithMainLayout />
            )
          : errorMessage !== null
            ? (
              <InfoContent
                message={errorMessage.message}
                handlerClickBtn={errorMessage.btnHandler}
                description={errorMessage.description}
              />
              )
            : (
              <TemporaryDeliveryEquipmentViews
                isLoadEndDelvEquip={isLoadEndDelvEquip}
                isLoadTempDelvEquip={isLoadTempDelvEquip}
                handleClickTopButton={handleClickTopButton}
                handleClickBottonButton={
              handleClickBottonButton
            }
              />
              )}
      </MainLayout>
    )
  }

export default observer(TemporaryDeliveryEquipmentPage)
