import { NoSSR } from '@garpix/cms'
import React, { FC } from 'react'
import { ToastContainer } from 'react-toastify'
import './style/index.scss'

const Notify: FC = () => (
  <NoSSR>
    <ToastContainer position='bottom-right' />
  </NoSSR>
)

export default Notify
