import React, { FC } from 'react'
import { Typography } from '@/views/typography'
import { NavLink } from 'react-router-dom'
import { useSiteConfig } from '@/hooks'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'

interface TOptionEquipmentAdmin {
  title: string
  isDefaultUse?: boolean
  option: Array<{
    title: string
    value: string
    id: number
  }>
}
const OptionEquipmentAdmin: FC<TOptionEquipmentAdmin> = ({
  title,
  isDefaultUse = true,
  option = []
}) => {
  const config = useSiteConfig()
  return (
    <div className={styles.container}>
      <CSSTransitionGroup
        component={Typography}
        animation='fadeInTop'
        size={60}
        weight={700}
        className={styles.container__title}
      >
        {title}
      </CSSTransitionGroup>
      <CSSTransitionGroup
        className={styles.container__list}
      >
        {option.map(({ value = '#', title, id }) => {
          const to = isDefaultUse
            ? `${config.links.hand_over_in_cell}${value}`
            : value
          return (
            <NavLink
              key={id}
              to={to}
              className={styles.container__item}
            >
              <Typography
                size={40}
                weight={500}
                className={styles.container__item_title}
              >
                {title}
              </Typography>
            </NavLink>
          )
        })}
      </CSSTransitionGroup>
    </div>
  )
}

export default OptionEquipmentAdmin
