export const runtimeConfig =
  typeof window === 'undefined'
    ? {
        // server
        API_URL: process?.env?.RAZZLE_API_URL ?? '',
        API_WS_URL: process?.env?.RAZZLE_API_WS_URL ?? '',
        IS_DEV: process?.env?.RAZZLE_IS_DEV ?? '',
      }
    : {
        // client
        API_URL:
          globalThis?.env?.API_URL !== undefined
            ? globalThis?.env?.API_URL
            : '',
        API_WS_URL:
          globalThis?.env?.API_WS_URL !== undefined
            ? globalThis?.env?.API_WS_URL
            : '',
        IS_DEV:
          globalThis?.env?.IS_DEV !== undefined
            ? globalThis?.env?.IS_DEV
            : '',
      };
