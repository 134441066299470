import React, { FC, useState } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import CardInfo from '@/components/cardInfo/CardInfo'
import ProblemsComponent from '@/components/problemsComponent/ProblemsComponent'
import { useCurrentDevice, useSiteConfig } from '@/hooks'
import { DEFAULT_LINKS } from '@/const'

const Problems: FC = () => {
  const [isShowCardInfo, setisShowCardInfo] =
    useState(true)
  const device = useCurrentDevice()
  const config = useSiteConfig()

  return (
    <MainLayout linkBack={config?.links?.condition_equipment ?? DEFAULT_LINKS.condition_equipment}>
      <>
        {isShowCardInfo
          ? (
            <CardInfo
              equipmentType={device?.equipment_type.title}
              name={device?.title_ru}
            />
            )
          : null}

        <ProblemsComponent
          device={device}
          setisShowCardInfo={setisShowCardInfo}
        />
      </>
    </MainLayout>
  )
}

export default Problems
