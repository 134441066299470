import AbsractApi from '@/api/abstract'
import { ICellApi, TQPOpenCell } from '@/api/interfaces'
import { ICell, ITime } from '@/api/interfaces/Cell'
import { QueryParams } from '@garpix/base-api'
import { timerSerializer } from './serializers'

class CellApi implements ICellApi {
  ctx: AbsractApi
  constructor (ctx: AbsractApi) {
    this.ctx = ctx
  }

  enableCell= async ({
    id
  }: TQPOpenCell): Promise<ICell> => {
    const res = await this.ctx.patch<ICell>(
      `/cell/enable/${id}/`,
      {}
    )
    return res.data
  }

  disableCell= async ({
    id
  }: TQPOpenCell): Promise<ICell> => {
    const res = await this.ctx.patch<ICell>(
      `/cell/disable/${id}/`,
      {}
    )
    return res.data
  }

  /**
   * @function openCellForChange
   * only for workmen and serviceman
   */
  openCellForChange = async ({
    id
  }: TQPOpenCell): Promise<ICell> => {
    const res = await this.ctx.post<ICell>(
      `/cell/open_cell_for_change/${id}/`,
      {}
    )
    return res.data
  }

  /**
   * @function openCellWithoutChangeStatus
   * only for enginer
   */
  openCellWithoutChangeStatus = async ({
    id
  }: {id: number}): Promise<void> => {
    await this.ctx.post(
      `/cell/open_cell_without_changing_status/${id}/`,
      {}
    )
  }

  getTime = async (cellNumber: number): Promise<ITime> => {
    const res = await this.ctx.get<ITime>(
      `/cell/check_timer/${cellNumber}/`
    )
    return timerSerializer(res.data)
  }

  openRandomCell = async (): Promise<ICell> => {
    const res = await this.ctx.post<ICell>(
      '/cell/open_random_cell/',
      {}
    )
    return res.data
  }

  openCell = async ({
    id
  }: TQPOpenCell): Promise<ICell> => {
    const res = await this.ctx.post<ICell>(
      `/cell/open_cell/${id}/`,
      {}
    )
    return res.data
  }

  getAllStatusesCells = async (
    params?: QueryParams<ICell[]> | undefined
  ): Promise<ICell[]> => {
    const res = await this.ctx.get<ICell[]>(
      '/cell/cell_status/',
      params
    )
    return res.data
  }
}

export default CellApi
