import React, { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import styles from './style/index.module.scss'

const arrayToMap = new Array(10).fill(0).map((e, i) => i + 1)

export const SkeletonWifiList: FC = ({ ...props }) => {
  return (
    <div
      className={styles.container__list}
    >
      {arrayToMap.map((key) => {
        return (
          <Skeleton
            key={key}
            className={styles.skeleton__list_item}
            height='200px' width='100%'
            baseColor='#ffffff'
            highlightColor='#e5e5e5'
            {...props}
          />
        )
      })}
    </div>
  )
}
