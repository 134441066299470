/**
 * Import all your stores
 */
import { Api } from '@/api'
import { ModalStore } from './Modal'
import { GlobalStore } from './Global'
import { SpinnerStore } from './Spinner'
import { TLanguage } from '@/interfaces/Lang'
import { CurrentWifiStore } from './CurrentWifi'
import SocketLocal from './SocketLocal'

/**
 * Root Store Class with
 */
export class RootStore {
  modalStore: ModalStore
  currentWifiStore: CurrentWifiStore
  api: Api
  global: GlobalStore
  spinerStore: SpinnerStore
  socketLocal: SocketLocal

  constructor (url: string, lng: TLanguage) {
    this.api = new Api(url, lng)
    this.modalStore = new ModalStore(this)
    this.spinerStore = new SpinnerStore(this)
    this.global = new GlobalStore(this)
    this.currentWifiStore = new CurrentWifiStore(this)
    this.socketLocal = new SocketLocal(this)
    // [INIT]
    this.global.initColorSheme()
  }
}
