import React, { memo, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import MainLayout from '@/components/mainLayout/MainLayout'
import { Typography } from '@/views/typography'
import CardEquipment from '@/components/choiceEquipment/cardEquipment/CardEquipment'
import Paginator from '@/components/paginator/Paginator'
import {
  useCurrentUser,
  useSiteConfig,
  useStores
} from '@/hooks'
import { GxSpinner } from '@garpix/garpix-web-components-react'
import { CELL, DEFAULT_LINKS, USER_ROLES } from '@/const'
import InfoMessagePage from '@/views/infoMessagePage/InfoMessagePage'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'
import { IEquipmentCell } from '@/api/interfaces/Equipment'
import { log } from '@/utils'

interface IData {
  total: number
  currentPage: number
  results: IEquipmentCell[]
}

const errorMessange = {
  [CELL.STATUS.FREE]: 'Отсутствуют доступные ячейки',
  [CELL.STATUS.OCCUPIED]: 'Отсутствуют доступные ячейки',
  [CELL.STATUS.UNKNOWN]: 'Отсутствуют доступные ячейки'
}

interface ICellList {
  cells: IEquipmentCell[] | null
  total: number
  pageSize: number
  currentPage: number
  changeNumber: (pageNum: number) => void
}

const CellsList: React.FC<ICellList> = memo(
  ({ cells, changeNumber, ...props }) => {
    return (
      <CSSTransitionGroup
        className={styles.container__content}
      >
        <div className={styles.container__main}>
          {cells != null
            ? cells.map((elem) => {
              return (
                <CardEquipment key={elem.id} {...elem} />
              )
            })
            : null}
        </div>
        <Paginator changePage={changeNumber} {...props} />
      </CSSTransitionGroup>
    )
  }
)

const HandOverInCell: React.FC = (): React.ReactElement => {
  const store = useStores()
  const config = useSiteConfig()
  const user = useCurrentUser()

  /** @info Индикатор идет ли загрузка */
  const [isLoading, setIsLoading] = useState<
  boolean | null
  >(null)
  /** @info Индикатор идет ли первая загрузка после MOUNT */
  const [isFirstLoading, setIsFirstLoading] = useState<
  boolean | null
  >(null)
  /** @info Список ячеек (data.results) */
  const [cellList, setCellList] = useState<
  IEquipmentCell[] | null
  >(null)
  /** @info Все данные с запроса на список ячеек */
  const [data, setData] = useState<IData | null>(null)
  /** @info Опции для запроса ячеек */
  const [options, setOptions] = useState({
    currentPage: 1,
    pageSize: 20,
    total: 0
  }) // параметры для пагинации

  const queryParams = new URLSearchParams(
    window.location.search
  )

  /** @info Обработчик перед запросом ставит лоадеры в true */
  const beforeRequest = (): void => {
    if (isFirstLoading === null) {
      setIsFirstLoading(true)
    }
    setIsLoading(true)
  }

  /** @info Обработчик успешного запроса учтановка результата в data */
  const afterSuccess = (data: IData): void => {
    setData(data)
  }

  /** @info Обработчик Ошибки Яркое сообщение в консоли */
  const afterError = (): void => {
    log('ERROR LOAD CELL LIST', 'error')
  }

  /** @info Обработчик в конце запроса установка индикаторов загрузки в false */
  const finallyRequest = (): void => {
    if (isFirstLoading === true) {
      setIsLoading(false)
    }
    setIsFirstLoading(false)
  }

  /** @info Запрос и обработка списка ячеек
   * если в queryParams небыло ничего переданно то ниче не грузим для отсечения загруки с initParams (весь список ячеек)
   */
  const fetchCellList = (): void => {
    beforeRequest()
    const params = {
      page: options.currentPage,
      cell_status: queryParams.get('cell_status'),
      content_status: queryParams.get('content_status'),
      type_of_hardware_content: queryParams.get(
        'type_of_hardware_content'
      ),
      equipments__equipment_type__id: queryParams.get(
        'equipments__equipment_type__id'
      ),
      reserved_for_equipment__isnull: true,
      is_active: true,
      is_blocked: false
    }
    store.api.equipment
      .getEquipmentList(params)
      .then((data) => {
        afterSuccess(data)
      })
      .catch(() => {
        afterError()
      })
  }

  const decorAllowFetch = (): void => {
    throttledFetchList()
  }

  const throttledFetchList = fetchCellList // useThrottle(fetchCellList, 500)

  /**
   * функция для изменения страниц
   */
  const changeNumber = (pageNum: number): void => {
    setOptions({
      ...options,
      currentPage: pageNum
    })
  }

  /**
   * сохраняем общее кол-во элементов, которое пришло с бэка в стейт
   */
  useEffect(() => {
    setOptions({
      ...options,
      total: data?.total ?? 0
    })
  }, [data?.total])

  /** @info Вытаскивание queryParams и установка в стейт qp */
  useEffect(() => {
    decorAllowFetch()
  }, [])

  /** @info При изменении qp обновить данные */
  useEffect(() => {
    decorAllowFetch()
  }, [options])

  /** @info Рендер идет по cellList -> если они обновились значит точно все работа над запросом закончена */
  useEffect(() => {
    if (isLoading === true) {
      finallyRequest()
    }
  }, [cellList])

  /** @info Установка списка ячеек из всего запроса */
  useEffect(() => {
    if (data !== null && data !== undefined) {
      setCellList(data.results)
    }
  }, [data])

  const lenList = cellList?.length

  if (user.role === USER_ROLES.serviceman) {
    // какой пиздец (но это кастыль сделанный в 3 утра под щафе в пятницу)
    if (
      queryParams.get('cell_status') ===
        CELL.STATUS.OCCUPIED &&
      queryParams.get('content_status') ===
        CELL.CONTENT.AVAILABLE &&
      isLoading === false &&
      lenList === 0
    ) {
      return (
        <InfoMessagePage
          linkBack='/'
          errorMessage='Отсутствует исправное оборудование'
          linkToMain=''
        />
      )
    }

    if (
      queryParams.get('cell_status') ===
        CELL.STATUS.OCCUPIED &&
      queryParams.get('content_status') ===
        CELL.CONTENT.NOT_WORKING &&
      isLoading === false &&
      lenList === 0
    ) {
      return (
        <InfoMessagePage
          linkBack='/'
          errorMessage='Отсутствует не исправное оборудование'
          linkToMain=''
        />
      )
    }
  }

  if (
    (data?.results == null && isLoading === false) ||
    data?.results.length === 0
  ) {
    if (queryParams.get('cell_status') !== null) {
      return (
        <InfoMessagePage
          linkToMain={
            config?.links?.verification ??
            DEFAULT_LINKS.verification
          }
          linkBack={
            config?.links?.verification ??
            DEFAULT_LINKS.verification
          }
          errorMessage={
            errorMessange[
              queryParams.get('cell_status') as string
            ]
          }
        />
      )
    }
  }

  return (
    <MainLayout
      linkBack={
        config?.links?.verification ??
        DEFAULT_LINKS.verification
      }
    >
      <div className={styles.container}>
        <CSSTransitionGroup
          animation='fadeInTop'
          component={Typography}
          size={60}
          weight={700}
        >
          Выберите ячейку
        </CSSTransitionGroup>
        {isFirstLoading === true
          ? (
            <GxSpinner
              className={styles.container__spinner}
              size='large'
            />
            )
          : (
            <CellsList
              cells={cellList}
              changeNumber={changeNumber}
              {...options}
            />
            )}
      </div>
    </MainLayout>
  )
}

export default observer(HandOverInCell)
