const defaultKeyboard = [
  'ё й ц у к е н г ш щ з х ъ',
  'ф ы в а п р о л д ж э',
  '{capslock} я ч с м и т ь б ю {backspace}',
  '{controlleft} {language} {space} , . {altright}'
]

const shiftKeyboard = [
  'Ё Й Ц У К Е Н Г Ш Щ З Х Ъ',
  'Ф Ы В А П Р О Л Д Ж Э',
  '{capslock} Я Ч С М И Т Ь Б Ю {backspace}',
  '{controlleft} {language} {space} , . {altright}'
]

const numericKeyboard = [
  '1 2 3 4 5 6 7 8 9 0',
  '- / : ; ( ) $ & @ "',
  "{capslock} # + ? ! ' * % ^ {backspace}",
  '{controlleft} {language} {space} , . {altright}'
]

const englishKeyboardShift = [
  'Q W E R T Y U I O P',
  'A S D F G H J K L',
  '{capslock} Z X C V B N M {backspace}',
  '{controlleft} {language} {space} , . {altright}'
]

const englishKeyboard = [
  'q w e r t y u i o p',
  'a s d f g h j k l',
  '{capslock} z x c v b n m {backspace}',
  '{controlleft} {language} {space} , . {altright}'
]

const layoutKeyboard = {
  default: defaultKeyboard,
  shift: shiftKeyboard,
  numeric: numericKeyboard,
  english: englishKeyboard,
  englishBig: englishKeyboardShift
}

const displayKeyboard = {
  '{language}':
    '<svg width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<g clip-path="url(#clip0_201_156)">\n' +
    '<path d="M10 20C12.7614 20 15 15.5228 15 10C15 4.47715 12.7614 0 10 0M10 20C7.23858 20 5 15.5228 5 10C5 4.47715 7.23858 0 10 0M10 20V0M0 10H20M20 24C20 28.9706 15.5228 33 10 33C4.47715 33 0 28.9706 0 24C0 19.0294 4.47715 15 10 15C15.5228 15 20 19.0294 20 24ZM20 -3C20 1.97056 15.5228 6 10 6C4.47715 6 0 1.97056 0 -3C0 -7.97056 4.47715 -12 10 -12C15.5228 -12 20 -7.97056 20 -3Z" stroke="black"/>\n' +
    '</g>\n' +
    '<rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="black"/>\n' +
    '<defs>\n' +
    '<clipPath id="clip0_201_156">\n' +
    '<rect width="20" height="20" rx="10" fill="white"/>\n' +
    '</clipPath>\n' +
    '</defs>\n' +
    '</svg>\n',
  '{backspace}':
    '<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M22 3.375H7C6.31 3.375 5.77 3.725 5.41 4.255L0 12.375L5.41 20.485C5.77 21.015 6.31 21.375 7 21.375H22C23.1 21.375 24 20.475 24 19.375V5.375C24 4.275 23.1 3.375 22 3.375ZM22 19.375H7.07L2.4 12.375L7.06 5.375H22V19.375ZM10.41 17.375L14 13.785L17.59 17.375L19 15.965L15.41 12.375L19 8.785L17.59 7.375L14 10.965L10.41 7.375L9 8.785L12.59 12.375L9 15.965L10.41 17.375Z" fill="#3D3D3F"/>\n' +
    '</svg>\n',
  '{space}': 'Пробел',
  '{capslock}':
    '<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M12.6821 3.16363C12.3052 2.77879 11.6948 2.77879 11.3179 3.16363L2.78466 11.8802C2.17675 12.5016 2.60693 13.5643 3.46679 13.5643H6.49999V20.8528C6.49999 21.4173 6.94775 21.875 7.49999 21.875H16.5C17.0522 21.875 17.5 21.4173 17.5 20.8528V13.5643H20.5332C21.3931 13.5643 21.8232 12.5016 21.2153 11.8802L12.6821 3.16363Z" fill="#3D3D3F"/>\n' +
    '</svg>',
  '{altright}':
    '<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M11.59 7.535L15.17 11.125H1V13.125H15.17L11.58 16.715L13 18.125L19 12.125L13 6.125L11.59 7.535ZM20 6.125V18.125H22V6.125H20Z" fill="white"/>\n' +
    '</svg>'
}

export {
  defaultKeyboard,
  shiftKeyboard,
  numericKeyboard,
  layoutKeyboard,
  displayKeyboard
}
