import { useEffect, useMemo, useState } from 'react'
import { useFormik, FormikHelpers } from 'formik'
import { useStores } from './useStores'
import { connectWifiFormSchem } from '@/utils/validation'
import {
  IHookConnectToWifi,
  IHookConnectToWifiReturn,
  IFormConnectToWifiValues,
  TSelectProtocol
} from '@/interfaces/Hooks'
import { AUTJORIZATION_METHODS, EMPTY_CONSTS, IP_TYPE } from '@/const'
import { cleanObjFromEmptyValues, getObjectErrorsFormik } from '@/utils'
import { FORM_NAMES } from '@/utils/formNames'
import { IConnectToWifiEnterprise } from '@/api/interfaces/Network'
import { IChangeInputEvent } from '@/interfaces/Common'

/**
 *? CONSTS
 */
export const initialValues = {
  ssid: EMPTY_CONSTS.STR,
  bssid: EMPTY_CONSTS.STR,
  password: EMPTY_CONSTS.STR,
  autoconnect: EMPTY_CONSTS.TRUE,
  ip: EMPTY_CONSTS.STR,
  mask: EMPTY_CONSTS.STR,
  gateway: EMPTY_CONSTS.STR,
  dns: EMPTY_CONSTS.STR,
  domain: EMPTY_CONSTS.STR,
  login: EMPTY_CONSTS.STR,
  protocol_choices: EMPTY_CONSTS.STR
}

/**
 *? Hook to use AddNewWifi Form
 */
const useConnectToWifiForm = ({
  ssid = EMPTY_CONSTS.STR,
  bssid = EMPTY_CONSTS.STR,
  protocols = EMPTY_CONSTS.ARR,
  isEnterprise = EMPTY_CONSTS.FALSE,
  afterSuccess = EMPTY_CONSTS.FUNC,
  afterError = EMPTY_CONSTS.FUNC,
  beforeSubmit = EMPTY_CONSTS.FUNC
}: IHookConnectToWifi): IHookConnectToWifiReturn => {
  const { api } = useStores()
  const [, setIsLoading] = useState(EMPTY_CONSTS.FALSE)
  const [, setIsError] = useState(EMPTY_CONSTS.FALSE)
  const [isKeyBoard, setIsKeybard] = useState(EMPTY_CONSTS.FALSE)
  const [keyboardValue, setKeyboardValue] = useState<string | null>(null)
  const [keyboardFild, setKeyboardField] = useState(EMPTY_CONSTS.STR)
  const [isDinamicIP, setIsDinamicIP] = useState(EMPTY_CONSTS.TRUE) // ip variant Static/Dinamic
  const [initValues] = useState({
    ...initialValues,
    ssid,
    bssid
  })
  const selectProtololOptions = useMemo<TSelectProtocol[]>(() => protocols, [protocols])

  const [currentProtocol, setProtocol] = useState<TSelectProtocol>(
    selectProtololOptions[0]
  )

  /**
   * закрыт клавиатуру
   */
  const closeKeyboard = (): void => {
    setIsKeybard(false)
  }

  /**
   * Handler submit Form
   * filtering values to empty value
   * setting loader
   * and processing respond
   */
  const onSubmit = (
    values: IFormConnectToWifiValues,
    { setSubmitting, setErrors }: FormikHelpers<IFormConnectToWifiValues>
  ): void => {
    setIsLoading(EMPTY_CONSTS.TRUE)
    const filteredValue = cleanObjFromEmptyValues<IFormConnectToWifiValues>(values)
    const connectedWifiData = {
      ...filteredValue,
      connection_type: isDinamicIP
        ? IP_TYPE.DYNAMIC
        : IP_TYPE.STATIC,
      security: isEnterprise
        ? AUTJORIZATION_METHODS['WPA2-Enterprise']
        : AUTJORIZATION_METHODS['WPA2-Personal']
    }
    beforeSubmit(connectedWifiData)
    let promise
    if (isEnterprise) {
      promise = api.network.connectToWiFiEnterprise(connectedWifiData as IConnectToWifiEnterprise)
    } else {
      promise = api.network.connectToWiFi(filteredValue)
    }
    promise
      .then((data) => {
        afterSuccess(connectedWifiData)
        closeKeyboard()
      })
      .catch((e) => {
        if (e.response?.data?.error?.length > EMPTY_CONSTS.ZERO) {
          const errors = getObjectErrorsFormik(e)
          setErrors(errors)
        } else {
          setIsError(EMPTY_CONSTS.TRUE)
          afterError(connectedWifiData)
        }
      })
      .finally(() => {
        setIsLoading(EMPTY_CONSTS.FALSE)
        setSubmitting(EMPTY_CONSTS.FALSE)
      })
  }

  /**
   * OnFocus input field keyBoard open
   * @param event
   */
  const onFocusInput = (
    event: IChangeInputEvent
  ): void => {
    setKeyboardValue(event.target.value)
    setKeyboardField(event.target.name)
    setIsKeybard(true)
  }

  const onBlurInput = (
    event: IChangeInputEvent
  ): void => {}

  /**
   * Proxi input from vitrual keyboard to simple handlerInput
   * @param event
   */
  const onKeyboardInput = (input: string): void => {
    void handleInput(input)
  }

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    values,
    errors
  } = useFormik<IFormConnectToWifiValues>({
    initialValues: initValues,
    onSubmit: onSubmit,
    validationSchema: () => connectWifiFormSchem(isDinamicIP, isEnterprise),
    validateOnMount: EMPTY_CONSTS.FALSE,
    validateOnChange: EMPTY_CONSTS.FALSE,
    validateOnBlur: true
  })

  /**
   * Handler change Protocol SELECT
   */
  const handleChangeProtocol = (elem: TSelectProtocol): void => {
    setProtocol(elem)
  }

  /**
   * Handler change Tab Value (Static/Dinamic)
   */
  const changeIpType = (value: boolean): void => {
    setIsDinamicIP(value)
  }

  /**
   * Handler input Value
   */
  const handleInput = (value: string): void => {
    setKeyboardValue(value)
    void setFieldValue(keyboardFild, value)
  }

  /**
   * Handler change Switch Value
   */
  const handleSwith = (): void => {
    void setFieldValue(
      FORM_NAMES.AUTOCONNECT,
      !values.autoconnect
    )
  }

  /**
   * return to initialValueses field  when ip-Dinamic
   */
  const clearStaticFields = (): void => {
    void setValues({
      ...values,
      ip: EMPTY_CONSTS.STR,
      mask: EMPTY_CONSTS.STR,
      gateway: EMPTY_CONSTS.STR,
      dns: EMPTY_CONSTS.STR
    })
  }

  /**
   * Reaction on change IP type (Dinamic/Static)
   * clear additional values or fill them
   */
  useEffect(() => {
    const updateFields = (): void => {
      if (isDinamicIP) {
        clearStaticFields()
      }
    }
    updateFields()
  }, [isDinamicIP])

  /**
   * Reaction on change Protocol SELECT
   */
  useEffect(() => {
    if (currentProtocol?.value !== undefined && isEnterprise) {
      void setFieldValue(FORM_NAMES.PROTOCOL_CHOICES, currentProtocol.value)
    }
  }, [currentProtocol])

  return {
    values,
    errors,
    isDinamicIP,
    isKeyBoard,
    keyboardValue,
    selectProtololOptions,
    currentProtocol,
    changeIpType,
    handleSubmit,
    handleChange,
    handleSwith,
    onKeyboardInput,
    onFocusInput,
    onBlurInput,
    closeKeyboard,
    handleChangeProtocol
  }
}

export default useConnectToWifiForm
