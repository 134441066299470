import React, { FC } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import InfoMessagePage from '@/views/infoMessagePage/InfoMessagePage'

const NoCellAvailable: FC = () => {
  return (
    <MainLayout>
      <InfoMessagePage
        linkBack={undefined}
        visibleLinkBack={false}
        errorMessage='Отсутвствуют доступные ячейки'
        linkToMain='/'
        showBtn
        isExitBtn
      />
    </MainLayout>
  )
}

export default NoCellAvailable
