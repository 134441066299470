import React, { useState } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import {
  usePage,
  useSiteConfig,
  useStores
} from '@/hooks'
import { NetworkMessage } from '@/views/networkMessage'
import { LanIcon } from '@/assets/icon'
import LanConnection from '@/components/lanConnection/LanConnection'
import NetworkConnectContainer from '@/components/connectNetwork'
import { notify } from '@/utils/notify'
import { DEFAULT_LINKS } from '@/const'

const NetworkConfigurationLan: React.FC =
  (): React.ReactElement => {
    const config = useSiteConfig()
    const page = usePage()
    const lanConnected = page?.init_state?.lan_connected as boolean
    const { api } = useStores()
    const [isConnectLan, setIsConnectLan] = useState(
      lanConnected
    )
    const [isActiveSwitch, setIsActiveSwitch] = useState(isConnectLan)

    const toggleSwitch = (): void => {
      setIsActiveSwitch((prev: boolean) => !prev)
      api.network
        .connectLanTurnOn()
        .then((res) => {
          setIsConnectLan(res.lan_connected)
          setIsActiveSwitch(res.lan_connected)
        })
        .catch(() => {
          setIsConnectLan(false)
          setIsActiveSwitch(false)
          notify.error('Ошибка переключения LAN')
        })
    }

    return (
      <MainLayout linkBack={config?.links?.main_menu_net ?? DEFAULT_LINKS.main_menu_net}>
        <NetworkConnectContainer
          label='Подключение LAN'
          isConnect={isActiveSwitch}
          toggleSwitch={toggleSwitch}
        >
          {isConnectLan
            ? (
              <LanConnection />
              )
            : (
              <NetworkMessage
                message='Чтобы подключиться через кабель, включите LAN'
                icon={LanIcon}
              />
              )}
        </NetworkConnectContainer>
      </MainLayout>
    )
  }

export default NetworkConfigurationLan
