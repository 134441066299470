import en from './messages/en/index.json'
import ru from './messages/ru/index.json'

// Набор языков должен быть равен набору на беке
const languages = ['en', 'ru']

const htmlLang = {
  ru: 'ru',
  en: 'en'
}

// Должен быть равен языку на беке
const defaultLanguage = 'en'

// Правила наименования messages ключей
// app.component.key
// или
// Если это общий текст просто key
// Объект должен быть всегда плоский
/*
{
  "app.message.today": "Today it's the {ts, date, ::yyyyMMdd}",
  "app.message.helloWorld": "Hello, world",
}
*/
const messages = {
  en,
  ru
}

export { languages, defaultLanguage, messages, htmlLang }
