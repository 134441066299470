import React, { FC } from 'react'
import classNames from 'classnames'
import style from './style/index.module.scss'

interface IScanSignal {
  className?: string
}

export const ScanSignal: FC<IScanSignal> = (props) => {
  const { className } = props

  return (
    <svg
      className={classNames(style.container, className)}
      width='403'
      height='298'
      viewBox='0 0 403 298'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M223.925 149C223.925 162.325 213.601 172.603 201.5 172.603C189.399 172.603 179.076 162.325 179.076 149C179.076 135.675 189.399 125.396 201.5 125.396C213.601 125.396 223.925 135.675 223.925 149Z'
        fill='#f80'
        stroke='#f80'
        strokeWidth='16'
      />
      <path
        d='M258.582 209.165C273.721 193.208 282.226 171.566 282.226 149C282.226 126.434 273.721 104.792 258.582 88.835'
        stroke='#f80'
        strokeWidth='16'
        strokeLinecap='round'
      />
      <path
        d='M144.418 88.835C136.922 96.7359 130.975 106.116 126.918 116.439C122.861 126.762 120.773 137.826 120.773 149C120.773 160.174 122.861 171.238 126.918 181.561C130.975 191.884 136.922 201.264 144.418 209.165'
        stroke='#f80'
        strokeWidth='16'
        strokeLinecap='round'
      />
      <path
        d='M338.208 290C374.571 252.604 395 201.885 395 149C395 96.1147 374.571 45.3955 338.208 8'
        stroke='#f80'
        strokeWidth='16'
        strokeLinecap='round'
      />
      <path
        d='M64.7924 8C46.787 26.5164 32.5043 48.4985 22.7599 72.6914C13.0154 96.8842 8 122.814 8 149C8 175.186 13.0154 201.116 22.7599 225.309C32.5043 249.501 46.787 271.484 64.7925 290'
        stroke='#f80'
        strokeWidth='16'
        strokeLinecap='round'
      />
      <path
        d='M300.076 243.81C324.491 218.665 338.208 184.561 338.208 149C338.208 113.439 324.491 79.335 300.076 54.1897'
        stroke='#f80'
        strokeWidth='16'
        strokeLinecap='round'
      />
      <path
        d='M102.925 54.1897C90.8352 66.6404 81.2454 81.4215 74.7027 97.6891C68.16 113.957 64.7925 131.392 64.7925 149C64.7925 166.608 68.16 184.043 74.7027 200.311C81.2454 216.579 90.8352 231.36 102.925 243.81'
        stroke='#f80'
        strokeWidth='16'
        strokeLinecap='round'
      />
    </svg>
  )
}
