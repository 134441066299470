import React from 'react'
import { OptionButtonNetwork } from '@/views/optionButtonNetwork'
import { AutoConnection } from '@/views/autoConnection'
import { Button } from '@/views/button'
import { Input } from '../input'
import { IFormConnectToWifiValues, IFormConnectToWifiErrors, TSelectProtocol } from '@/interfaces/Hooks'
import styles from './style/index.module.scss'
import { Select } from '../select'
import { IBlurInputEvent, IChangeInputEvent, IFocusInputEvent } from '@/interfaces/Common'

interface IProps {
  handleSubmit: (
    e?: React.FormEvent<HTMLFormElement> | undefined
  ) => void
  onFocusInput: (event: IFocusInputEvent) => void
  onBlurInput: (event: IBlurInputEvent) => void
  changeIpType: (value: boolean) => void
  handleSwith: () => void
  handleChange: (event: IChangeInputEvent) => void
  values: IFormConnectToWifiValues
  protocols: TSelectProtocol[]
  currentProtocol: TSelectProtocol
  onChangeProtocol: (el: TSelectProtocol) => void
  isEnterprise: boolean
  errors: IFormConnectToWifiErrors
  isDinamicIP: boolean
}

const ConnectTowifiForm: React.FC<IProps> = ({
  values,
  errors,
  protocols,
  isEnterprise,
  isDinamicIP,
  currentProtocol,
  onChangeProtocol,
  changeIpType,
  handleSubmit,
  handleChange,
  handleSwith,
  onFocusInput,
  onBlurInput
}): React.ReactElement => {
  return (
    <form
      className={styles['connect-wifi__form']} onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      {isEnterprise
        ? <Input
            id='login'
            name='login'
            data-testid='login'
            value={values.login}
            error={Boolean(errors.login)}
            errorMessage={errors.login}
            label='Логин'
            onChange={handleChange}
            onFocus={onFocusInput}
            onBlur={onBlurInput}
            autoComplete='off'
          />
        : null}
      <Input
        id='password'
        name='password'
        data-testid='password'
        value={values.password}
        error={Boolean(errors.password)}
        errorMessage={errors.password}
        label='Пароль'
        onChange={handleChange}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        autoComplete='off'
        type='password'
      />
      {isEnterprise
        ? <Input
            id='domain'
            name='domain'
            data-testid='domain'
            value={values.domain}
            error={Boolean(errors.domain)}
            errorMessage={errors.domain}
            message='Не обязательное поле'
            label='Домен'
            onChange={handleChange}
            onFocus={onFocusInput}
            onBlur={onBlurInput}
            autoComplete='off'
          />
        : null}
      {isEnterprise
        ? <Select
            onChange={onChangeProtocol}
            label='Протокол безопасности'
            value={currentProtocol}
            options={protocols}
          />
        : null}
      <AutoConnection value={values.autoconnect} handleSwitch={handleSwith} />
      <OptionButtonNetwork
        isDinamicIP={isDinamicIP}
        handleChangeIpType={changeIpType}
        values={values}
        errors={errors}
        handleInput={handleChange}
        handleFocusInput={onFocusInput}
        handleBlurInput={onBlurInput}
      />
      <Button type='submit'>
        Подключиться
      </Button>
    </form>
  )
}

export default ConnectTowifiForm
