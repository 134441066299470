import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { WiFiIcon } from '@/assets/icon'
import { Typography } from '@/views/typography'
import styles from '../style/index.module.scss'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { useSiteConfig } from '@/hooks'
import { DEFAULT_LINKS } from '@/const'

const ErrorWifiConnect: React.FC = (): React.ReactElement => {
  const config = useSiteConfig()

  return (
    <div className={styles['wifi-connect__container']}>
      <div
        className={classNames(styles['wifi-connect__content'], {
          [styles['wifi-connect__content_error']]: true
        })}
      >
        <GxIcon
          src={WiFiIcon}
          className={styles['wifi-connect__logo']}
        />
        <Typography
          size={80}
          className={styles['wifi-connect__title']}
          weight={700}
        >
          Oшибка подключения
        </Typography>
        <div className={styles['wifi-connect__btn_wrap']}>
          <NavLink
            to={config?.links?.net_settings !== undefined ? config?.links?.net_settings : DEFAULT_LINKS.net_settings}
            className={styles['wifi-connect__btn']}
          >
            В настройки
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default ErrorWifiConnect
