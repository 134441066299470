import React, { FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import TimerComponent from '@/components/timer/TimerComponent'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import HeaderCurrentCell from '@/views/currentCell/header/HeaderCurrentCell'
import SpinerWithMainLayout from '@/views/spinner/SpinerWithMainLayout'
import { Typography } from '@/views/typography'

import {
  useCurrentDevice,
  useStores,
  useObjectPage,
  useCurrentUser
} from '@/hooks'
import { CELL, USER_ROLES, CONTENT_STATUSES } from '@/const'
import { ICell } from '@/api/interfaces/Cell'

import style from './style/index.module.scss'

const CurrentCell: FC = () => {
  const store = useStores()
  const [isLoading, setisLoading] = useState(true)
  const [statusCell, setstatusCell] = useState(
    CELL.STATUS.UNKNOWN
  )
  const [isClose, setIsClose] = useState(false)
  const device = useCurrentDevice()
  const user = useCurrentUser()
  const {
    cell_number: cellNumber,
    id,
    content_status: contentStatus
  } = useObjectPage()
  const isTemporary = CONTENT_STATUSES.TEMPORARY === contentStatus
  const showTemporaryStatus =
    (user.role === USER_ROLES.engineer ||
      user.role === USER_ROLES.workman) &&
      isTemporary

  /**
   * @function handleOpenWithoutChangeStatusCell
   * метод для открытия ячейки без смены ее статуса
   */
  const handleOpenWithoutChangeStatusCell = (
    currentCell: ICell
  ): void => {
    void store.api.cell
      .openCellWithoutChangeStatus({ id })
      .then(() => {
        setstatusCell(
          currentCell?.cell_status as keyof typeof CELL.STATUS
        )
        setisLoading(false)
      })
  }

  const handleChangeOpen = (
    currentCell: ICell,
    isResending?: boolean
  ): void => {
    // отправим запрос на открытие ячейки пустой ячейки
    void store.api.cell
      .openCellForChange({ id })
      .then(() => {
        setstatusCell(
          currentCell?.cell_status as keyof typeof CELL.STATUS
        )
        /**
         * @info
         * ? если повторная сдача то мы говорим положить оборудование
         * */
        if (isResending !== undefined && isResending) {
          setstatusCell(CELL.STATUS.REPEAT)
        }
        setisLoading(false)
      })
  }

  const handleOpen = (
    currentCell: ICell | undefined
  ): void => {
    // отправим запрос на открытие ячейки s
    void store.api.cell.openCell({ id }).then(() => {
      setstatusCell(
        currentCell?.cell_status as keyof typeof CELL.STATUS
      )
      setisLoading(false)
    })
  }
  /**
   * получение статуса ячейки для последующией синхронизации
   * todo: переделать на другой запрос (на текущий момент нет запроса с id)
   * @type side effect
   */
  const getStatusCell = (): void => {
    void store.api.cell
      .getAllStatusesCells()
      .then((res) => {
        // найдём текущюю ячейку (запрос вовзращяет все доступные)
        const currentCell = res.find((el) => el.id === id)
        // кастыль
        const params = new URL(window.location.href)
          .searchParams
        const isResending: string | null =
          params.get('is_resending') // is the string "Jonathan Smith".

        if (
          isResending === 'true' &&
          currentCell !== undefined
        ) {
          return handleChangeOpen(currentCell, true)
        }
        // конец кастыля

        if (
          user.role === USER_ROLES.engineer &&
          currentCell !== undefined
        ) {
          return handleOpenWithoutChangeStatusCell(
            currentCell
          )
        }

        // ? разделенно из-за того что страница одна но используется и для сдачи и для получения
        if (currentCell?.cell_status === CELL.STATUS.FREE) {
          handleChangeOpen(currentCell)
        } else {
          handleOpen(currentCell)
        }
      })
  }

  useEffect(() => {
    void getStatusCell()
  }, [])

  if (isLoading) {
    return <SpinerWithMainLayout />
  }

  const bottomText = {
    [CELL.STATUS.OCCUPIED]: (
      <Typography size={50}>
        Достаньте оборудование <br /> и закройте ячейку
      </Typography>
    ),
    [CELL.STATUS.FREE]: (
      <Typography size={50}>
        Положите оборудование <br /> и закройте ячейку
      </Typography>
    ),
    [CELL.STATUS.REPEAT]: (
      <Typography size={50}>
        Положите оборудование <br /> и закройте ячейку
      </Typography>
    ),
    [USER_ROLES.engineer]: (
      <Typography size={50}>
        После закрытия ячейки ее статус <br /> не изменится
      </Typography>
    )
  }

  return (
    <div className={style.container}>
      <HeaderCurrentCell
        cellNumber={cellNumber}
        title={
          device?.equipment_type?.title ?? 'Пустая ячейка'
        }
        equipmentTitle={device?.title_ru}
        isTemporary={showTemporaryStatus}
      />
      <CSSTransitionGroup
        animation='fadeInTop'
        component={Typography}
        size={80}
        className={style.container__title}
      >
        {isClose ? 'Закройте ячейку' : 'Ячейка открыта'}
      </CSSTransitionGroup>
      <CSSTransitionGroup
        className={style.container__timer}
      >
        <TimerComponent
          cellNumber={cellNumber}
          setIsClose={setIsClose}
        />
      </CSSTransitionGroup>
      <CSSTransitionGroup
        animation='fadeInBottom'
        className={style.container__footer}
      >
        {user.role === USER_ROLES.engineer
          ? bottomText[USER_ROLES.engineer]
          : bottomText[statusCell]}
      </CSSTransitionGroup>
    </div>
  )
}

export default observer(CurrentCell)
