import { useCallback, useEffect, useRef } from 'react'

/**
 * Хук debuonce
 * @param callback функция кожбэк
 * @param delay задержка
 * @returns callback
 */
function useThrottle (callbackFunc: (...args: any[]) => void, delay: number): Function {
  const timer = useRef<NodeJS.Timeout | null>(null)

  const throttledCallback = useCallback(
    (...args: any[]) => {
      if (timer.current == null) {
        callbackFunc(...args)
        timer.current = setTimeout(() => {
          clearTimer()
        }, delay)
      }
    },
    [callbackFunc, delay]
  )

  const clearTimer = useCallback(() => {
    if (timer.current != null) {
      clearTimeout(timer.current)
      timer.current = null
    }
  }, [])

  useEffect(() => {
    return () => {
      clearTimer()
    }
  }, [])

  return throttledCallback
}

export default useThrottle
