import { languages, defaultLanguage } from '@/i18n'
import { format, parse } from 'date-fns'
import {
  EMPTY_CONSTS,
  MOCKS_DATA,
  SIMPLE_STATUSES
} from '@/const'
import isEqual from 'react-fast-compare'
import {
  IFormAddNewWifiValues,
  IFormConnectToWifiValues,
  IFormSettingWifiValues,
  IInfoSettingWifiValues
} from '@/interfaces/Hooks'
import { FORM_NAMES } from './formNames'
import { ICurrentWifi } from '@/services/store/CurrentWifi'

export const getLocaleByPathname = (
  pathname: string
): string => {
  const language = pathname.split('/')[1]
  return languages.includes(language)
    ? language
    : defaultLanguage
}

export const debounce = (
  fn: (...params: any[]) => any,
  n: number,
  immed: boolean = false
): any => {
  let timer: number | undefined
  return function (this: any, ...args: any[]) {
    if (timer === undefined && immed) {
      fn.apply(this, args)
    }
    if (timer !== undefined) {
      clearTimeout(timer)
    }
    // @ts-expect-error
    timer = setTimeout(() => fn.apply(this, args), n)
    return timer
  }
}

export const nope = (): void => {}

export const isString = (value: any): boolean =>
  typeof value === 'string' || value instanceof String

export function formatDateForBackend (date: string): string {
  return format(
    parse(date, 'dd/MM/yyyy', new Date()),
    'yyyy-MM-dd'
  )
}

export function formatDateForFrontend (
  date: string
): string {
  return format(
    parse(date, 'yyyy-MM-dd', new Date()),
    'dd/MM/yyyy'
  )
}

export const formatDateTime = (
  inputDateStr: string
): {
  day: any
  month: any
  year: any
  hours: any
  minutes: any
} => {
  // Преобразуем строку в объект Date
  const date = new Date(inputDateStr)
  // Получаем день, месяц, год, часы и минуты
  const day = date.getDate()
  const month = date.getMonth() + 1 // Месяцы в JavaScript начинаются с 0
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const formattedMinuts = minutes < 10 ? `0${minutes}` : minutes

  return {
    day,
    month,
    year,
    hours,
    minutes: formattedMinuts
  }
}

export function formatDateForFrontendExperemental (
  date: string
): string {
  return format(
    parse(date, 'yyyy-MM-dd', new Date()),
    'dd/MM/yyyy'
  )
}

/**
 * ПРосто сериалайзер для обработки контекстов
 * @param data  - context
 * @param page  - ключ для объекта с расширениями контекста
 * @returns
 */
export const mockContext = (
  data: any,
  page: string | number
): any => {
  const context = data
  context.mock = MOCKS_DATA[page]
  return context
}

type IEventScroll = React.UIEvent<HTMLDivElement> & {
  target: {
    scrollTop: number
    clientHeight: number
    scrollHeight: number
  }
}
/**
 *
 * @param event  - native scroll event
 */
export const isScrollEnd = (
  event: IEventScroll
): boolean => {
  const container = event.target
  const offsetTop = Number(
    (container.scrollTop + container.clientHeight).toFixed()
  )
  if (container.scrollHeight - offsetTop < 50) {
    // Доскролили до конца блока
    return true
  }
  return false
}
//! DEPRECATED
export function log (msg: any, color?: string): void {}

/**
 * Создание объекта с ошибками из запроса для вывода в форме
 *@info Содержит e.response.data.error
 *@example Пример:
 * e.response.data.error = [
 * {password: 'невалид'},
 * {email: ['невалид', 'что это вообще такое?']}
 * ]
 */
export const getObjectErrorsFormik = (e: {
  response: {
    data: {
      error: ArrayLike<unknown> | { [s: string]: unknown }
    }
  }
}): Record<string, string> => {
  return Object.entries(e.response.data.error).reduce(
    (all, [, errObj]) => {
      const [[key, value]] = Object.entries(
        errObj as Record<string, string | string[]>
      )
      return {
        ...all,
        [key]: Array.isArray(value)
          ? value.join('. ')
          : value
      }
    },
    {}
  )
}

/**
 * Функция проверяющая является ли элемент null | undefined
 * @param targetElement Сравниваемый элемент
 * @returns Если да то true | если нет  false
 */
export const isNullOrUndefined = (
  value: unknown
): boolean => {
  return value === null || value === undefined
}

/**
 * Функция проверяющая есть ли такой же элемент в массиве
 * @param targetElement Сравниваемый элемент
 * @param comparisonElements Массив элементов с котрыми надо сравнивать
 * @returns Если есть то true | если нет  false
 */
export const isEqualWith = (
  targetElement: unknown,
  comparisonElements: unknown[]
): boolean => {
  for (const comraredElement of comparisonElements) {
    if (isEqual(targetElement, comraredElement)) {
      return true
    }
  }
  return false
}

export const objToQs = (
  obj: { [s: string]: unknown } | ArrayLike<unknown>
): string => {
  const queryParams = Object.entries(obj)
    .map(
      ([key, value]: any) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}`
    )
    .join('&')

  return `?${queryParams}`
}

/**
 * Выборка отображени надписи о статусе вайфая на странице настроки Сетей
 * @params
 * wifiModuleStatus -- статус модуля (вкл\выкл)
 * connectedWifiSsid  -- имя подключеной сети
 * currentWifiStoreStatus  -- статус сети в сторе соответствует SIMPLE_STATUSES
 * @returns
 * если модуль Wifi выключен -> subtitle = ''
 * если включен и имеется имя сети -> subtitle = ssid
 * если предыдущ условия не верны и статус подключеной сети в сторе != 'error' -> не подключено никакой сети
 * если предыдущ условия не верны и статус подключеной сети в сторе = 'error' -> 'Ошибка подключения'
 */
export const getSubtitleWifiStatus = ({
  wifiModuleStatus,
  connectedWifiSsid,
  currentWifiStoreStatus
}: {
  wifiModuleStatus: boolean
  connectedWifiSsid: string | boolean
  currentWifiStoreStatus: string | undefined
}): string => {
  const subtitles = {
    connected:
      typeof connectedWifiSsid === 'string'
        ? connectedWifiSsid
        : 'Подключено',
    off: '',
    error: 'Ошибка подключения',
    not_connected: 'Не подключено'
  }

  if (!wifiModuleStatus) return subtitles.off
  if (
    typeof connectedWifiSsid === 'string' &&
    connectedWifiSsid.length !== 0
  ) {
    return subtitles.connected
  }
  if (currentWifiStoreStatus !== SIMPLE_STATUSES.ERROR) {
    return subtitles.not_connected
  }
  if (currentWifiStoreStatus === SIMPLE_STATUSES.ERROR) {
    return subtitles.error
  }
  return subtitles.off
}

/**
 * Выборка отображени надписи о статусе LAN на странице настроки Сетей
 * @params
 * connectedWifiSsid  -- имя подключеной сети
 * lanStatus  -- статус LAN
 * @returns
 * @info Нижнее условие Не должно воиспроизводиться но проверка должна быть чтоб видеть ситауцию
 * если подключены к сети wifi и так же подключение по лан активно -> subtitle = Подключено
 * если подключены к сети wifi и подключение по лан не активно -> subtitle = ''
 * если предыдущ условия не верны значит подключения по вайфай нету и отображаем значение LAN -> 'Подключено' \ 'Не подключено'
 */
export const getSubtitleLanStatus = ({
  connectedWifiSsid = '',
  lanStatus = false
}: {
  connectedWifiSsid: string
  lanStatus: boolean
}): string => {
  const realStatus = !isEqualWith(lanStatus, [
    null,
    false,
    undefined
  ])

  if (realStatus && connectedWifiSsid.length > 0) {
    return 'Подключено'
  }
  if (!realStatus && connectedWifiSsid.length > 0) return ''

  return realStatus ? 'Подключено' : 'Не подключено'
}

/**
 * @info Получить начальные значения для Формы
 * если текущий вайфай в статусее подключен с ошибкой
 * то взять данные из стора
 * если подключен то взять данные из контекста
 */
export const getInitValuesSettingsCurrentWifi = (
  contextInfoWifi: { [x: string]: any, bssid: undefined },
  storeInfoWifi: ICurrentWifi | null
): IFormSettingWifiValues => {
  if (contextInfoWifi?.bssid !== undefined) {
    return {
      autoconnect:
        contextInfoWifi[FORM_NAMES.AUTOCONNECT] ??
        EMPTY_CONSTS.STR,
      ip:
        contextInfoWifi[FORM_NAMES.IP] ?? EMPTY_CONSTS.STR,
      mask:
        contextInfoWifi[FORM_NAMES.MASK] ??
        EMPTY_CONSTS.STR,
      gateway:
        contextInfoWifi[FORM_NAMES.GATEWAY] ??
        EMPTY_CONSTS.STR,
      dns:
        contextInfoWifi[FORM_NAMES.DNS] ?? EMPTY_CONSTS.STR,
      domain:
        contextInfoWifi[FORM_NAMES.DOMAIN] ??
        EMPTY_CONSTS.STR,
      login:
        contextInfoWifi[FORM_NAMES.LOGIN] ??
        EMPTY_CONSTS.STR,
      protocol_choices:
        contextInfoWifi[FORM_NAMES.PROTOCOL_CHOICES] ??
        EMPTY_CONSTS.STR,
      password: EMPTY_CONSTS.STR
    }
  } else {
    return {
      autoconnect:
        storeInfoWifi?.[FORM_NAMES.AUTOCONNECT] ??
        EMPTY_CONSTS.FALSE,
      password: EMPTY_CONSTS.STR,
      ip:
        storeInfoWifi?.[FORM_NAMES.IP] ?? EMPTY_CONSTS.STR,
      mask:
        storeInfoWifi?.[FORM_NAMES.MASK] ??
        EMPTY_CONSTS.STR,
      gateway:
        storeInfoWifi?.[FORM_NAMES.GATEWAY] ??
        EMPTY_CONSTS.STR,
      dns:
        storeInfoWifi?.[FORM_NAMES.DNS] ?? EMPTY_CONSTS.STR,
      domain:
        storeInfoWifi?.[FORM_NAMES.DOMAIN] ??
        EMPTY_CONSTS.STR,
      login:
        storeInfoWifi?.[FORM_NAMES.LOGIN] ??
        EMPTY_CONSTS.STR,
      protocol_choices:
        storeInfoWifi?.[FORM_NAMES.PROTOCOL_CHOICES] ??
        EMPTY_CONSTS.STR
    }
  }
}

/**
 * @info
 * Получить общие начальные не использующиеся в заполнении формы
 * если текущий вайфай в статусее подключен с ошибкой
 * то взять данные из стора
 * если подключен то взять данные из контекста
 */
export const getInfoValuesSettingsCurrentWifi = (
  contextInfoWifi: {
    [x: string]: any
    bssid: undefined
    is_password: any
  },
  storeInfoWifi: ICurrentWifi | null
): IInfoSettingWifiValues => {
  if (contextInfoWifi?.bssid !== undefined) {
    return {
      bssid:
        contextInfoWifi[FORM_NAMES.BSSID] ??
        EMPTY_CONSTS.STR,
      ssid:
        contextInfoWifi[FORM_NAMES.SSID] ??
        EMPTY_CONSTS.STR,
      signal_strength:
        contextInfoWifi[FORM_NAMES.SIGNAL_STRENGTH] ??
        EMPTY_CONSTS.STR,
      security:
        contextInfoWifi[FORM_NAMES.SECURITY] ??
        EMPTY_CONSTS.STR,
      is_connected: EMPTY_CONSTS.TRUE,
      is_password:
        contextInfoWifi.is_password ?? EMPTY_CONSTS.FALSE
    }
  } else {
    return {
      ssid:
        storeInfoWifi?.[FORM_NAMES.SSID] ??
        EMPTY_CONSTS.STR,
      bssid:
        storeInfoWifi?.[FORM_NAMES.BSSID] ??
        EMPTY_CONSTS.STR,
      signal_strength:
        storeInfoWifi?.[FORM_NAMES.SIGNAL_STRENGTH] ??
        EMPTY_CONSTS.STR,
      security:
        storeInfoWifi?.[FORM_NAMES.SECURITY] ??
        EMPTY_CONSTS.STR,
      is_connected: EMPTY_CONSTS.FALSE,
      is_password:
        storeInfoWifi?.is_password ?? EMPTY_CONSTS.FALSE
    }
  }
}

/**
 * Отчистит объект от полей чью значения === '' (пустой строке)
 */
export const cleanObjFromEmptyValues = <T>(
  obj:
  | ArrayLike<unknown>
  | IFormSettingWifiValues
  | { [s: string]: unknown }
  | IFormConnectToWifiValues
  | IFormAddNewWifiValues
): T => {
  const filteredValuesEntriess = Object.entries(obj).filter(
    ([key, value]) => value !== EMPTY_CONSTS.STR
  )
  const filteredValue = Object.fromEntries(
    filteredValuesEntriess
  ) as T
  return filteredValue
}
