import { IMainApi } from '@/api/interfaces'
import { IHistoryScan } from '@/api/interfaces/Equipment'
import { IStorageConfig, IStorageConfigSerialized } from '@/api/interfaces/Config'

import { storageConfigSerializer } from './serializers'

class ConfigApi {
  ctx: IMainApi
  constructor (ctx: IMainApi) {
    this.ctx = ctx
  }

  getHistoryScanList = async (): Promise<IHistoryScan[]> => {
    const res = await this.ctx.get<IHistoryScan[]>(
      '/config/history-card/'
    )
    return res.data
  }

  getStorageConfig = async (): Promise<IStorageConfigSerialized> => {
    const res = await this.ctx.get<IStorageConfig>(
      '/config/info/'
    )
    return storageConfigSerializer(res.data)
  }
}

export default ConfigApi
