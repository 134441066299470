import AbsractApi from '@/api/abstract'
import { IUserApi } from '@/api/interfaces'
import { ILogout } from '@/api/interfaces/Page'

class UserApi implements IUserApi {
  ctx: AbsractApi
  constructor (ctx: AbsractApi) {
    this.ctx = ctx
  }

  logout = async (): Promise<ILogout> => {
    const res = await this.ctx.post<ILogout>(
      '/logout_user/',
      {}
    )
    return res.data
  }
}

export default UserApi
