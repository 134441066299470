import { usePage as usePageCms } from '@garpix/cms'
import { IEquipment } from '@/interfaces/Device'

/**
 *  метод для получения текущего прибора ( ТСД )
 * ? актуально только для страниц из пайплайна сдачи оборудования
 * @returns IEquipment
 */
const useCurrentDevice = (): IEquipment => {
  const value = usePageCms()
  //! Всегда приходит массив с 1 элементом ( бекенд сказал что не может иначе )
  return value?.page?.init_state?.device
}

export default useCurrentDevice
