import BaseApi from '@garpix/base-api'
import { TLanguage } from '@/interfaces/Lang'
import { QueryParams } from '@garpix/base-api/types'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

type TRequest = <TRequest = unknown, TResponse = TRequest>(
  url: string,
  params?: QueryParams<TRequest>,
  axiosParams?: AxiosRequestConfig,
  failFetchCallback?: (err: any) => void
) => Promise<AxiosResponse<TResponse>>

abstract class AbsractApi extends BaseApi {
  language: TLanguage
  AUTH_TOKEN_KEY: string

  constructor (MAIN_URL: string, language: TLanguage) {
    super(MAIN_URL)
    this.language = language
    this.AUTH_TOKEN_KEY = 'auth_token_key'
  }

  getLanguage = (): string => this.language

  postWithErrorHandler: TRequest = (
    url,
    params,
    axiosParams,
    failFetchCallback
  ) => {
    try {
      return this.post(url, params ?? {}, axiosParams)
        .then((res) => res)
        .catch((err) => {
          if (failFetchCallback != null) {
            failFetchCallback(err)
          }
          return err
        })
    } catch (err) {
      return err
    }
  }

  getWithErrorHandler: TRequest = (
    url,
    params,
    axiosParams,
    failFetchCallback
  ) => {
    try {
      return this.get(url, params ?? {}, axiosParams)
        .then((res) => res)
        .catch((err) => {
          if (failFetchCallback != null) {
            failFetchCallback(err)
          }
          return err
        })
    } catch (err) {
      return err
    }
  }

  getSerialNumber = (): string => 'token'

  // errorHandler = (axios) => {
  //   axios.interceptors.response.use(
  //     (response: any) => {
  //       return response;
  //     },
  //     (error: {
  //       response: { data: any; status: number };
  //       message: any;
  //     }) => {
  //       debugger;
  //       // console.log('sddsdsdssdddsdssdds', error);
  //
  //       if (error.response && error.response.data) {
  //         if (error.response.status === STATUS_CODES._404) {
  //           return console.log('404');
  //         }
  //         if (error.response.status === STATUS_CODES._500) {
  //           return console.log('500');
  //         }
  //       }
  //       return Promise.reject(error.message);
  //     }
  //   );
  // };

  axiosOverride = (axios): any => {
    // this.errorHandler(axios);
    axios.defaults.headers.common['Accept-Language'] =
      this.getLanguage()
    // axios.defaults.headers.common['Accept-Serial-number'] =
    //   this.getSerialNumber()
    return axios
  }
}

export default AbsractApi
