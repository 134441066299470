import React, { FC } from 'react'
import style from './style/index.module.scss'
import { IFormConnectToWifiValues, IFormConnectToWifiErrors } from '@/interfaces/Hooks'
import { Input } from '../input'
import { IBlurInputEvent, IChangeInputEvent, IFocusInputEvent } from '@/interfaces/Common'

interface IProps {
  values: IFormConnectToWifiValues
  errors: IFormConnectToWifiErrors
  handleInput: (event: IChangeInputEvent) => void
  handleFocusInput: (event: IFocusInputEvent) => void
  handleBlurInput: (event: IBlurInputEvent) => void
}

export const FormStaticConnection: FC<IProps> = ({
  values,
  errors,
  handleInput,
  handleFocusInput,
  handleBlurInput
}) => {
  return (
    <div className={style.container}>
      <Input
        id='ip'
        name='ip'
        data-testid='ip'
        placeholder='255.255.255.255'
        value={values?.ip}
        error={Boolean(errors.ip)}
        errorMessage={errors.ip}
        label='IP - адрес'
        onChange={handleInput}
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        autoComplete='off'
      />
      <Input
        id='mask'
        name='mask'
        data-testid='mask'
        placeholder='255.255.255.255'
        value={values.mask}
        error={Boolean(errors.mask)}
        errorMessage={errors.mask}
        label='Маска сети'
        onChange={handleInput}
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        autoComplete='off'
      />
      <Input
        id='gateway'
        name='gateway'
        data-testid='gateway'
        placeholder='255.255.255.255'
        value={values.gateway}
        error={Boolean(errors.gateway)}
        errorMessage={errors.gateway}
        label='Шлюз'
        onChange={handleInput}
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        autoComplete='off'
      />
      <Input
        id='dns'
        name='dns'
        data-testid='dns'
        placeholder='255.255.255.255'
        value={values.dns}
        error={Boolean(errors.dns)}
        errorMessage={errors.dns}
        message='Не обязательное поле'
        label='DNS'
        onChange={handleInput}
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        autoComplete='off'
      />
    </div>
  )
}

export default FormStaticConnection
