import React, { FC } from 'react'
import classNames from 'classnames'
import { Typography } from '@/views/typography'
import { Switch } from '@/views/switch'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'

interface INetworkConnectContainer {
  isConnect: boolean
  toggleSwitch: () => void
  children: React.ReactNode
  label: string
}

const NetworkConnectContainer: FC<
INetworkConnectContainer
> = ({
  isConnect,
  toggleSwitch,
  children,
  label
}): React.ReactElement => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.container__disactive]: !isConnect
      })}
    >
      <CSSTransitionGroup
        animation='fadeInTop'
        className={styles.container__header}
      >
        <Typography
          size={60}
          weight={700}
          className={styles.container__title}
        >
          {label}
        </Typography>
        <Switch
          checked={isConnect}
          onChange={toggleSwitch}
        />
      </CSSTransitionGroup>
      {children}
    </div>
  )
}

export default NetworkConnectContainer
