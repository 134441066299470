import React from 'react'
import EmptyCells from '@/views/infoMessagePage/InfoMessagePage'
import { useSiteConfig } from '@/hooks'
import { DEFAULT_LINKS } from '@/const'

const SeizeFaultyType: React.FC =
  (): React.ReactElement => {
    const config = useSiteConfig()
    // const page = usePage()

    return (
      <EmptyCells
        success
        errorMessage='Все неисправное оборудование изъято'
        linkBack={undefined} // явно указал что б он использовал методы навигации по страницам назад
        linkToMain={config?.links?.verification ?? DEFAULT_LINKS.verification}
      />
    )
  }

export default SeizeFaultyType
