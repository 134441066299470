import React, { FC } from 'react'
import { Typography } from '@/views/typography'
import classNames from 'classnames'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import style from './style/index.module.scss'

interface IHeaderCurrentCell {
  cellNumber: number
  title: string
  equipmentTitle: string
  error?: boolean
  isTemporary?: boolean
}

const HeaderCurrentCell: FC<IHeaderCurrentCell> = ({
  cellNumber,
  title,
  equipmentTitle,
  error = false,
  isTemporary
}) => {
  return (
    <CSSTransitionGroup
      animation='fadeInTop'
      className={style.header}
    >
      <Typography
        size={48}
        className={classNames(style.header__num, {
          [style.header__num_error]: error,
          [style.header__num_temporary]: isTemporary
        })}
      >
        {cellNumber}
      </Typography>
      <div>
        <Typography
          size={46}
          weight={700}
          className={style.header__label}
        >
          {title}
        </Typography>
        <Typography size={36} color='gray'>
          {equipmentTitle}
        </Typography>
      </div>
    </CSSTransitionGroup>
  )
}

export default HeaderCurrentCell
