import React from 'react'

import { MainLayout } from '@/components/mainLayout/MainLayout'
import { Spinner } from '.'
/**
 * * Спинер с шапкой для выхода из приложения
 * @view
 */
const SpinerWithMainLayout = (): React.ReactElement => {
  return (
    <MainLayout visibleLinkBack={false}>
      <Spinner isFullPage />
    </MainLayout>
  )
}

export default SpinerWithMainLayout
