import AbsractApi from '@/api/abstract'
import { IExperemental } from '@/api/interfaces'
import { IEquipmentTypesList } from '@/api/interfaces/Equipment'

class Experemental implements IExperemental {
  ctx: AbsractApi
  constructor (ctx: AbsractApi) {
    this.ctx = ctx
  }

  scanNFC = async (params: {
    hid_number: string
  }): Promise<void> => {
    await this.ctx.post<IEquipmentTypesList>(
      '/controller/scan_nfc/',
      params
    )
  }
}

export default Experemental
