import { makeAutoObservable } from 'mobx'
import { RootStore } from './Root'
import { THEME_MODAL } from '@/const'

export class ModalStore {
  variant: string | null
  rootStore: RootStore
  modalProps: any
  isOpen: boolean

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.isOpen = false
    this.variant = null // это вариант контента
    this.modalProps = {
      theme: THEME_MODAL.DEFAULT // это пресет со стилями для саммой модалки
    }
    makeAutoObservable(this)
  }

  get theme (): string {
    return this.modalProps.theme
  }

  get currentVariant (): string | null {
    return this.variant
  }

  setTheme = (theme: string): string => {
    this.modalProps.theme = theme
    return this.modalProps.theme
  }

  open = (name: string): void => {
    this.variant = name
    this.isOpen = true
  }

  close = (): void => {
    this.variant = null
    this.isOpen = false
  }
}
