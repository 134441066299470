import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import useObjectPage from '../../hooks/useObjectPage'
import { Typography } from '@/views/typography'
import { LogoIcon } from '@/assets/icon'
import { infoErrors } from '@/pages/error/const'
import styles from './style/index.module.scss'

const Error: React.FC = (): React.ReactElement => {
  const { error } = useObjectPage()
  const info = infoErrors[error]
  return (
    <div className={styles.container}>
      <div className={styles.container__content}>
        <div className={styles.container__info}>
          <GxIcon
            src={LogoIcon}
            className={styles.container__logo}
          />
          <Typography
            size={80}
            className={styles.container__title}
            weight={700}
          >
            {info?.title ?? 'ERROR CONTEXT'}
          </Typography>
          <Typography
            size={50}
            weight={400}
            className={styles.container__desc}
          >
            {info?.description ?? 'ERROR CONTEXT'}
          </Typography>
        </div>
        <Typography
          size={100}
          className={styles.container__error}
        >
          {error}
        </Typography>
      </div>
    </div>
  )
}

export default Error
