import React from 'react'

import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import { Typography } from '@/views/typography'
import Row from '../Row'

import { IStorageConfigSerialized } from '@/api/interfaces/Config'

import styles from '../styles/index.module.scss'

const workModeFields = [
  {
    label: 'Резервирование ячеек',
    key: 'cellReservation'
  },
  {
    label: 'Возможность сдать временно',
    key: 'handOver'
  },
  {
    label: 'Режим оборудования при получении',
    key: 'userSelectionMode'
  },
  {
    label: 'Выбор ячейки при получении',
    key: 'equipmentSelectionMode'
  }
] as const

const softwareVersion = [
  {
    label: 'Бэкенд',
    key: 'lockerBack'
  },
  {
    label: 'Сервис бэкенд',
    key: 'lockerService'
  },
  {
    label: 'Фронтенд',
    key: 'lockerLk'
  }
] as const

interface IProps {
  storageConfig: IStorageConfigSerialized
}
/**
 * @view
 * * вьюха с главными данными о шкафе
 * @param storageConfig
 */
const Core: React.FC<IProps> = ({ storageConfig }) => {
  return (
    <div className={styles.container}>
      <CSSTransitionGroup
        component={Typography}
        animation='fadeInTop'
        size={60}
        weight={700}
        className={styles.container__title}
      >
        Информация об устройстве
      </CSSTransitionGroup>
      <CSSTransitionGroup
        className={styles.container__main}
      >
        <div className={styles.container__content}>
          <Row
            label='Серийный номер'
            value={storageConfig.serialNumber}
          />
          <Typography
            size={40}
            weight={500}
            className={styles.container__content_title}
          >
            Режим работы:
          </Typography>
          <div className={styles.container__content_list}>
            {workModeFields.map(({ label, key }) => (
              <Row
                key={key}
                label={label}
                value={storageConfig[key]}
              />
            ))}
          </div>
          <Typography
            size={40}
            weight={500}
            className={styles.container__content_title}
          >
            Версия ПО:
          </Typography>
          <div className={styles.container__content_list}>
            {softwareVersion.map(({ label, key }) => (
              <Row
                key={key}
                label={label}
                value={storageConfig[key]}
              />
            ))}
          </div>
        </div>
      </CSSTransitionGroup>
    </div>
  )
}

export default Core
