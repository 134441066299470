import React, {
  FC,
  useCallback,
  useEffect,
  useState
} from 'react'
import useSWR from 'swr'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@/views/typography'
import { useCurrentUser, useSiteConfig, useStores } from '@/hooks'
import { GxSpinner } from '@garpix/garpix-web-components-react'
import { IContentType } from './interfaces'
import { CONTENT_TYPES } from './const'
import ErrorPipline from '@/components/ErrorPipline'
import { IEquipment } from '@/interfaces/Device'
import InfoMessagePage from '@/views/infoMessagePage/InfoMessagePage'
import DescriptionProblem from '@/pages/descriptionProblem/DescriptionProblem'
import { IProblemList } from '@/api/interfaces/Equipment'
import Paginator from '../paginator/Paginator'
import { IProblem } from '@/interfaces/Problem'
import { CELL, DEFAULT_LINKS, USER_ROLES } from '@/const'
import { objToQs } from '@/utils'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'
import { TWorkMode } from '@/interfaces/ObjectPage'

interface TProp {
  device: IEquipment
  setisShowCardInfo: (V: boolean) => void
}

type TSendProblemm = (T: {
  id: number
  desc?: string
}) => void

const ProblemsComponent: FC<TProp> = ({
  setisShowCardInfo
}): React.ReactElement => {
  const store = useStores()
  const config = useSiteConfig()
  const { role } = useCurrentUser()
  const [isError, setisError] = useState(false)
  const [options, setOptions] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0
  }) // параметры для пагинации

  const qs = {
    cell_status: CELL.STATUS.FREE
  }

  const [isNeedDescript, setIsNeedDescript] =
    useState(false)
  const [description, setDescription] = useState('')
  const [selectedProblem, setselectedProblem] =
    useState<IProblem>()
  const fetchData = useSWR(
    { page: options.currentPage },
    store.api.problem.getProblems
  )
  const navigate = useNavigate()
  const { data, isLoading } = fetchData as {
    data: IProblemList
    isLoading: boolean
  }

  const linkCellList = `${config?.links?.hand_over_in_cell ?? DEFAULT_LINKS.hand_over_in_cell}${objToQs(qs)}`

  /**
   * функция для изменения страниц
   */
  const changePagination = (pageNum: number): void => {
    setOptions({
      ...options,
      currentPage: pageNum
    })
  }

  /**
   * обработчик отправки проблеммы через дескрипшен
   */
  const sendDescriptionProblem = (): void => {
    if (selectedProblem === undefined) {
      setisError(true)
      return
    }
    sendProblem({
      id: selectedProblem.id,
      desc: description
    })
  }

  /**
 * @info отправляет запрос на открытие рандомной ячейки
 * resolve - в ответе ничего не приходит
 * reject - пробросить выше
 * Бэк по сокетам присылает ссылку для редиректа
 * Редирект происходит автоматом реакцией на message
 */
  const openRandomCell = (): void => {
    store.api.cell.openRandomCell().catch(err => {
      throw new Error(err)
    })
  }

  /**
   *
   * @param param0 id - problem
   * @param param0 desc - description problemm string
   */
  const sendProblem: TSendProblemm = ({
    id,
    desc
  }): void => {
    void store.api.problem
      .setProblemForEquip({
        problem: id,
        description: desc
      })
      .then((res) => {
        if (role === USER_ROLES.workman && config?.work_mode === TWorkMode.Random) {
          openRandomCell()
        } else {
          navigate(linkCellList)
        }
      })
      .catch(() => {
        setisShowCardInfo(true)
        setIsNeedDescript(false)
        setisError(true)
      })
  }

  /**
   * Событие клика по проблемме
   * ? мемоизированно
   * ? предварительно спросит текущий прибор что б прикрепить к нему проблемму
   * ! вынести наобсуждение другую реализацию ( "контексты" )
   */
  const handleClickProblem = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement> & {
        target: { id: number }
      }
    ): void => {
      const id = e.target.id
      const currentProblem = data.results.find(
        (el) => el.id === Number(id)
      )
      // файнд может вернут undefined по этому проверка необходима
      if (currentProblem === undefined) return
      // если для этой проблеммы обязательно описание
      // то смени статус для отображения окна с вводом дескрипшина
      if (currentProblem.is_required_description) {
        setisShowCardInfo(false) // скрыть блок с тайтлом девайса
        setIsNeedDescript(true) // показать блок с вводом дескрипшена
        setselectedProblem(currentProblem) // записать выбранную проблемму
      } else {
        sendProblem({ id })
      }
    },
    [data?.results]
  )
  /**
   * объект со всеми возможным контентом от разных состояний
   */
  const contentBody: IContentType = {
    loading: (
      <GxSpinner
        className={styles.container__spinner}
        size='large'
      />
    ),
    error: null,
    empty: (
      <InfoMessagePage
        linkBack={config?.links?.condition_equipment ?? DEFAULT_LINKS.condition_equipment}
        errorMessage='Проблемы не обнаруженны!'
        linkToMain={config.links.verification}
      />
    ),
    list: (
      <>
        <CSSTransitionGroup
          className={styles.container__list}
        >
          {Array.isArray(data?.results)
            ? data.results.map((elem) => {
              return (
                <button
                  key={elem.id}
                  id={String(elem.id)}
                  onClick={handleClickProblem}
                  className={styles.container__card}
                >
                  <Typography
                    size={40}
                    weight={500}
                    className={
                      styles.container__card_title
                    }
                  >
                    {elem.title}
                  </Typography>
                </button>
              )
            })
            : null}
        </CSSTransitionGroup>
        <CSSTransitionGroup
          className={styles.pagination__container}
        >
          <Paginator
            {...options}
            changePage={changePagination}
          />
        </CSSTransitionGroup>
      </>
    )
  }

  /**
   * коллектор для контента реагирующий на состояние нескольких параметров
   * @returns JSX.Element | null
   */
  const getContent = (): JSX.Element | null => {
    if (isLoading) {
      return contentBody[CONTENT_TYPES.loading]
    }
    if (isError || data === undefined) {
      return contentBody[CONTENT_TYPES.error]
    }
    if (data?.results.length === 0 && !isLoading) {
      return contentBody[CONTENT_TYPES.empty]
    }
    if (data !== undefined && Array.isArray(data.results)) {
      return contentBody[CONTENT_TYPES.list]
    }
    return null
  }

  /**
   * сохраняем общее кол-во элементов, которое пришло с бэка в стейт
   */
  useEffect(() => {
    setOptions({
      ...options,
      total: data?.count ?? 0
    })
  }, [data])

  if (isNeedDescript) {
    return (
      <DescriptionProblem
        description={description}
        reqiured={selectedProblem?.is_required_description}
        sendDescriptionProblem={sendDescriptionProblem}
        setDescription={setDescription}
      />
    )
  }

  return (
    <>
      {isError
        ? (
          <ErrorPipline
            linkBack='/'
            errorMessage='Возникла проблема привязки проблемы к оборудованию, попробуйте сначала!'
          />
          )
        : (
          <>
            <div className={styles.container}>
              <CSSTransitionGroup
                animation='fadeInTop'
                component={Typography}
                size={60}
                weight={700}
                className={styles.container__title}
              >
                Укажите проблему
              </CSSTransitionGroup>
              {getContent()}
            </div>
          </>
          )}
    </>
  )
}

export default ProblemsComponent
