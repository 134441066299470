import React, { FC } from 'react'
import { Typography } from '@/views/typography'
import styles from './style/index.module.scss'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { CheckIcon, ErrorClose } from '@/assets/icon'
import classNames from 'classnames'
import { Button } from '../button'

interface TInfoContent {
  message: string
  handlerClickBtn: () => void
  success?: boolean
  typeName?: string
  description?: string
}

const InfoContent: FC<TInfoContent> = ({
  message,
  handlerClickBtn,
  success = false,
  typeName,
  description
}) => {
  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.container__content, {
          [styles.container__content_subtitle]: typeName
        })}
      >
        <div
          className={classNames(styles.icon__container, {
            [styles.icon__container_success]: success
          })}
        >
          <GxIcon
            src={success ? CheckIcon : ErrorClose}
            className={classNames(styles.icon, {
              [styles.icon_success]: success
            })}
          />
        </div>
        <Typography
          size={80}
          weight={700}
          className={styles.container__title}
        >
          {message}
        </Typography>
        {Boolean(description) && (
          <Typography
            size={50}
            weight={400}
            className={styles.container__description}
          >
            {description}
          </Typography>
        )}

        {Boolean(typeName) && (
          <Typography
            size={60}
            weight={400}
            className={styles.container__subtitle}
          >
            {typeName}
          </Typography>
        )}
        <Button
          className={styles.container__button}
          onClick={handlerClickBtn}
        >
          Вернуться в меню
        </Button>
      </div>
    </div>
  )
}

export default InfoContent
