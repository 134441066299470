import React, { FC } from 'react'
import { Typography } from '@/views/typography'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'

interface TProp {
  name: string
  equipmentType: string
}

const CardInfo: FC<TProp> = ({ name, equipmentType }) => {
  return (
    <CSSTransitionGroup
      animation='fadeInTop'
      className={styles.container}
    >
      <Typography size={46}>{equipmentType}</Typography>
      <Typography size={36} weight={400} color='gray'>
        {name}
      </Typography>
    </CSSTransitionGroup>
  )
}

export default CardInfo
