import React, { FC } from 'react'
import { Typography } from '@/views/typography'
import { GxIcon } from '@garpix/garpix-web-components-react'
import {
  GetEquipmentIcon,
  ReturnEquipmentIcon
} from '@/assets/icon'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'
import { Button } from '../button'

interface TOptionsEquipmentComponent {
  titleTop: string
  titleBottom: string
  handleClickBtnTop: () => void
  handleClickBtnBottom: () => void
}
const OptionsEquipmentComponent: FC<
TOptionsEquipmentComponent
> = ({
  titleTop,
  titleBottom,
  handleClickBtnTop,
  handleClickBtnBottom
}) => {
  return (
    <CSSTransitionGroup className={styles.container}>
      <Button
        isShadow={false}
        color='white'
        className={styles.container__button}
        onClick={handleClickBtnTop}
      >
        <>
          <GxIcon
            src={GetEquipmentIcon}
            className={styles.container__button_icon}
          />
          <Typography
            size={60}
            className={styles.container__button_title}
          >
            {titleTop}
          </Typography>
        </>
      </Button>

      <Button
        isShadow={false}
        color='white'
        className={styles.container__button}
        onClick={handleClickBtnBottom}
      >
        <>
          <GxIcon
            src={ReturnEquipmentIcon}
            className={styles.container__button_icon}
          />
          <Typography
            size={60}
            className={styles.container__button_title}
          >
            {titleBottom}
          </Typography>
        </>
      </Button>
    </CSSTransitionGroup>
  )
}

export default OptionsEquipmentComponent
