import React from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import { usePage, useSiteConfig, useStores } from '@/hooks'
import { NavLink } from 'react-router-dom'
import { Typography } from '@/views/typography'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { ArrowBackIcon } from '@/assets/icon'
import { DEFAULT_LINKS } from '@/const'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'
import { getSubtitleLanStatus, getSubtitleWifiStatus } from '@/utils'

const NetworkConfiguration: React.FC =
  (): React.ReactElement => {
    const config = useSiteConfig()
    const { currentWifiStore: { currentWifi } } = useStores()
    const page = usePage()

    const options = [
      {
        title: 'Wi-Fi',
        subtitle: getSubtitleWifiStatus({
          wifiModuleStatus: page?.init_state?.connected_wifi,
          connectedWifiSsid: page?.init_state?.connected_ssid,
          currentWifiStoreStatus: currentWifi?.status
        }),
        url:
          config?.links?.settings_wifi ??
          DEFAULT_LINKS.settings_wifi
      },
      {
        title: 'LAN',
        subtitle: getSubtitleLanStatus({
          connectedWifiSsid: page?.init_state?.connected_ssid,
          lanStatus: page?.init_state?.connected_lan
        }),
        url:
          config?.links?.lan_settings ??
          DEFAULT_LINKS.lan_settings
      }
    ]

    /**
     * подписка за нотифайками с бэка
     * если находясь на этом экране
     * отключился LAN или WIFI
     */
    // useEffect(() => {

    // }, [])

    return (
      <MainLayout linkBack={config?.links?.main_menu_net ?? DEFAULT_LINKS.main_menu_net}>
        <div className={styles.container}>
          <CSSTransitionGroup
            animation='fadeInTop'
            component={Typography}
            size={60}
            weight={700}
            className={styles.container__title}
          >
            Настройки сети
          </CSSTransitionGroup>
          {options.map(
            ({ title, subtitle, url }, index) => {
              return (
                <CSSTransitionGroup
                  component={NavLink}
                  animation='fadeInLeft'
                  key={index}
                  className={styles.item}
                  to={url}
                >
                  <Typography
                    size={60}
                    weight={500}
                    className={styles.item__label}
                  >
                    {title}
                  </Typography>
                  <div className={styles.item__right}>
                    <Typography
                      size={30}
                      weight={500}
                      className={styles.item__subtitle}
                    >
                      {subtitle}
                    </Typography>
                    <GxIcon
                      src={ArrowBackIcon}
                      className={styles.item__icon}
                    />
                  </div>
                </CSSTransitionGroup>
              )
            }
          )}
        </div>
      </MainLayout>
    )
  }

export default NetworkConfiguration
