import {
  IEquipmentApi,
  IMainApi,
  TQPAllEquipTypes
} from '@/api/interfaces'
import {
  IEquipmentList,
  IEquipmentTypesList,
  IQp
} from '@/api/interfaces/Equipment'
import { IDevice } from '@/interfaces/Device'
import { equipListSerializer } from './serializers'

class EquipmentApi implements IEquipmentApi {
  ctx: IMainApi
  constructor (ctx: IMainApi) {
    this.ctx = ctx
  }

  getCurrentDevice = async (
    params = {}
  ): Promise<IDevice> => {
    const res = await this.ctx.get<IDevice>(
      '/equipment-current/',
      params
    )
    return res.data
  }

  getEquipmentList = async (
    options: IQp
  ): Promise<IEquipmentList> => {
    const res =
      await this.ctx.getWithErrorHandler<IEquipmentList>(
        '/cell/',
        options,
        undefined,
        this.ctx.errorHandlers.handleErrorRedirect
      )
    const data = equipListSerializer(res.data)
    return data
  }

  getAllEquipTypes = async (
    options: TQPAllEquipTypes
  ): Promise<IEquipmentTypesList> => {
    const res =
      await this.ctx.getWithErrorHandler<IEquipmentTypesList>(
        '/equipment-type/',
        options,
        undefined,
        this.ctx.errorHandlers.handleErrorRedirect
      )
    return res.data
  }

  setFixedEqip = async (
    id: number,
    params: { is_resolved: boolean }
  ): Promise<unknown> => {
    const res = await this.ctx.post<IEquipmentTypesList>(
      `/equipment-problem-user/state_equipment/${id}/`,
      params
    )
    return res.data
  }

  setTempSurender = async (
    params: any
  ): Promise<unknown> => {
    const res = await this.ctx.post<IEquipmentTypesList>(
      '/cell/temporary_surrender/',
      params
    )
    return res.data
  }
}

export default EquipmentApi
