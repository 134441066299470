import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePage, useSiteConfig } from '@/hooks' // usePage
import MainLayout from '@/components/mainLayout/MainLayout'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import style from './styles/index.module.scss'
import InfoContent from '@/views/infoContent'
import { DEFAULT_LINKS, ERROR_MAIN_TEXT } from '@/const'

interface IErrorMessage {
  message: string
  description: string
  btnHandler: () => void
}

const SelectAnotherCell: FC = () => {
  const navigate = useNavigate()
  const page = usePage()

  const config = useSiteConfig()

  const [errorMessage, setErrorMessage] = useState<null | IErrorMessage>(null)

  const clearErrorMessage = (): void => {
    setErrorMessage(null)
  }

  const linkBack = config?.links?.verification ?? DEFAULT_LINKS.verification

  const createErrorMessage = (
    message: string,
    description: string,
    btnHandler: () => void
  ): void => {
    setErrorMessage({
      message,
      description,
      btnHandler
    })
  }

  /**
   * обработчик нажатий на кнопку вовзращения в меню
   */
  const handleClickBackToMenu = (): void => {
    navigate(linkBack)
  }

  /**
   * обработчик нажатий на кнопку для последущего редиректа -
   * на страницу выбора ячейки
   */
  const handleClickSelectNextCell = (): void => {
    if (page?.init_state?.is_redirect === false) {
      createErrorMessage(
        ERROR_MAIN_TEXT.NO_AVALIABLE_EQUIPMENT,
        '',
        clearErrorMessage
      )
    } else {
      navigate(
        page?.init_state?.url_still !== undefined
          ? page?.init_state?.url_still
          : linkBack
      )
    }
  }

  return (
    <MainLayout linkBack={linkBack}>
      <div className={style.content__container}>
        {
          errorMessage !== null
            ? <InfoContent
                message={errorMessage.message}
                handlerClickBtn={errorMessage.btnHandler}
                description={errorMessage.description}
              />
            : (
              <>
                <CSSTransitionGroup
                  animation='fadeInTop'
                  component={Typography}
                  size={80}
                  className={style.content__container_title}
                >
                  Выбрать еще одну ячейку?
                </CSSTransitionGroup>
                <CSSTransitionGroup
                  component={Button}
                  onClick={handleClickSelectNextCell}
                >
                  Выбрать ячейку
                </CSSTransitionGroup>
                <CSSTransitionGroup
                  component={Button}
                  color='white'
                  onClick={handleClickBackToMenu}
                >
                  Вернуться в меню
                </CSSTransitionGroup>
              </>
              )
        }
      </div>
    </MainLayout>
  )
}

export default SelectAnotherCell
