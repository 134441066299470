import { useLocation } from 'react-router-dom'
import { getLocaleByPathname } from '@/utils'

const useLocale = (): {locale: string} => {
  const location = useLocation()
  const { pathname } = location
  const locale = getLocaleByPathname(pathname)
  return { locale }
}

export default useLocale
