import React, { FC } from 'react'
import { Typography } from '@/views/typography'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { RestartIcon } from '@/assets/icon'
import { Button } from '@/views/button'
import styles from '../style/index.module.scss'

interface TProps {
  onClose: () => void
  onReboot: () => void
}

export const RebootModal: FC<TProps> = ({ onReboot, onClose }) => {
  return (
    <>
      <GxIcon src={RestartIcon} className={styles.icon} />
      <Typography
        size={60}
        weight={500}
        className={styles.container__title}
      >
        Шкаф будет перезагружен
      </Typography>
      <div className={styles.container__buttons}>
        <Button
          onClick={onReboot}
        >
          Перезагрузить
        </Button>
        <Button
          onClick={onClose}
          color='white'
          lightShadow
        >
          Отмена
        </Button>
      </div>
    </>
  )
}
