import React, { FC } from 'react'
import FormInput from '@/components/formInput'
import { FORM_NAMES } from '@/utils/formNames'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import style from './style/index.module.scss'
import { IFocusInputEvent } from '@/interfaces/Common'

interface TFormStaticConnection {
  disabledInputs?: Record<string, boolean>
  onFocusInput?: (event: IFocusInputEvent) => void
}

export const FormStaticConnection: FC<
TFormStaticConnection
> = ({ disabledInputs, ...props }) => {
  return (
    <CSSTransitionGroup className={style.container}>
      <FormInput
        name={FORM_NAMES.IP}
        label='IP - адрес'
        disabled={disabledInputs?.[FORM_NAMES.IP]}
        {...props}
      />
      <FormInput
        name={FORM_NAMES.MASK}
        label='Маска сети'
        disabled={disabledInputs?.[FORM_NAMES.MASK]}
        {...props}
      />
      <FormInput
        name={FORM_NAMES.GATEWAY}
        label='Шлюз'
        disabled={disabledInputs?.[FORM_NAMES.GATEWAY]}
        {...props}
      />
      <FormInput
        name={FORM_NAMES.DNS}
        message='Не обязательное поле'
        label='DNS'
        disabled={disabledInputs?.[FORM_NAMES.DNS]}
        {...props}
      />
    </CSSTransitionGroup>
  )
}
