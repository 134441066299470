import React, { FC, useEffect, useState } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import OptionEquipmentAdmin from '@/views/optionEquipmentAdmin/OptionEquipmentAdmin'
import { useSiteConfig, useStores } from '@/hooks'
import useSWR from 'swr'
import InfoMessagePage from '@/views/infoMessagePage/InfoMessagePage'
import { CELL, DEFAULT_LINKS } from '@/const'

const OptionsFaultyEquipmentType: FC = () => {
  const store = useStores()
  const config = useSiteConfig()
  const [options, setOptions] = useState({
    currentPage: 1,
    pageSize: 20,
    total: 0
  }) // параметры для пагинации

  const { data, isLoading } = useSWR(
    {
      page: options.currentPage
      // cell_status: ''
    },
    store.api.equipment.getAllEquipTypes
  )

  // /**
  //  * функция для изменения страниц
  //  */
  // const changeNumber = (pageNum: number): void => {
  //   setOptions({
  //     ...options,
  //     currentPage: pageNum
  //   })
  // }

  /**
   * сохраняем общее кол-во элементов, которое пришло с бэка в стейт
   */
  useEffect(() => {
    setOptions({
      ...options,
      total: data?.total ?? 0
    })
  }, [data?.total])

  console.log('data', data)
  if (
    (data?.results == null && !isLoading) ||
    data?.results.length === 0
  ) {
    return (
      <InfoMessagePage
        linkToMain={config?.links?.verification ?? DEFAULT_LINKS.verification}
        linkBack={config?.links?.verification ?? DEFAULT_LINKS.verification}
        errorMessage='У вас нет доступных типов оборудования!'
      />
    )
  }

  const optionsList: any = data?.results.map(
    (el: { title: string, id: number }) => ({
      value: `?type_of_hardware_content=${el.title}&content_status=${CELL.CONTENT.NOT_WORKING}&equipments__equipment_type__id=${el.id}`,
      ...el
    })
  )

  const linkBack = config?.links?.faulty_equipment ?? DEFAULT_LINKS.faulty_equipment

  return (
    <MainLayout linkBack={linkBack}>
      <OptionEquipmentAdmin
        title='Выберите тип'
        option={optionsList as unknown as any[]}
      />
    </MainLayout>
  )
}

export default OptionsFaultyEquipmentType
