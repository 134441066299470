import React, { FC } from 'react'
import { useField } from 'formik'
import { Input } from '@/views/input'
import { IChangeInputEvent, IFormInput } from '@/interfaces/Common'

const FormInput: FC<IFormInput> = ({ name, onFocusInput, handleChange = () => { }, ...props }) => {
  const [, { value, error, touched }
    //  { setValue }
  ] =
    useField(name)

  const onChange = (e: IChangeInputEvent): void => {
    // setValue(e.target.value)
    handleChange(name, e.target.value)
  }

  return (
    <Input
      {...props}
      value={value}
      onFocus={onFocusInput}
      onChange={onChange}
      error={Boolean(touched && error)}
      errorMessage={error}
      name={name}
    />
  )
}

export default FormInput
