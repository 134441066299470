export interface KeyboardComponentType {
  onChange: (val: string) => void
  action: () => void
}

export enum TypeKeyboard {
  default = 'default',
  shift = 'shift',
  numeric = 'numeric',
  english = 'english',
  englishBig = 'englishBig'
}

export type EnumTypeKeyboard = keyof typeof TypeKeyboard
