import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { GarpixCMSProvider } from '@garpix/cms'
import Combine from './pages/Combine'
import { PATHS } from './const'
import {
  defaultLanguage,
  htmlLang,
  messages
} from './i18n'
import useLocale from './hooks/useLocale'
import { runtimeConfig } from './config'

const App = ({ context }: any): React.ReactElement => {
  const { locale } = useLocale()

  return (
    <GarpixCMSProvider
      isAutoGetSessionToken={false}
      baseUrl={String(runtimeConfig.API_URL)}
      language={htmlLang[locale]}
    >
      <IntlProvider
        messages={messages[locale]}
        locale={locale}
        defaultLocale={defaultLanguage}
      >
        <Routes>
          <Route
            path={PATHS.ALL.path}
            element={
              <Combine
                staticContext={context}
                {...PATHS.ALL}
              />
            }
          />
        </Routes>
      </IntlProvider>
    </GarpixCMSProvider>
  )
}

export default App
