import React, { FC } from 'react'
import SettingWifiForm from '@/views/Forms/SettingWifiForm'
import { Typography } from '@/views/typography'
import { WiFiSignal } from '@/views/wifiSignal'
import { AutoConnection } from '@/views/autoConnection'
import useSettingsCurentWifiForm from '@/hooks/useSettingsCurentWifiForm'
import { FORM_NAMES } from '@/utils/formNames'
import { KeyboardLayout } from '../keyboard/Keyboard'
import { AUTJORIZATION_METHODS } from '@/const'
import { log } from '@/utils'
import { IFormSettingWifiErrors, IFormSettingWifiValues } from '@/interfaces/Hooks'
import styles from './styles/index.module.scss'

const SettingsCurrentWifi: FC =
  (): React.ReactElement => {
    const afterSuccess = (formObj: IFormSettingWifiValues): void => {
      log('afterSuccess SettingCurrentWifi', 'info')
    }

    const afterError = (formObj: IFormSettingWifiErrors): void => {
      log('afterError SettingCurrentWifi', 'info')
    }

    const {
      values,
      infoValues,
      errors,
      isDinamicIP,
      isKeyBoard,
      keyboardValue,
      isLoading,
      isEnterprise,
      protocols,
      currentProtocol,
      handleChangeProtocol,
      changeIpType,
      handleSubmit,
      handleChange,
      handleSwitch,
      onKeyboardInput,
      onFocusInput,
      closeKeyboard,
      onBlurInput
    } = useSettingsCurentWifiForm({
      afterSuccess,
      afterError
    })

    return (
      <div className={styles.container} data-keyboard={isKeyBoard}>
        <Typography
          className={styles.container__title}
          size={60}
          weight={700}
        >
          {infoValues[FORM_NAMES.SSID]}
        </Typography>
        <div className={styles.wifi}>
          <WiFiSignal
            connect
            lock={infoValues.is_password}
            value={infoValues[FORM_NAMES.SIGNAL_STRENGTH]}
            fail={!infoValues.is_connected}
          />
          <Typography
            size={40}
            weight={500}
            className={styles.wifi__label}
          >
            {infoValues.is_connected
              ? 'Подключено'
              : 'Ошибка Подключения'}
          </Typography>
        </div>
        <div className={styles.container__item}>
          <Typography
            size={40}
            weight={700}
            className={styles.container__item_label}
          >
            Метод авторизации
          </Typography>
          <Typography
            size={40}
            weight={500}
            className={styles.container__item_label}
          >
            {AUTJORIZATION_METHODS[infoValues[FORM_NAMES.SECURITY]]}
          </Typography>
        </div>
        <AutoConnection
          value={values[FORM_NAMES.AUTOCONNECT]}
          handleSwitch={handleSwitch}
        />
        <KeyboardLayout
          onChange={onKeyboardInput}
          open={isKeyBoard}
          value={keyboardValue}
          handlecontinue={closeKeyboard}
        >
          <SettingWifiForm
            isEnterprise={isEnterprise}
            protocols={protocols}
            currentProtocol={currentProtocol}
            handleChangeProtocol={handleChangeProtocol}
            values={values}
            isLoading={isLoading}
            infoValues={infoValues}
            errors={errors}
            isDinamicIP={isDinamicIP}
            handleChangeIpType={changeIpType}
            handleSubmit={handleSubmit}
            handleInput={handleChange}
            onKeyboardInput={onKeyboardInput}
            handleFocusInput={onFocusInput}
            handleBlurInput={onBlurInput}
          />
        </KeyboardLayout>
      </div>
    )
  }

export default SettingsCurrentWifi
