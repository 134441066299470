import { useEffect, useState } from 'react'
import { useFormik, FormikHelpers } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useStores } from './useStores'
import useSiteConfig from './useSiteConfig'
import { ICurrentWifi } from '@/services/store/CurrentWifi'
import { cleanObjFromEmptyValues, getObjectErrorsFormik } from '@/utils'
import { addWifiFormSchem } from '@/utils/validation'
import { FORM_NAMES } from '@/utils/formNames'
import { IConnectToWifiEnterprise } from '@/api/interfaces/Network'
import {
  IAddNewWifiReturn,
  IFormAddNewWifiValues,
  TSelectProtocol
} from '@/interfaces/Hooks'
import {
  AUTJORIZATION_METHODS,
  DEFAULT_LINKS,
  EMPTY_CONSTS,
  IP_TYPE,
  SIMPLE_STATUSES
} from '../const'
import { IBlurInputEvent, IFocusInputEvent } from '@/interfaces/Common'

/**
 *? CONSTS
 */
export const initialValues = {
  ssid: EMPTY_CONSTS.STR,
  password: EMPTY_CONSTS.STR,
  security: AUTJORIZATION_METHODS['Открытая'],
  autoconnect: EMPTY_CONSTS.TRUE,
  ip: EMPTY_CONSTS.STR,
  mask: EMPTY_CONSTS.STR,
  gateway: EMPTY_CONSTS.STR,
  dns: EMPTY_CONSTS.STR,
  login: EMPTY_CONSTS.STR,
  domain: EMPTY_CONSTS.STR,
  protocol_choices: EMPTY_CONSTS.STR
}

export const securitiOptions = [
  {
    value: AUTJORIZATION_METHODS['Открытая'],
    label: AUTJORIZATION_METHODS['Открытая']
  },
  {
    value: AUTJORIZATION_METHODS['WPA2-Personal'],
    label: AUTJORIZATION_METHODS['WPA2-Personal']
  },
  {
    value: AUTJORIZATION_METHODS['WPA2-Enterprise'],
    label: AUTJORIZATION_METHODS['WPA2-Enterprise']
  }
]

/**
 *? Hook to use AddNewWifi Form
 */
const useAddNewWifiForm = (): IAddNewWifiReturn => {
  const { api } = useStores()
  const config = useSiteConfig()
  const { currentWifiStore } = useStores()
  const navigate = useNavigate()
  // const { showSpinner, hideSpinner } = useGlobalSpinner()
  const [isLoading, setIsLoading] = useState(
    EMPTY_CONSTS.FALSE
  )
  const [isError, setIsError] = useState(
    EMPTY_CONSTS.FALSE
  )
  const [isKeyBoard, setIsKeybard] = useState(
    EMPTY_CONSTS.FALSE
  )
  const [keyboardValue, setKeyboardValue] = useState<string | null>(null)
  const [keyboardFild, setKeyboardField] = useState(
    EMPTY_CONSTS.STR
  )
  const [isDinamicIP, setIsDinamicIP] = useState(
    EMPTY_CONSTS.TRUE
  ) // ip variant Static/Dinamic

  const [protocols, setProtocols] = useState<TSelectProtocol[]>([])
  const [currentProtocol, setProtocol] = useState<TSelectProtocol>()

  /**
   * redirect to page with Wifi lists
   */
  const navigateToWifiList = (): void => {
    navigate(
      config?.links?.settings_wifi ??
        DEFAULT_LINKS.settings_wifi
    )
  }

  /**
     * закрыт клавиатуру
     */
  const closeKeyboard = (): void => {
    setIsKeybard(false)
  }

  /**
   * Handler submit Form
   * filtering values to empty value
   * setting loader
   * and processing respond
   */
  const onSubmit = (
    values: IFormAddNewWifiValues,
    {
      setSubmitting,
      setErrors
    }: FormikHelpers<IFormAddNewWifiValues>
  ): void => {
    setIsLoading(EMPTY_CONSTS.TRUE)
    closeKeyboard()
    /** @note фильтрация значений (удалание пустых) */
    const filteredValuesObj = cleanObjFromEmptyValues<IFormAddNewWifiValues>(values)
    const connectedWifiData = {
      ...filteredValuesObj,
      connection_type: isDinamicIP
        ? IP_TYPE.DYNAMIC
        : IP_TYPE.STATIC
    }
    let promise
    if (isEnterprise) {
      promise = api.network
        .connectToWiFiEnterprise(connectedWifiData as IConnectToWifiEnterprise)
    } else {
      promise = api.network
        .connectToWiFi(connectedWifiData)
    }
    promise.then((data) => {
      const newCurrentWifi = {
        ...filteredValuesObj,
        status: SIMPLE_STATUSES.LOADING
      }
      currentWifiStore.set(
        newCurrentWifi as unknown as ICurrentWifi
      )
      navigateToWifiList()
    })
      .catch((e) => {
        if (
          e.response?.data?.error?.length >
        EMPTY_CONSTS.ZERO
        ) {
          const errors = getObjectErrorsFormik(e)
          setErrors(errors)
        } else {
          setIsError(EMPTY_CONSTS.TRUE)
        }
      })
      .finally(() => {
        setIsLoading(EMPTY_CONSTS.FALSE)
        setSubmitting(EMPTY_CONSTS.FALSE)
      })
  }

  /**
   * Handler Event onFocus Input
   */
  const onFocusInput = (
    event: IFocusInputEvent
  ): void => {
    setKeyboardValue(event.target.value)
    setKeyboardField(event.target.name)
    setIsKeybard(EMPTY_CONSTS.TRUE)
  }

  /**
   * Handler Event onBlur Input
   */
  const onBlurInput = (
    event: IBlurInputEvent
  ): void => {
    // setIsKeybard(false)
  }

  /**
   * Handler Event onInput from Virtual Keyboard Input
   */
  const onKeyboardInput = (input: string): void => {
    void handleInput(input)
  }

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    values,
    errors
    // ...formikData
  } = useFormik<IFormAddNewWifiValues>({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: () =>
      addWifiFormSchem(
        isDinamicIP,
        isPassword,
        isEnterprise
      ),
    validateOnMount: EMPTY_CONSTS.FALSE,
    validateOnChange: EMPTY_CONSTS.FALSE,
    validateOnBlur: EMPTY_CONSTS.TRUE
  })

  /**
   * Handler change Tab Value (Static/Dinamic) in form
   */
  const changeIpType = (value: boolean): void => {
    setIsDinamicIP(value)
  }

  /**
   * Simple Handler input Value and set to form values
   */
  const handleInput = (value: string): void => {
    setKeyboardValue(value)
    void setFieldValue(keyboardFild, value)
  }

  /**
   * Handler change Select Value and set to form values
   */
  const handleSelectChange = (selectedOption: {
    label: string
    value: string
  }): void => {
    const isEnterprise =
      selectedOption?.value ===
      AUTJORIZATION_METHODS['WPA2-Enterprise']

    if ((protocols.length > 0) && isEnterprise) {
      setProtocol(protocols[0])
    }

    const enterpriseCleanFields = {
      login: EMPTY_CONSTS.STR,
      domain: EMPTY_CONSTS.STR,
      protocol_choices: isEnterprise
        ? protocols[0].value
        : EMPTY_CONSTS.STR
    }

    void setValues({
      ...values,
      ...enterpriseCleanFields,
      security: selectedOption.value,
      password: EMPTY_CONSTS.STR
    })
  }

  /**
   * Handler change Switch Value and set to form values
   */
  const handleSwith = (): void => {
    void setFieldValue(
      'autoconnect',
      !values.autoconnect
    )
  }

  /**
   * current option of security select
   */
  const selectedValue = securitiOptions.find(
    (option) => option.value === values.security
  )
  const securityValue =
    selectedValue !== EMPTY_CONSTS.NULL &&
    selectedValue !== EMPTY_CONSTS.UNDEFINED
      ? selectedValue
      : securitiOptions[0]

  /**
   * If wifi security is not 'Открытая' === Not Password
   */
  const isPassword =
  values.security !== AUTJORIZATION_METHODS['Открытая']

  /**
 * If wifi security is 'Enterprise' === not domain & login & protocol
 */
  const isEnterprise =
  values.security ===
  AUTJORIZATION_METHODS['WPA2-Enterprise']

  /**
   * Clear all field for Staic IP to init values
   */
  const clearStaticFields = (): void => {
    void setValues({
      ...values,
      ip: EMPTY_CONSTS.STR,
      mask: EMPTY_CONSTS.STR,
      gateway: EMPTY_CONSTS.STR,
      dns: EMPTY_CONSTS.STR
    })
  }

  /**
   * Handler change Protocol SELECT
   */
  const handleChangeProtocol = (
    elem: TSelectProtocol
  ): void => {
    setProtocol(elem)
  }

  /**
     * Reaction on change Protocol SELECT
     */
  useEffect(() => {
    if (currentProtocol != null) {
      void setFieldValue(
        FORM_NAMES.PROTOCOL_CHOICES,
        currentProtocol.value
      )
    }
  }, [currentProtocol])
  /**
   * Reaction on change IP type (Dinamic/Static)
   * clear additional values or fill them
   */
  useEffect(() => {
    const updateFields = (): void => {
      if (isDinamicIP === EMPTY_CONSTS.TRUE) {
        clearStaticFields()
      }
    }
    updateFields()
  }, [isDinamicIP])

  useEffect(() => {
    const fetchProtocols = (): void => {
      void api.network.getAllProticols().then((protocols) => {
        setProtocols(protocols)
      })
    }
    fetchProtocols()
  }, [])

  return {
    values,
    errors,
    securitiOptions,
    isDinamicIP,
    securityValue,
    isPassword,
    isKeyBoard,
    isError,
    keyboardValue,
    isLoading,
    protocols,
    currentProtocol,
    isEnterprise,
    handleChangeProtocol,
    changeIpType,
    handleSubmit,
    handleChange,
    handleSelectChange,
    handleSwith,
    onKeyboardInput,
    onFocusInput,
    onBlurInput,
    closeKeyboard
  }
}

export default useAddNewWifiForm
