import React, { FC } from 'react'
import classNames from 'classnames'
import { Spinner } from '@/views/spinner'
import { Typography } from '@/views/typography'
import { WiFiSignal } from '@/views/wifiSignal'
import { SettingIcon } from '@/assets/icon'
import { DEFAULT_LINKS, SIMPLE_STATUSES } from '@/const'
import {
  TWifiConnectionCard,
  IWifiSignalImage
} from './interfaces'
import styles from './style/index.module.scss'
import { useNavigate } from 'react-router-dom'
import { useSiteConfig } from '@/hooks'

const STATUS_TEXT = {
  [SIMPLE_STATUSES.SUCCESS]: 'Подключено',
  [SIMPLE_STATUSES.ERROR]: 'Ошибка подключения',
  [SIMPLE_STATUSES.LOADING]: ''
}

const WifiSignalImage: FC<IWifiSignalImage> = ({
  loading,
  signal,
  lock,
  fail
}) => {
  if (loading) {
    return <Spinner />
  } else {
    return (
      <WiFiSignal
        value={signal}
        lock={lock}
        fail={fail}
        connect
      />
    )
  }
}

export const WifiConnectionCard: FC<
TWifiConnectionCard
> = ({
  ssid,
  status,
  signal_strength: signal,
  is_password: lock,
  bssid,
  handleReconnectWifi
}) => {
  const navigate = useNavigate()
  const config = useSiteConfig()
  /**
   * Редирект на страницу Списка вайфаев
   */
  const readirectToSettingsCurrentWifi = (): void => {
    if (status === SIMPLE_STATUSES.ERROR) {
      handleReconnectWifi(bssid)
    } else {
      void navigate(config?.links?.settings_connected_wifi ??
          DEFAULT_LINKS.settings_connected_wifi
      )
    }
  }

  return (
    <div className={styles.item}>
      <div className={styles.item__info}>
        <WifiSignalImage
          loading={status === SIMPLE_STATUSES.LOADING}
          signal={signal}
          lock={lock}
          fail={status === SIMPLE_STATUSES.ERROR}
        />
        <div className={styles.item__left}>
          <Typography
            size={40}
            weight={500}
            className={classNames(styles.item__label, {
              [styles.item__label_success]:
                status === SIMPLE_STATUSES.SUCCESS,
              [styles.item__label_error]:
                status === SIMPLE_STATUSES.ERROR
            })}
          >
            {ssid}
          </Typography>
          <Typography
            size={30}
            weight={500}
            className={classNames(styles.item__sublabel)}
          >
            {STATUS_TEXT[status]}
          </Typography>
        </div>
      </div>
      {status !== SIMPLE_STATUSES.LOADING &&
        <button className={styles.item__setting_btn} onClick={readirectToSettingsCurrentWifi}>
          <img src={SettingIcon} width={80} height={80} />
        </button>}
    </div>
  )
}
