import React, { FC } from 'react'
import { Typography } from '@/views/typography'
import { NavLink } from 'react-router-dom'
import { GxIcon } from '@garpix/garpix-web-components-react'
import {
  ErrorStatusIcon,
  SuccessStatusIcon
} from '@/assets/icon'
import { Button } from '../button'
import { Spinner } from '../spinner'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'

interface TEquipmentStatusComponent {
  titleTop: string
  titleBottom: string
  linkTop?: string
  linkBottom?: string
  isButtonTop?: boolean
  isButtonBottom?: boolean
  handleClickBtnTop?: () => void
  handleClickBtnBottom?: () => void
  isLoadBtnSpin?: boolean
}
const EquipmentStatusComponent: FC<
TEquipmentStatusComponent
> = ({
  titleTop,
  titleBottom,
  linkTop = '#',
  linkBottom = '#',
  isButtonTop = false,
  isButtonBottom = false,
  handleClickBtnBottom = () => { },
  handleClickBtnTop = () => { },
  isLoadBtnSpin = false
}) => {
  const contentLinkOrBtnTop = {
    link: (
      <NavLink
        to={linkTop}
        className={styles.container__button}
      >
        <GxIcon
          src={SuccessStatusIcon}
          slot='icon-left'
          className={styles.container__button_icon}
        />
        <Typography
          size={60}
          className={styles.container__button_title}
        >
          {titleTop}
        </Typography>
      </NavLink>
    ),
    button: (
      <Button
        isShadow={false}
        color='white'
        className={styles.container__button}
        onClick={handleClickBtnTop}
      >
        {isLoadBtnSpin
          ? (
            <Spinner />
            )
          : (
            <>
              <GxIcon
                src={SuccessStatusIcon}
                slot='icon-left'
                className={styles.container__button_icon}
              />
              <Typography
                size={60}
                className={styles.container__button_title}
              >
                {titleTop}
              </Typography>
            </>
            )}
      </Button>
    )
  }

  const contentLinkOrBtnBottom = {
    link: (
      <NavLink
        to={linkBottom}
        className={styles.container__button}
      >
        <GxIcon
          src={ErrorStatusIcon}
          slot='icon-left'
          className={styles.container__button_icon}
        />
        <Typography
          size={60}
          className={styles.container__button_title}
        >
          {titleBottom}
        </Typography>
      </NavLink>
    ),
    button: (
      <Button
        isShadow={false}
        color='white'
        className={styles.container__button}
        onClick={handleClickBtnBottom}
      >
        {isLoadBtnSpin
          ? (
            <Spinner />
            )
          : (
            <>
              <GxIcon
                src={ErrorStatusIcon}
                slot='icon-left'
                className={styles.container__button_icon}
              />
              <Typography
                size={60}
                className={styles.container__button_title}
              >
                {titleBottom}
              </Typography>
            </>
            )}
      </Button>
    )
  }

  return (
    <CSSTransitionGroup
      animation='fadeIn'
      className={styles.container__content}
    >
      {contentLinkOrBtnTop[isButtonTop ? 'button' : 'link']}
      {contentLinkOrBtnBottom[isButtonBottom ? 'button' : 'link']}
    </CSSTransitionGroup>
  )
}

export default EquipmentStatusComponent
