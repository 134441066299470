import { TYPE_CONNECTION } from '@/components/lanConnection/const'
import {
  IObjectPageLanData
} from '@/interfaces/ObjectPage'

const errorSerializer = (): any => {
  const data = {
    init_state: {
      object: {
        title: '500',
        seo_title: '500',
        error: 500
      }
    }
  }
  return data
}

const defaultSerializer = (data: any): any => data

const notFoundSerializer = (data: any): any => {
  data.init_state.object = {
    title: '404',
    seo_title: '404',
    error: 404
  }
  return data
}

const permissionDeniedSerializer = (data: any): any => {
  data.init_state.object = {
    title: '403',
    seo_title: '403',
    error: 403
  }
  return data
}

const settingsLanSerializer = (
  data: IObjectPageLanData
): IObjectPageLanData => {
  const initState = data.init_state
  const object = data.init_state.object
  const objValuesConnection = {
    ip: initState.ip,
    mask: initState.mask,
    gateway: initState.gateway,
    dns: initState.dns
  }

  return {
    ...data,
    init_state: {
      ...data.init_state,
      object: {
        ...object,
        typeConnection: initState.connection_type,
        lanConnected: initState.lan_connected,
        dynamicValues:
        initState.connection_type === TYPE_CONNECTION.DYNAMIC
          ? objValuesConnection
          : null,
        staticValues:
        initState.connection_type === TYPE_CONNECTION.STATIC
          ? objValuesConnection
          : null
      }
    }
  }
}

export {
  errorSerializer,
  defaultSerializer,
  notFoundSerializer,
  permissionDeniedSerializer,
  settingsLanSerializer
}
