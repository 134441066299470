import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import { Typography } from '@/views/typography'
import { CheckIcon } from '@/assets/icon'
import style from './style/index.module.scss'
import { ISelectComponent } from './interfaces'

export const OptionSelect: ISelectComponent
| undefined = ({
  label,
  selectOption,
  isSelected,
  data
}) => {
  return (
    <button
      onClick={() => selectOption(data)}
      className={style.menu__item}
    >
      <Typography
        className={classNames(style.menu__label, {
          [style.menu__label_value]: isSelected
        })}
        size={36}
        weight={500}
      >
        {label}
      </Typography>

      <GxIcon
        className={classNames(style.menu__icon, {
          [style.menu__icon_value]: isSelected
        })}
        src={CheckIcon}
      />
    </button>
  )
}
