import React, { useEffect, useState } from 'react'

interface INoSSR {
  fallback?: any
  children: React.ReactNode
}

const NoSSR: React.FC<INoSSR> = ({ children, fallback = null }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => setMounted(true), [])

  if (!mounted) {
    return fallback
  }

  return children
}

export { NoSSR }
