import React, { FC } from 'react'
import classNames from 'classnames'
import styles from './style/index.module.scss'
import { EMPTY_CONSTS, WIFI_SIGNALS } from '@/const'

/**
 * @param lock - сеть заблокирована(к иконке добавляется знак замка)
 * @param value - состояение соединения сети для отображения на иконке( 1 - слабое соединения, 4 - отличное )
 * @param fail - соеденинение с ошибкой (к иконке добавляется знак восклицания)
 * @param connect - соединение подключенения (подключено - иконка окрашивается в оранжевый, иначе черный)
 */
interface IWiFiSignal {
  lock: boolean
  value: string
  fail?: boolean
  connect?: boolean
}

export const WiFiSignal: FC<IWiFiSignal> = (props) => {
  const {
    lock,
    value = 'LOW',
    fail = EMPTY_CONSTS.FALSE,
    connect = EMPTY_CONSTS.FALSE
  } = props

  return (
    <svg
      className={classNames(
        styles.icon,
        styles[`icon_${WIFI_SIGNALS[value] as number}`],
        {
          [styles.icon_connect]: connect
        }
      )}
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='58.4998'
        cy='91.5'
        r='7.5'
        fill='#181D27'
      />
      <path
        d='M90.5107 59.7877C81.8017 52.0004 70.5285 47.6953 58.8457 47.6953C47.1628 47.6953 35.8896 52.0004 27.1807 59.7877'
        stroke='#181D27'
        strokeWidth='8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M103.846 44.8786C78.2712 21.9636 39.4212 21.9636 13.8462 44.8786'
        stroke='#181D27'
        strokeWidth='8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M77.1807 74.6935C72.1387 70.183 65.6109 67.6893 58.8457 67.6893C52.0806 67.6893 45.5528 70.183 40.5107 74.6935'
        stroke='#181D27'
        strokeWidth='8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {/* {(value === 'VERYLOW') && <path d='M90 90L21 21' stroke='#DADCE0' stroke-width='8' stroke-linecap='round' />} */}
      {lock && !fail && (
        <path
          d='M85 99C83.8667 99 82.9173 98.64 82.152 97.92C81.384 97.2025 81 96.3125 81 95.25V84C81 82.9375 81.384 82.0475 82.152 81.33C82.9173 80.61 83.8667 80.25 85 80.25V76.5C85 74.4375 85.7827 72.6712 87.348 71.2012C88.916 69.7337 90.8 69 93 69C95.2 69 97.084 69.7337 98.652 71.2012C100.217 72.6712 101 74.4375 101 76.5V80.25C102.133 80.25 103.083 80.61 103.848 81.33C104.616 82.0475 105 82.9375 105 84V95.25C105 96.3125 104.616 97.2025 103.848 97.92C103.083 98.64 102.133 99 101 99H85ZM89 80.25H97V76.5C97 75.4375 96.616 74.5475 95.848 73.83C95.0827 73.11 94.1333 72.75 93 72.75C91.8667 72.75 90.9173 73.11 90.152 73.83C89.384 74.5475 89 75.4375 89 76.5V80.25Z'
          fill='#181D27'
        />
      )}
      {fail && (
        <path
          stroke='white'
          strokeWidth='2'
          fill='#F40000'
          d='m77.252404,84.068134c0.86636,0.8687 1.9556,1.3008 3.19905,1.3008c1.24348,0 2.33274,-0.4321 3.19911,-1.3009c0.86876,-0.8664 1.30089,-1.9556 1.30089,-3.1991l0,-21c0,-1.24403 -0.43246,-2.33417 -1.30089,-3.20261l-0.70711,0.70711l0.70711,-0.70711c-0.86682,-0.86682 -1.95614,-1.29739 -3.19911,-1.29739c-1.24297,0 -2.33229,0.43057 -3.19911,1.29739c-0.86843,0.86844 -1.30089,1.95858 -1.30089,3.20261l0,21c0,1.2435 0.43215,2.3328 1.30095,3.1992zm-1.30095,10.8008c0,1.2435 0.43215,2.3328 1.30095,3.1992c0.86636,0.8687 1.9556,1.3008 3.19905,1.3008c1.24348,0 2.33274,-0.4321 3.19911,-1.3009c0.86876,-0.8664 1.30089,-1.9556 1.30089,-3.1991c0,-1.2435 -0.43216,-2.3328 -1.30098,-3.1992c-0.86636,-0.8687 -1.95558,-1.3008 -3.19902,-1.3008c-1.24348,0 -2.33274,0.4321 -3.19911,1.3009c-0.86876,0.8664 -1.30089,1.9556 -1.30089,3.1991z'
        />
      )}
    </svg>
  )
}
