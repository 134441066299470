import React from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import {
  GxButton,
  GxIcon
} from '@garpix/garpix-web-components-react'
import { ArrowBackIcon, LogoIcon } from '@/assets/icon'
import { useSiteConfig, useStores } from '@/hooks'
import useGlobalSpinner from '@/hooks/useGlobalSpiner'
import { Spinner } from '@/views/spinner'

import style from './style/index.module.scss'

interface IProps {
  children: JSX.Element | JSX.Element[]
  linkBack?: string
  visibleLinkBack?: boolean
  handleBack?: (params?: unknown) => unknown
  logoutCallback?: () => void
}

export const MainLayout: React.FC<IProps> = ({
  children,
  linkBack,
  handleBack,
  logoutCallback,
  visibleLinkBack = true
}): React.ReactElement => {
  const store = useStores()
  const config = useSiteConfig()
  const navigate = useNavigate()
  const { spinnerProps, hideSpinner } = useGlobalSpinner({ size: 'medium', isFullPage: true, global: true })

  /**
   * редирет на прошлую страницу
   * ? hack для кнопки назад
   * ! проработать все кейсы для
   */
  const handleCLickBack = (): void => {
    if (handleBack !== undefined) {
      handleBack()
    }
    if (linkBack !== undefined) {
      navigate(linkBack)
    }
    hideSpinner()
  }

  /**
   * обработчик логаута
   */
  const handleLogoutClick = async (): Promise<void> => {
    hideSpinner()
    await store.api.user.logout()
    logoutCallback?.()
  }

  return (
    <div className={style.container}>
      {store.spinerStore.isShow && <Spinner {...spinnerProps} />}
      <div className={style.container__header}>
        <img
          alt='логотип'
          src={config?.logo?.logo_url ?? LogoIcon}
          className={style.container__logo}
        />
        <GxButton
          onClick={handleCLickBack}
          disabled={!visibleLinkBack}
          className={classNames(style.container__back, {
            [style.container__back_hidden]:
              !visibleLinkBack
          })}
        >
          <GxIcon
            src={ArrowBackIcon}
            slot='icon-left'
            className={style.container__icon}
          />
          Назад
        </GxButton>

        <GxButton
          onClick={handleLogoutClick}
          className={style.container__back}
        >
          Выход
        </GxButton>
      </div>
      <div className={style.container__content}>
        {children}
      </div>
    </div>
  )
}

export default observer(MainLayout)
