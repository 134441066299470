import React, { FC } from 'react'
import { Typography } from '@/views/typography'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { ErrorClose } from '@/assets/icon'
import { NavLink } from 'react-router-dom'
import styles from './style/index.module.scss'

interface TErrorPipeLine {
  linkBack: string
  errorMessage: string
  hideBtn?: boolean
}

const ErrorPipeLine: FC<TErrorPipeLine> = ({
  linkBack = '#',
  errorMessage,
  hideBtn
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.container__content}>
        <div className={styles.icon__container}>
          <GxIcon
            src={ErrorClose}
            className={styles.icon}
          />
        </div>
        <Typography
          size={80}
          weight={700}
          className={styles.container__title}
        >
          {errorMessage}
        </Typography>
        {hideBtn === false || hideBtn === undefined
          ? (
            <NavLink
              to={linkBack}
              className={styles.container__button}
            >
              Вернуться в меню
            </NavLink>
            )
          : null}
      </div>
    </div>
  )
}

export default ErrorPipeLine
