import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { GxIcon } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'

import MainLayout from '@/components/mainLayout/MainLayout'
import { Typography } from '@/views/typography'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import { BOTTOM_CONTENT_TYPES, ModalBottom } from '@/views/ModalBottom'

import { useSiteConfig, useStores } from '@/hooks'
import { notify } from '@/utils/notify'
import { RestartIcon, CellManagement, WiFiIcon, InfoIcon } from '@/assets/icon'
import { DEFAULT_LINKS, MODAL_TYPES, THEME_MODAL } from '@/const'

import styles from './style/index.module.scss'

const MenuEngineer: React.FC = (): React.ReactElement => {
  const config = useSiteConfig()
  const store = useStores()
  const [isOpenConfirmReboot, setIsOpenConfirmReboot] =
    useState(false)

  /**
   * обработчик события клика по кнопке перезагрузки
   */
  const handleClickRebootBtn = (): void => {
    setIsOpenConfirmReboot(true)
  }

  const handleOpenDeviceInformationModal = (): void => {
    store.modalStore.setTheme(THEME_MODAL.FULL)
    store.modalStore.open(MODAL_TYPES.STORAGE_INFO)
  }

  /**
   * обработает закрытие модалки
   */
  const handleCloseConfirmModal = (): void => {
    setIsOpenConfirmReboot(false)
  }

  /**
   * обработчик для запуска пепрезагрузки
   */
  const handleRebootNow = (): void => {
    store.api.network
      .onReboot()
      .then(() =>
        notify.success('Активирована перезагрузка шкафа')
      )
      .catch(() => {
        notify.error('Ошибка перезагрузки шкафа', {
          position: 'top-right'
        })
      })
  }

  const actions = {
    onReboot: handleRebootNow,
    onClose: handleCloseConfirmModal
  }

  return (
    <MainLayout visibleLinkBack={false}>
      <CSSTransitionGroup
        animation='fadeInTop'
        className={styles.container}
      >
        <NavLink
          className={styles.item}
          to={
            config?.links?.net_settings ?? '/nastrojki-seti'
          }
        >
          <GxIcon
            src={WiFiIcon}
            className={classNames(
              styles.item__icon,
              styles.item__icon_orange,
              styles.item__icon_wifi
            )}
          />
          <Typography
            size={40}
            weight={500}
            className={styles.item__label}
          >
            Настройки сети
          </Typography>
        </NavLink>
        <NavLink
          className={styles.item} to={
            config?.links?.cell_admin_page ?? DEFAULT_LINKS.cell_admin_page
          }
        >
          <GxIcon
            src={CellManagement}
            className={classNames(
              styles.item__icon,
              styles.item__icon_store
            )}
          />
          <Typography
            size={40}
            weight={500}
            className={styles.item__label}
          >
            Администрирование ячеек
          </Typography>
        </NavLink>
        <button onClick={handleOpenDeviceInformationModal} className={styles.item}>
          <GxIcon
            src={InfoIcon}
            className={classNames(
              styles.item__icon,
              styles.item__icon_orange
            )}
          />
          <Typography
            size={40}
            weight={500}
            className={styles.item__label}
          >
            Информация об устройстве
          </Typography>
        </button>
        <button
          className={styles.item}
          onClick={handleClickRebootBtn}
        >
          <GxIcon
            src={RestartIcon}
            className={classNames(
              styles.item__icon,
              styles.item__icon_red
            )}
          />
          <Typography
            size={40}
            weight={500}
            className={styles.item__label}
          >
            Перезагрузка шкафа
          </Typography>
        </button>
      </CSSTransitionGroup>
      <ModalBottom
        isOpen={isOpenConfirmReboot}
        contentType={BOTTOM_CONTENT_TYPES.REBOOT_STORE}
        params={actions}
      />
    </MainLayout>
  )
}

export default MenuEngineer
