import React, {
  useEffect,
  useState
} from 'react'
import BaseModal from './Base'
import { NoSSR } from '@/components/NoSSR'
import { SetCurdNumber, SetNFCNumber, HistoryScan, DeviceInformation } from './ModalContent'
import { useStores } from '@/hooks'
import { MODAL_TYPES } from '@/const'
import { observer } from 'mobx-react'
import { KeyboardLayout } from '@/components/keyboard/Keyboard'
import styles from './ModalContent/styles/index.module.scss'

/**
 * набор модалок
 * key- тип модалки
 * value - компонента контеента модалки
 */
const modals = {
  [MODAL_TYPES.SET_CURD_NUMBER]: SetCurdNumber,
  [MODAL_TYPES.SET_NFC_NUMBER]: SetNFCNumber,
  [MODAL_TYPES.HISTORY_SCAN]: HistoryScan,
  [MODAL_TYPES.STORAGE_INFO]: DeviceInformation,
  [MODAL_TYPES.EMPTY]: null
}

const Modals: React.FC = observer(() => {
  const { modalStore } = useStores()
  const [isOpenKeyboard, setIsOpenKeyboard] =
    useState(false)
  const [valueKeyboard, setValueKeyboard] = useState<
  string | null
  >(null)

  const afterHideModal = (): void => modalStore.close()

  const closeModal = (): void => {
    modalStore.close()
  }

  const CurrentModal =
    modals[modalStore.currentVariant ?? MODAL_TYPES.EMPTY]

  useEffect(() => {
    if (!modalStore.isOpen) {
      setValueKeyboard(null)
    }
  }, [modalStore.isOpen])

  if (CurrentModal === null || CurrentModal === undefined) {
    return null
  }

  const paramsModal = {
    openKeyboard: () => setIsOpenKeyboard(true),
    closeKeyboard: () => setIsOpenKeyboard(false),
    setValueKeyboard,
    valueKeyboard
  }

  return (
    <NoSSR>
      <KeyboardLayout
        className={styles.keyboard}
        handlecontinue={paramsModal.closeKeyboard}
        value={valueKeyboard}
        open={isOpenKeyboard}
        onChange={(v) => {
          setValueKeyboard(v)
        }}
      >
        <BaseModal
          handleClose={closeModal}
          open={modalStore.isOpen}
          onGx-after-hide={afterHideModal}
          theme={modalStore?.modalProps?.theme}
        >
          <CurrentModal
            closeModal={closeModal}
            {...paramsModal}
          />
        </BaseModal>
      </KeyboardLayout>
    </NoSSR>
  )
})

export default Modals
