import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react'
import MainLayout from '@/components/mainLayout/MainLayout'
import { Typography } from '@/views/typography'
import classNames from 'classnames'
import { GxIcon } from '@garpix/garpix-web-components-react'
import {
  ArrowHeadDownCustomIcon,
  ArrowHeadSmallDownCustomIcon,
  ScanPanel
} from '@/assets/icon'
import {
  DEFAULT_LINKS,
  DEFAULT_SETTINGS_PROJECT,
  DEFAULT_SETTINGS_PROJECT_NAME,
  ERROR_MAIN_TEXT,
  MODAL_TYPES,
  TEXT,
  TIMEOUT,
  USER_ROLES,
  WORK_MODE
} from '@/const'
import { useCurrentUser, useSiteConfig, useStores } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import { ScanSignal } from '@/views/scanSignal'
import styles from './styles/index.module.scss'
import { runtimeConfig } from '@/config'
import { Button } from '@/views/button'

const ReturnEquipment: FC = observer(() => {
  const navigate = useNavigate()
  const store = useStores()
  const config = useSiteConfig()

  const { role } = useCurrentUser()
  let timerBackToPageScan: NodeJS.Timeout // ссылка на таймер (необходимо для очистки)
  const {
    NFC_SUCCESS,
    NFC_ERROR,
    NFC_COUNT_LABEL,
    NFC_COUNT_READ_LABLE
  } = store.global.get() // получение глобального стора для реакции на события с сокета

  const isWorkman = role === USER_ROLES.workman
  const isHideBackLink = isWorkman && config?.work_mode === WORK_MODE.RANDOM

  /**
   * сбросит данные по сканированию оборудования
   * ? для повторного использования
   */
  const reflowNFCStore = (): void => {
    store.global.reset()
  }

  /**
   * калбек на таймер
   * ? формат записи не менять (необходим для последущей очистки таймера)
   */
  const timerResolver = (): void => {
    store.global.set<boolean>(
      DEFAULT_SETTINGS_PROJECT_NAME.NFC_ERROR,
      false
    )
  }
  /**
   * @func redirectToNextPage
   * метод для редиректа на стр при успешном сканировании метки
   */
  const redirectToNextPage = (): void => {
    /** Если вкл временная сдача оборудования и пользователь - рабочий */
    if (config.is_hand_over && isWorkman) {
      return navigate(config?.links?.page_surrender_of_equipment ?? DEFAULT_LINKS.surrender_of_equipment)
    }
    return navigate(config?.links?.condition_equipment ?? DEFAULT_LINKS.condition_equipment)
  }

  const handleCickOpenModal = (): void => {
    store.modalStore.open(MODAL_TYPES.SET_NFC_NUMBER)
  }
  const showNFCStep = NFC_COUNT_LABEL !== 1
  const scanText = NFC_ERROR
    ? ERROR_MAIN_TEXT.ERROR_NFC_SCAN
    : showNFCStep ? TEXT.SCAN_NFC_EQUIPMENT_AGAIN : TEXT.SCAN_NFC_EQUIPMENT

  useEffect(() => {
    if (NFC_SUCCESS) {
      redirectToNextPage()
      reflowNFCStore()
    }
  }, [NFC_SUCCESS])

  useEffect(() => {
    timerBackToPageScan = setTimeout(
      timerResolver,
      TIMEOUT._2000
    )
  }, [NFC_ERROR])

  useEffect(() => {
    return () => {
      clearTimeout(timerBackToPageScan)
      // сбростить этап сканирования меток в сторе при размонтировании
      store.global.set(
        DEFAULT_SETTINGS_PROJECT_NAME.NFC_COUNT_LABEL,
        DEFAULT_SETTINGS_PROJECT.NFC_COUNT_LABEL
      )
      store.global.set(
        DEFAULT_SETTINGS_PROJECT_NAME.NFC_COUNT_READ_LABLE,
        DEFAULT_SETTINGS_PROJECT.NFC_COUNT_READ_LABLE
      )
    }
  }, [])

  return (
    <MainLayout visibleLinkBack={!isHideBackLink} linkBack={config.links.verification}>
      <div
        className={classNames({
          [styles.container]: true
        })}
      >
        {runtimeConfig.IS_DEV === 'true'
          ? (
            <CSSTransitionGroup animation='fadeInTop'>
              <Button
                onClick={handleCickOpenModal}
                className={styles.enter__card__btn}
              >
                Ввести метку
              </Button>
            </CSSTransitionGroup>
            )
          : null}
        <CSSTransitionGroup
          animation='fadeInTop'
          className={classNames(styles.container_block, {
            [styles.mt_0]: runtimeConfig.IS_DEV === 'true'
          })}
        >
          <GxIcon
            src={ScanPanel}
            className={styles.container_panel}
          />
          <div
            className={classNames(
              styles.container__content
              // {
              //   [styles.mt_0]:
              //     runtimeConfig.IS_DEV === 'true',
              // }
            )}
          >
            <Typography
              size={80}
              color={NFC_ERROR ? 'red' : undefined}
              className={classNames(
                styles.container__title,
                {
                  [styles.container__title_error]:
                    NFC_ERROR
                }
              )}
            >
              {scanText}
            </Typography>
            {showNFCStep &&
              <Typography
                size={80}
                className={styles.container__step}
              >
                {NFC_COUNT_READ_LABLE} / {NFC_COUNT_LABEL}
              </Typography>}
            <ScanSignal
              className={classNames(
                styles.container__card,
                {
                  [styles.container__card_error]: NFC_ERROR
                }
              )}
            />
          </div>
          <div className={styles.container__footer}>
            <GxIcon
              src={ArrowHeadSmallDownCustomIcon}
              className={classNames(
                styles.container__arrow_secondary,
                styles.container__arrow,
                {
                  [styles.container__arrow_error]:
                    NFC_ERROR
                }
              )}
            />
            <GxIcon
              src={ArrowHeadDownCustomIcon}
              className={classNames(
                styles.container__arrow_main,
                styles.container__arrow,
                {
                  [styles.container__arrow_error]:
                    NFC_ERROR
                }
              )}
            />
          </div>
        </CSSTransitionGroup>
      </div>
    </MainLayout>
  )
})

export default ReturnEquipment
