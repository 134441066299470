import React, { FC } from 'react'
import {
  GxButton,
  GxIcon
} from '@garpix/garpix-web-components-react'
import { ArrowIcon } from '@/assets/icon'

interface ButtonType {
  action: () => void
  className: string
  classNameIcon: string
}
const Button: FC<ButtonType> = ({
  action,
  className,
  classNameIcon
}) => (
  <GxButton onClick={action} className={className}>
    <GxIcon src={ArrowIcon} className={classNameIcon} />
  </GxButton>
)

export default Button
