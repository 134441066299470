interface TColor {
  root: string
  trail: string
  path: string
  text: string
  background: string
}
/**
 * @deprecated
 */
// const getColor = (time: number): string => {
//   if (time > 14) {
//     return 'trail_green'
//   } else if (time > 9) {
//     return 'trail_yellow'
//   } else {
//     return 'trail_red'
//   }
// }

/**
 * * метод для установки цвета таймера (осн цвет - зеленый)
 * @function setGreenColor
 */
const setGreenColor = (time: number): string => time > 0 ? 'trail_green' : 'trail_red'

export const StyleTimer = (time: number): TColor => {
  return {
    path: 'CircularProgressbar-path path',
    trail: `${setGreenColor(time)}`,
    root: 'CircularProgressbar',
    text: 'CircularProgressbar-text text',
    background: 'CircularProgressbar-background'
  }
}
