import React, { FC, PointerEvent, useState } from 'react'
import ReactSelect from 'react-select'
import classNames from 'classnames'
import { Typography } from '@/views/typography'
import { OptionSelect } from '@/views/optionSelect'
import style from './style/index.module.scss'
import { ISelectComponent } from '../optionSelect/interfaces'

interface ISelectValue {
  label: string
  value: string | number
}

interface ISelect {
  onChange: (value: ISelectValue) => void
  options: ISelectValue[]
  value?: ISelectValue | null
  label?: string
  placeholder?: string
  name?: string
  id?: string
  isSearchable?: boolean
  isError?: boolean
  errorMessage?: string
}

interface IIndicator {
  handleClick: (e: PointerEvent<HTMLDivElement>) => void
  open: boolean
}

const Indicator: FC<IIndicator> = ({ open, handleClick }) => {
  return (
    <div
      className={classNames(style.main__icon, {
        [style.main__icon_open]: open
      })}
      onPointerDown={handleClick}
    >
      <svg
        width='60' height='61' viewBox='0 0 60 61' fill='none' xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M39 10L19.6813 29.8017C19.3023 30.1901 19.3023 30.8099 19.6813 31.1983L39 51' stroke='currentColor' stroke-width='6' stroke-linecap='round' />
      </svg>
    </div>
  )
}

export const Select: FC<ISelect> = ({
  onChange,
  label,
  value,
  options,
  placeholder = '',
  isError,
  errorMessage,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  const closeDropdown = (): void => {
    setOpen(false)
  }

  const openDropdown = (): void => {
    setOpen(true)
  }

  const handleClickArrow = (e: PointerEvent<any>): void => {
    e.preventDefault()
    if (open) {
      closeDropdown()
    } else openDropdown()
  }

  const selectStyles = {
    control: ({ menuIsOpen }) => {
      return classNames(style.container__control, {
        [style.container__control_open]: menuIsOpen,
        [style.container__control_error]: isError
      })
    },
    menu: () => style.container__menu,
    input: () => style.container__input,
    placeholder: () => style.container__input,
    valueContainer: () => style.container__inputContainer,
    option: () => style.container__option,
    singleValue: () => style.container__value,
    menuList: () => style.container__list,
    container: ({ selectProps }) => {
      return classNames(style.main__container, {
        [style.main__container_open]: selectProps.menuIsOpen
      })
    }
  }

  const SelectIndicator: ISelectComponent = () =>
    <Indicator open={open} handleClick={handleClickArrow} />

  return (
    <div className={style.main}>
      <Typography
        size={36}
        weight={700}
        className={classNames(style.main__label, {
          [style.main__label_error]: isError
        })}
      >
        {label}
      </Typography>
      <ReactSelect
        {...props}
        onMenuClose={closeDropdown}
        onChange={onChange}
        menuIsOpen={open}
        onMenuOpen={openDropdown}
        placeholder={placeholder}
        options={options}
        value={value}
        components={{
          IndicatorsContainer: SelectIndicator,
          Option: OptionSelect
        }}
        classNames={selectStyles}
      />
      {
        Boolean(errorMessage) && (
          <Typography
            size={30}
            weight={500}
            className={style.main__error_msg}
          >
            {errorMessage}
          </Typography>
        )
      }
    </div>
  )
}
