import React, { FC } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import { Typography } from '@/views/typography'
import styles from './style/index.module.scss'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { CheckIcon, ErrorClose } from '@/assets/icon'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { Button } from '../button'
import { useStores } from '@/hooks'

interface TInfoMessagePage {
  linkBack: string | undefined
  visibleLinkBack?: boolean
  errorMessage: string
  linkToMain: string
  success?: boolean
  typeName?: string
  description?: string
  showBtn?: boolean
  isExitBtn?: boolean
}

const InfoMessagePage: FC<TInfoMessagePage> = ({
  linkBack = '/',
  visibleLinkBack = true,
  errorMessage,
  linkToMain = '#',
  success = false,
  typeName,
  description,
  showBtn = true,
  isExitBtn = false
}) => {
  const store = useStores()

  /**
   * обработчик логаута
   */
  const handleLogoutClick = (): void => {
    void store.api.user.logout()
  }

  return (
    <MainLayout visibleLinkBack={visibleLinkBack} linkBack={linkBack}>
      <div className={styles.container}>
        <div
          className={classNames(styles.container__content, {
            [styles.container__content_subtitle]: typeName
          })}
        >
          <div
            className={classNames(styles.icon__container, {
              [styles.icon__container_success]: success
            })}
          >
            <GxIcon
              src={success ? CheckIcon : ErrorClose}
              className={classNames(styles.icon, {
                [styles.icon_success]: success
              })}
            />
          </div>
          <Typography
            size={80}
            weight={700}
            className={styles.container__title}
          >
            {errorMessage}
          </Typography>
          {Boolean(description) && (
            <Typography
              size={50}
              weight={400}
              className={styles.container__description}
            >
              {description}
            </Typography>
          )}

          {Boolean(typeName) && (
            <Typography
              size={60}
              weight={400}
              className={styles.container__subtitle}
            >
              {typeName}
            </Typography>
          )}
          {showBtn && !isExitBtn && (
            <NavLink
              to={linkToMain}
              className={styles.container__button}
            >
              Вернуться в меню
            </NavLink>
          )}
          {showBtn && isExitBtn && (
            <Button
              onClick={handleLogoutClick}
              className={styles.container__button}
            >
              Выход
            </Button>
          )}
        </div>
      </div>
    </MainLayout>
  )
}

export default InfoMessagePage
