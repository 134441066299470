import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from 'react-dom/client';
import {
  applyPolyfills,
  defineCustomElements,
} from '@garpix/garpix-web-components/loader';
import '@garpix/garpix-web-components/dist/garpix-web-components/garpix-web-components.css';
import App from './App';
import { RootStore } from './services';
import { StoreProvider } from './contexts';
import { defaultLanguage } from '@/i18n';
import { runtimeConfig } from './config';
import './styles/global/index.scss';
import './styles/index.scss';

import 'react-circular-progressbar/dist/styles.css';
// import 'react-simple-keyboard/build/css/index.css';

const container =
  document.getElementById('root') || document.body;

const API_URL = runtimeConfig.API_URL;

const rootStore = new RootStore(
  API_URL,
  globalThis.language ?? defaultLanguage
);

ReactDOM.hydrateRoot(
  container,
  <StoreProvider store={rootStore}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </StoreProvider>
);

applyPolyfills().then(() => {
  defineCustomElements();
});

if (module.hot) {
  module.hot.accept();
}
