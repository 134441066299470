import React, { FC, useState } from 'react'
import classNames from 'classnames'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { Typography } from '@/views/typography'
import DynamicStaticButtons from '@/views/dynamicStaticButtons/addNewWifi'
import FormStaticConnection from '../formStaticConnection/addNewWifi'
import { IFormConnectToWifiValues, IFormConnectToWifiErrors } from '@/interfaces/Hooks'
import { ArrowBackIcon } from '@/assets/icon'
import styles from './style/index.module.scss'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import { IBlurInputEvent, IChangeInputEvent, IFocusInputEvent } from '@/interfaces/Common'

interface IProps {
  isDinamicIP: boolean
  handleChangeIpType: (newvalue: boolean) => void
  values: IFormConnectToWifiValues
  errors: IFormConnectToWifiErrors
  handleInput: (event: IChangeInputEvent) => void
  handleFocusInput: (event: IFocusInputEvent) => void
  handleBlurInput: (event: IBlurInputEvent) => void
}

export const OptionButtonNetwork: FC<IProps> = ({
  isDinamicIP,
  handleChangeIpType,
  values,
  errors,
  handleInput,
  handleBlurInput,
  handleFocusInput
}) => {
  const [openSetNetwork, setOpenSetNetwork] =
    useState(false)

  const toggleOpenSetNetwork = (): void => {
    setOpenSetNetwork((prevVal) => !prevVal)
  }
  return (
    <CSSTransitionGroup className={styles.container}>
      <div className={styles.setting}>
        <button
          onClick={toggleOpenSetNetwork}
          type='button'
          data-testid='More_Settings'
          className={classNames(styles.setting__button, {
            [styles.setting__button_active]: openSetNetwork
          })}
        >
          <Typography
            size={40}
            className={classNames(styles.setting__title, {
              [styles.setting__title_active]:
                openSetNetwork
            })}
          >
            Настройки сети
          </Typography>
          <GxIcon
            src={ArrowBackIcon}
            className={classNames(styles.setting__toggle, {
              [styles.setting__toggle_active]:
                openSetNetwork
            })}
          />
        </button>
      </div>
      {openSetNetwork && (
        <DynamicStaticButtons
          isDinamicIP={isDinamicIP}
          handleChangeIpType={handleChangeIpType}
        />
      )}
      {(!isDinamicIP && openSetNetwork) && (
        <FormStaticConnection
          values={values}
          errors={errors}
          handleInput={handleInput}
          handleFocusInput={handleFocusInput}
          handleBlurInput={handleBlurInput}
        />
      )}
    </CSSTransitionGroup>
  )
}
