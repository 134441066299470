import React from 'react'
import AllAddForm from '@/components/addConnectionForm/allAddForm'
import MainLayout from '@/components/mainLayout/MainLayout'
import ErrorWifiConnect from '@/views/ErrorBlock/ErrorWifiConnect'
import useAddNewWifiForm from '@/hooks/useAddNewWifiForm'
import { useSiteConfig } from '@/hooks'
import { DEFAULT_LINKS } from '@/const'
import { KeyboardLayout } from '@/components/keyboard/Keyboard'

const AddConnection: React.FC = (): React.ReactElement => {
  const config = useSiteConfig()

  const {
    values,
    errors,
    securitiOptions,
    isDinamicIP,
    securityValue,
    isPassword,
    isError,
    isKeyBoard,
    isLoading,
    keyboardValue,
    protocols,
    currentProtocol,
    isEnterprise,
    handleChangeProtocol,
    changeIpType,
    handleSubmit,
    handleChange,
    handleSwith,
    handleSelectChange,
    onFocusInput,
    onBlurInput,
    onKeyboardInput,
    closeKeyboard
  } = useAddNewWifiForm()

  return (
    <MainLayout linkBack={config?.links?.settings_wifi ?? DEFAULT_LINKS.settings_wifi}>
      <>
        {isError
          ? <ErrorWifiConnect />
          : (
            <>
              <KeyboardLayout
                onChange={onKeyboardInput}
                open={isKeyBoard}
                value={keyboardValue}
                handlecontinue={closeKeyboard}
              >
                <AllAddForm
                  protocols={protocols}
                  currentProtocol={currentProtocol}
                  isEnterprise={isEnterprise}
                  handleChangeProtocol={handleChangeProtocol}
                  isKeyBoard={isKeyBoard}
                  isLoading={isLoading}
                  values={values}
                  errors={errors}
                  isDinamicIP={isDinamicIP}
                  securitiOptions={securitiOptions}
                  handleSubmit={handleSubmit}
                  isPassword={isPassword}
                  securityValue={securityValue}
                  changeIpType={changeIpType}
                  handleSwith={handleSwith}
                  handleSelectChange={handleSelectChange}
                  handleChange={handleChange}
                  onFocusInput={onFocusInput}
                  onBlurInput={onBlurInput}
                />
              </KeyboardLayout>
            </>
            )}
      </>
    </MainLayout>
  )
}

export default AddConnection
