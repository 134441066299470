import React, { FC } from 'react'
import MainLayout from '@/components/mainLayout/MainLayout'
import InfoMessagePage from '@/views/infoMessagePage/InfoMessagePage'

const UserEquipmentNotConnectedPage: FC = () => {
  return (
    <MainLayout visibleLinkBack={false}>
      <InfoMessagePage
        linkBack={undefined}
        errorMessage='Вам не присвоено оборудование'
        description='Обратитесь к администратору'
        linkToMain='/'
        visibleLinkBack={false}
      />
    </MainLayout>
  )
}

export default UserEquipmentNotConnectedPage
