import { useState, useEffect } from 'react'
import { useStores } from './useStores'
import {
  IAllWiFiConnections,
  IGetAllWiFiConnections,
  IWifi
} from '@/api/interfaces/Network'
import {
  IUseWifiList,
  IUseWifiListReturn
} from '@/interfaces/Hooks'
import { EMPTY_CONSTS } from '@/const'

const DEFAULT_PAGE_SIZE = 10

/**
 * Hook for process wifi list
 * USING WIFI_LIST_API
 * GET: nextPage, isLoading, clearList
 * isFirstLoading
 * @param param0
 * @returns
 */
const useWifiList = ({
  onError = EMPTY_CONSTS.FUNC,
  onSuccess = EMPTY_CONSTS.FUNC,
  isInfinity = EMPTY_CONSTS.TRUE,
  pageSize = DEFAULT_PAGE_SIZE
}: IUseWifiList): IUseWifiListReturn => {
  const { api } = useStores()
  /** @info список вайфай сетей сконтаченый с предыдущими страницами + текущая подключенная сеть */
  const [wifiList, setWifiList] = useState<IWifi[] | null>(
    EMPTY_CONSTS.NULL
  )
  /** @info все данные с запроса: count,next,prev,results и тд */
  const [data, setData] =
    useState<IAllWiFiConnections | null>(EMPTY_CONSTS.NULL)
  /** @info isLoading - значение во время любой загрузки загрузки true, после завершения false */
  const [isLoading, setIsLoading] = useState<
  boolean | null
  >(EMPTY_CONSTS.FALSE)
  /** @info isFirstLoading - начальное значение null, значение во время первой загрузки true, после завершения первой false */
  const [isFirstLoading, setIsFirstLoading] = useState<
  boolean | null
  >(EMPTY_CONSTS.NULL)
  /** @info параметры запроса списка сетей */
  const [fetchParams, setFetchParams] = useState({
    page: EMPTY_CONSTS.UNIT,
    page_size: pageSize
  })

  /**
   * Extended Fetch request
   * @param props
   * @returns
   */
  const fetcherDecoraded = async (
    props: IGetAllWiFiConnections
  ): Promise<IAllWiFiConnections> => {
    setIsLoading(EMPTY_CONSTS.TRUE)
    if (isFirstLoading === EMPTY_CONSTS.NULL) {
      setIsFirstLoading(EMPTY_CONSTS.TRUE)
    }

    return await api.network.getAllWiFiConnections(props)
  }

  /**
   * Extended onSuccess event
   * @param data
   */
  const onSuccessDecorated = (
    data: IAllWiFiConnections
  ): void => {
    setData(data)
    onSuccess(data)
  }

  /**
   *  Extended onError event
   * @param error
   */
  const onErrorDecorated = (error: any): void => {
    onError(error)
  }

  /**
   * Fetch request to get wifiList with fetchParams
   */
  const fetchWifiList = (): void => {
    fetcherDecoraded(fetchParams)
      .then((data) => {
        onSuccessDecorated(data)
      })
      .catch((err) => {
        onErrorDecorated(err)
      })
  }

  // Hanvdler Force clear WifiList and back to fetchParams
  const clearList = (): void => {
    setWifiList(null)
    setFetchParams({
      ...fetchParams,
      page: EMPTY_CONSTS.UNIT
    })
  }

  /**
   * Force revalidate list data
   */
  const revalidateData = (): void => {
    setIsFirstLoading(true)
    clearList()
    fetchWifiList()
  }

  /**
   * simple set next page
   */
  const nextPage = (): void => {
    if (
      data?.next !== EMPTY_CONSTS.UNDEFINED &&
      data?.next !== EMPTY_CONSTS.NULL &&
      isLoading !== true
    ) {
      setFetchParams({
        ...fetchParams,
        page: fetchParams.page + 1
      })
    }
  }

  /**
   * simple set prev page
   */
  const prevPage = (): void => {
    if (
      data?.previous !== EMPTY_CONSTS.UNDEFINED &&
      data?.previous !== EMPTY_CONSTS.NULL &&
      isLoading !== true
    ) {
      setFetchParams({
        ...fetchParams,
        page: fetchParams.page - 1
      })
    }
  }

  /**
   * Reaction to  change wifi list
   * it means concated wifi list is updated
   * and we can of isFirstLoad state
   */
  useEffect(() => {
    if (wifiList !== null) {
      if (isFirstLoading === true) {
        setIsFirstLoading(EMPTY_CONSTS.FALSE)
      }
      if (isLoading === true) {
        setIsLoading(EMPTY_CONSTS.FALSE)
      }
    }
  }, [wifiList])

  /**
   * if isInfinity and data change - concat more page
   * else set simple new page
   */
  useEffect(() => {
    if (
      data === EMPTY_CONSTS.NULL ||
      data === EMPTY_CONSTS.UNDEFINED
    ) { return }
    if (isInfinity) {
      let newWifiList: IWifi[] =
        wifiList !== EMPTY_CONSTS.NULL ? wifiList : []
      newWifiList = newWifiList.concat(data.results)
      setWifiList(newWifiList)
    } else {
      setWifiList(data.results)
    }
  }, [data])

  /**
   * update data accord to new fetchParams
   */
  useEffect(() => {
    if (fetchParams.page !== 1) {
      fetchWifiList()
    }
  }, [fetchParams])

  /**
   * FirstLoad wifi List on MOUNT
   * Clear wifi list on UnMount
   */
  useEffect(() => {
    fetchWifiList()
    return () => {
      clearList()
    }
  }, [])

  return {
    isNext:
      data?.next === EMPTY_CONSTS.UNDEFINED
        ? EMPTY_CONSTS.NULL
        : data?.next,
    list: wifiList,
    isLoading,
    isFirstLoading,
    clearList,
    revalidateData,
    nextPage,
    prevPage
  }
}

export default useWifiList
