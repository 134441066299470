import React from 'react'
import classNames from 'classnames'
import { Typography } from '@/views/typography'
import { ITextarea } from '@/interfaces/Common'
import { EMPTY_CONSTS } from '@/const'
import style from './style/index.module.scss'

export const TextArea: React.ForwardRefExoticComponent<
ITextarea & {
  children?: React.ReactNode
} & React.RefAttributes<HTMLTextAreaElement>
> = React.forwardRef(
  (
    {
      value = EMPTY_CONSTS.STR,
      label = EMPTY_CONSTS.STR,
      message = EMPTY_CONSTS.STR,
      error = EMPTY_CONSTS.FALSE,
      disabled = EMPTY_CONSTS.FALSE,
      errorMessage = EMPTY_CONSTS.STR,
      onChange = EMPTY_CONSTS.FUNC,
      onFocus = EMPTY_CONSTS.FUNC,
      onBlur = EMPTY_CONSTS.FUNC,
      outerStyles = '',
      ...props
    },
    ref: React.RefObject<HTMLTextAreaElement>
  ): React.ReactElement => {
    return (
      <div className={style.container}>
        <Typography
          size={36}
          weight={700}
          className={classNames(style.container__title, {
            [style.container__title_error]: true,
            [style.container__title_disabled]: disabled
          })}
        >
          {label}
        </Typography>
        {!(disabled ?? false) && (
          <Typography
            size={24}
            weight={400}
            className={classNames(style.container__message)}
          >
            {message}
          </Typography>
        )}

        <textarea
          ref={ref}
          disabled={disabled}
          className={classNames(
            style.textarea,
            {
              [style.textarea_error]: error,
              [style.textarea_disabled]: disabled
            },
            outerStyles
          )}
          onInput={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value ?? ''}
          autoComplete='off'
          {...props}
        />

        {Boolean(error) && (
          <Typography
            size={30}
            weight={500}
            className={style.container__error}
          >
            {errorMessage}
          </Typography>
        )}
      </div>
    )
  }
)
