import { IStorageConfig, IStorageConfigSerialized } from '@/api/interfaces/Config'

const equipmentModeDictionary = {
  random: 'Рандомный выбор ячейки',
  manually: 'Выбор ячейки с оборудованием вручную'
}

const userModeDictionaty = {
  tied_equipment: 'Привязанное к пользователю оборудование',
  random: 'Рандомный выбор доступного оборудования'
}

export const storageConfigSerializer = (response: IStorageConfig): IStorageConfigSerialized => {
  return {
    serialNumber: response.serial_number,
    cellReservation: response.is_cell_reservation ? 'вкл' : 'выкл',
    handOver: response.is_hand_over ? 'вкл' : 'выкл',
    equipmentSelectionMode: equipmentModeDictionary[response.equipment_selection_mode],
    userSelectionMode: userModeDictionaty[response.user_selection_mode],
    lockerService: response.locker_service,
    lockerBack: response.locker_back,
    lockerLk: response.locker_lk
  }
}
