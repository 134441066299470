import React, { FC } from 'react'
import classNames from 'classnames'
import style from './style/index.module.scss'

interface SwitchType {
  onChange: () => void
  checked: boolean
}

export const Switch: FC<SwitchType> = (props) => {
  const { onChange, checked } = props

  return (
    <div
      className={classNames(style.switch, {
        [style.switch_active]: checked
      })}
    >
      <input
        onClick={onChange}
        type='checkbox'
        className={style.switch__input}
      />
    </div>
  )
}
