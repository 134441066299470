import React, { FC } from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { Typography } from '@/views/typography'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import styles from './style/index.module.scss'
import { Spinner } from '../spinner'

interface TNetworkMessage {
  icon: string
  message: string
  spinner?: boolean
}

export const NetworkMessage: FC<TNetworkMessage> = ({
  icon,
  message,
  spinner = false
}) => {
  return (
    <CSSTransitionGroup className={styles.container}>
      {spinner
        ? <Spinner size='medium' />
        : <GxIcon
            src={icon}
            className={styles.container__icon}
          />}
      <Typography
        size={80}
        weight={700}
        className={styles.container__message}
      >
        {message}
      </Typography>
    </CSSTransitionGroup>
  )
}
