import React, { memo, useEffect, useState } from 'react'
import { GxSpinner } from '@garpix/garpix-web-components-react'
/* Components */
import MainLayout from '@/components/mainLayout/MainLayout'
import CardEquipment from '@/components/choiceEquipment/cardEquipment/CardEquipment'
import Paginator from '@/components/paginator/Paginator'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
/* Views */
import { Typography } from '@/views/typography'
import { BOTTOM_CONTENT_TYPES, ModalBottom } from '@/views/ModalBottom'
/* Helpers */
import { IEquipmentCell } from '@/api/interfaces/Equipment'
import useThrottle from '@/hooks/useThrottle'
import {
  useSiteConfig,
  useStores
} from '@/hooks'
import { log } from '@/utils'
import { DEFAULT_LINKS } from '@/const'
/* Styles */
import styles from './styles/index.module.scss'
import { useNavigate } from 'react-router-dom'

interface IData {
  total: number
  currentPage: number
  results: IEquipmentCell[]
}

interface ISelectvalue {
  value: string
  label: string
}

interface ICellList {
  cells: IEquipmentCell[] | null
  total: number
  pageSize: number
  currentPage: number
  changeNumber: (pageNum: number) => void
  onClick: (num: number) => void
}

const selectValues = {
  open: { value: 'open', label: 'Открыть' },
  enable: { value: 'enable', label: 'Включить' },
  disable: { value: 'disable', label: 'Отключить' }
}

const selectForDisabledCell = [selectValues.enable, selectValues.open]
const selectForActiveCell = [selectValues.open, selectValues.disable]

const CellsList: React.FC<ICellList> = memo(({ cells, changeNumber, onClick, ...props }) => {
  return (
    <CSSTransitionGroup
      className={styles.container__content}
    >
      <div className={styles.container__main}>
        {(cells != null)
          ? cells.map((elem) => {
            return (
              <CardEquipment key={elem.id} onClick={onClick} {...elem} />
            )
          })
          : null}
      </div>
      <Paginator changePage={changeNumber} {...props} />
    </CSSTransitionGroup>
  )
})

const EditCells: React.FC = (): React.ReactElement => {
  const navigate = useNavigate()
  const store = useStores()
  const config = useSiteConfig()

  /** @info Индикатор идет ли загрузка */
  const [isLoading, setIsLoading] = useState<boolean | null>(null)
  /** @info Индикатор идет ли первая загрузка после MOUNT */
  const [isFirstLoading, setIsFirstLoading] = useState<boolean | null>(null)
  /** @info Список ячеек (data.results) */
  const [cellList, setCellList] = useState<IEquipmentCell[] | null>(null)
  /** @info Все данные с запроса на список ячеек */
  const [data, setData] = useState<IData | null>(null)

  const [clickedCell, setClickedCell] = useState<IEquipmentCell | null>(null)
  const [selectValue, setselectValue] = useState<ISelectvalue | null>(null)
  const [bottomModalType, setBottomModalType] =
    useState<string | undefined>()
  /** @info Опции для запроса ячеек */
  const [options, setOptions] = useState({
    currentPage: 1,
    pageSize: 20,
    total: 0
  }) // параметры для пагинации

  const handleChangeSelect = (val: ISelectvalue): void => {
    setselectValue(val)
  }

  const openModalSelectAction = (): void => {
    setBottomModalType(BOTTOM_CONTENT_TYPES.CHOOSE_ACTION_CELL)
  }

  const openModalConfirmAction = (): void => {
    setBottomModalType(BOTTOM_CONTENT_TYPES.CONFIRM_ACTION_CELL)
  }

  const closeModal = (): void => {
    setBottomModalType(undefined)
  }
  const handleCloseModal = (): void => {
    closeModal()
    setClickedCell(null)
  }

  const onClickCell = (num: number): void => {
    if (cellList === null) return
    const clickedCell = cellList.find(el => el.cellNumber === num)
    if (clickedCell === undefined) return
    setClickedCell(clickedCell)
  }

  /** @info Обработчик перед запросом ставит лоадеры в true */
  const beforeRequest = (): void => {
    if (isFirstLoading === null) {
      setIsFirstLoading(true)
    }
    setIsLoading(true)
  }

  /** @info Обработчик успешного запроса учтановка результата в data */
  const afterSuccess = (data: IData): void => {
    setData(data)
  }

  /** @info Обработчик Ошибки Яркое сообщение в консоли */
  const afterError = (): void => {
    log('ERROR LOAD CELL LIST', 'error')
  }

  /** @info Обработчик в конце запроса установка индикаторов загрузки в false */
  const finallyRequest = (): void => {
    if (isFirstLoading === true) {
      setIsLoading(false)
    }
    setIsFirstLoading(false)
  }

  /** @info Запрос и обработка списка ячеек
   * если в queryParams небыло ничего переданно то ниче не грузим для отсечения загруки с initParams (весь список ячеек)
   */
  const fetchCellList = (props): void => {
    beforeRequest()
    store.api.equipment.getEquipmentList(
      {
        page: options.currentPage
      }
    )
      .then((data) => {
        afterSuccess(data)
      })
      .catch((e) => {
        afterError()
      })
  }

  const decorAllowFetch = (): void => {
    // if (!Object.values(qp).some((el) => el !== null)) return
    throttledFetchList()
  }

  const throttledFetchList = useThrottle(fetchCellList, 500)

  /**
   * функция для изменения страниц
   */
  const changeNumber = (pageNum: number): void => {
    setOptions({
      ...options,
      currentPage: pageNum
    })
  }

  const handleConfirmModal = async (): Promise<void> => {
    if (bottomModalType === BOTTOM_CONTENT_TYPES.CHOOSE_ACTION_CELL) {
      openModalConfirmAction()
    } else if (bottomModalType === BOTTOM_CONTENT_TYPES.CONFIRM_ACTION_CELL) {
      if (selectValue === null || clickedCell === null) return
      const selectApi = {
        [selectValues.disable.value]: store.api.cell.disableCell,
        [selectValues.enable.value]: store.api.cell.enableCell
      }
      /**
       * @info
       * * открытие ячейки на стр открытия ячеки
       */
      if (selectValue.value === selectValues.open.value) {
        return navigate(clickedCell.url)
      }

      if (selectValue.value in selectApi) {
        await selectApi[selectValue.value]({ id: clickedCell.cellNumber })

        decorAllowFetch()
        handleCloseModal()
      }
    }
  }

  useEffect(() => {
    if (clickedCell === null) return
    openModalSelectAction()
    setselectValue(!clickedCell?.is_blocked ? selectForActiveCell[0] : selectForDisabledCell[0])
  }, [clickedCell])

  /**
   * сохраняем общее кол-во элементов, которое пришло с бэка в стейт
   */
  useEffect(() => {
    setOptions({
      ...options,
      total: data?.total ?? 0
    })
  }, [data?.total])

  /** @info При изменении qp обновить данные */
  useEffect(() => {
    decorAllowFetch()
  }, [options])

  /** @info Рендер идет по cellList -> если они обновились значит точно все работа над запросом закончена */
  useEffect(() => {
    if (isLoading === true) {
      finallyRequest()
    }
  }, [cellList])

  /** @info Установка списка ячеек из всего запроса */
  useEffect(() => {
    if (data !== null && data !== undefined) {
      setCellList(data.results)
    }
  }, [data])

  // const lenList = cellList?.length

  const modalParams = {
    onClose: handleCloseModal,
    selectValue,
    selectOptions: !(clickedCell?.is_blocked ?? false) ? selectForActiveCell : selectForDisabledCell,
    onChange: handleChangeSelect,
    onClick: handleConfirmModal,
    title: `Вы действительно хотите
    ${String(selectValue?.label.toLocaleLowerCase())}: ячейку ${Number(clickedCell?.cell_number)}?`
  }

  return (
    <MainLayout linkBack={config?.links?.main_menu_net ?? DEFAULT_LINKS.main_menu_net}>
      <div className={styles.container}>
        <CSSTransitionGroup
          animation='fadeInTop'
          component={Typography}
          size={60}
          weight={700}
        >
          Выберите ячейку
        </CSSTransitionGroup>
        {isFirstLoading === true
          ? (
            <GxSpinner
              className={styles.container__spinner}
              size='large'
            />
            )
          : (
            <CellsList cells={cellList} changeNumber={changeNumber} onClick={onClickCell} {...options} />
            )}
      </div>
      <ModalBottom
        isOpen={Boolean(bottomModalType)}
        contentType={bottomModalType}
        params={modalParams}
      />
    </MainLayout>
  )
}

export default EditCells
