export const mockListWifi1 = {
  count: 6,
  total_pages: 3,
  page: 1,
  next: 'null',
  previous: null,
  results: [
    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 1',
      is_password: false,
      signal_strength: 'HIGH',
      autoconnect: false,
      security: 'Открытая'
    },
    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 2',
      is_password: true,
      signal_strength: 'MIDDLE',
      autoconnect: false,
      security: 'WPA2-Personal'
    },
    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 3',
      is_password: false,
      signal_strength: 'LOW',
      autoconnect: false,
      security: 'Открытая'
    },
    {
      id: 4,
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 4',
      is_password: false,
      signal_strength: 'HIGH',
      autoconnect: false,
      security: 'Открытая'
    },

    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 5',
      is_password: true,
      signal_strength: 'MIDDLE',
      autoconnect: false,
      security: 'WPA2-Personal'
    },
    // {
    //   in_use: false,
    //   ssid: 'Сеть 6',
    //   bssid: 'Сеть 6',
    //   is_password: true,
    //   signal_strength: 'LOW',
    //   autoconnect: false,
    //   security: 'WPA2-Personal'
    // }
    {
      in_use: true,
      ssid: String(Math.random()),
      bssid: 'Сеть 123',
      autoconnect: false,
      is_password: false,
      signal_strength: 'MIDDLE',
      security: 'WPA2-Personal',
      mask: '192.168.31.1',
      gateway: '192.168.31.1',
      ip: '192.168.31.27',
      dns: '192.168.31.1'
    }
  ]
}

export const mockListWifi2 = {
  count: 6,
  total_pages: 3,
  page: 2,
  next: 'null',
  previous: 'null',
  results: [
    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 7',
      is_password: false,
      signal_strength: 'HIGH',
      autoconnect: false,
      security: 'Открытая'
    },
    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 8',
      is_password: true,
      signal_strength: 'MIDDLE',
      autoconnect: false,
      security: 'WPA2-Personal'
    },
    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 9',
      is_password: false,
      signal_strength: 'LOW',
      autoconnect: false,
      security: 'Открытая'
    },
    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 10',
      is_password: false,
      signal_strength: 'HIGH',
      autoconnect: false,
      security: 'Открытая'
    },

    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 11',
      is_password: true,
      signal_strength: 'MIDDLE',
      autoconnect: false,
      security: 'WPA2-Personal'
    },
    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 12',
      is_password: true,
      signal_strength: 'LOW',
      autoconnect: false,
      security: 'WPA2-Personal'
    }
  ]
}

export const mockListWifi3 = {
  count: 2,
  total_pages: 3,
  page: 3,
  next: null,
  previous: 'null',
  results: [
    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 13',
      is_password: false,
      signal_strength: 'HIGH',
      autoconnect: false,
      security: 'Открытая'
    },
    {
      in_use: false,
      ssid: String(Math.random()),
      bssid: 'Сеть 14',
      is_password: true,
      signal_strength: 'MIDDLE',
      autoconnect: false,
      security: 'WPA2-Personal'
    }
  ]
}

export const createMock1 = (): any => {
  return (
    {
      count: 6,
      total_pages: 3,
      page: 1,
      next: 'null',
      previous: null,
      results: [
        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 1',
          is_password: false,
          signal_strength: 'HIGH',
          autoconnect: false,
          security: 'Открытая'
        },
        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 2',
          is_password: true,
          signal_strength: 'MIDDLE',
          autoconnect: false,
          security: 'WPA2-Personal'
        },
        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 3',
          is_password: false,
          signal_strength: 'LOW',
          autoconnect: false,
          security: 'Открытая'
        },
        {
          id: 4,
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 4',
          is_password: false,
          signal_strength: 'HIGH',
          autoconnect: false,
          security: 'Открытая'
        },

        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 5',
          is_password: true,
          signal_strength: 'MIDDLE',
          autoconnect: false,
          security: 'WPA2-Personal'
        },
        // {
        //   in_use: false,
        //   ssid: 'Сеть 6',
        //   bssid: 'Сеть 6',
        //   is_password: true,
        //   signal_strength: 'LOW',
        //   autoconnect: false,
        //   security: 'WPA2-Personal'
        // }
        {
          in_use: true,
          ssid: String(Math.random()),
          bssid: 'Сеть 123',
          autoconnect: false,
          is_password: false,
          signal_strength: 'MIDDLE',
          security: 'WPA2-Personal',
          mask: '192.168.31.1',
          gateway: '192.168.31.1',
          ip: '192.168.31.27',
          dns: '192.168.31.1'
        }
      ]
    }
  )
}

export const createMock2 = (): any => {
  return (
    {
      count: 6,
      total_pages: 3,
      page: 2,
      next: 'null',
      previous: 'null',
      results: [
        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 7',
          is_password: false,
          signal_strength: 'HIGH',
          autoconnect: false,
          security: 'Открытая'
        },
        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 8',
          is_password: true,
          signal_strength: 'MIDDLE',
          autoconnect: false,
          security: 'WPA2-Personal'
        },
        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 9',
          is_password: false,
          signal_strength: 'LOW',
          autoconnect: false,
          security: 'Открытая'
        },
        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 10',
          is_password: false,
          signal_strength: 'HIGH',
          autoconnect: false,
          security: 'Открытая'
        },

        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 11',
          is_password: true,
          signal_strength: 'MIDDLE',
          autoconnect: false,
          security: 'WPA2-Personal'
        },
        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 12',
          is_password: true,
          signal_strength: 'LOW',
          autoconnect: false,
          security: 'WPA2-Personal'
        }
      ]
    }
  )
}

export const createMock3 = (): any => {
  return (
    {
      count: 2,
      total_pages: 3,
      page: 3,
      next: null,
      previous: 'null',
      results: [
        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 13',
          is_password: false,
          signal_strength: 'HIGH',
          autoconnect: false,
          security: 'Открытая'
        },
        {
          in_use: false,
          ssid: String(Math.random()),
          bssid: 'Сеть 14',
          is_password: true,
          signal_strength: 'MIDDLE',
          autoconnect: false,
          security: 'WPA2-Personal'
        }
      ]
    }
  )
}
