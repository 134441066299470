import React, { FC } from 'react'
import { Typography } from '@/views/typography'
import styles from './style/index.module.scss'

const EmptyTimer: FC = () => {
  return (
    <div className={styles.container}>
      <Typography
        size={100}
        className={styles.container__title}
      >
        0
      </Typography>
    </div>
  )
}

export default EmptyTimer
