export const infoErrors = {
  500: {
    title: 'Ошибка обращения к серверу',
    description:
      'Мы уже устраняем неисправность, попробуйте обновить страницу через некоторое время.  \n' +
      'Приносим извинения за временные неудобства!'
  },
  404: {
    title: 'Страница не найдена',
    description:
      'Страница, которую вы ищите, \n' +
      'не существует,  возможно она устарела или была удалена.'
  },
  403: {
    title: 'Страница не доступна',
    description:
      'Страница, которую вы ищите, \n' +
      'вам не доступна,  возможно она устарела или была удалена.'
  }
}
