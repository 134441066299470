import { makeAutoObservable } from 'mobx'
import { RootStore } from './Root'

export class SpinnerStore {
  isShow: boolean
  rootStore: RootStore

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.isShow = false
    makeAutoObservable(this)
  }

  show = (): void => {
    this.isShow = true
  }

  hide = (): void => {
    this.isShow = false
  }

  isOpen = (): boolean => Boolean(this.isShow)
}
