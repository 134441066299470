import AbsractApi from '.'
import { DEFAULT_LINKS, STATUS_CODES } from '@/const'

interface TErrorParams {
  isRedirect500?: boolean
  isRedirect404?: boolean
}

class ErrorApiOverride {
  ctx: AbsractApi
  constructor (ctx: AbsractApi) {
    this.ctx = ctx
  }

  onError500 = (): void => {
    window.location.replace(DEFAULT_LINKS.error_server)
  }

  onError404 = (): void => {
    window.location.replace(DEFAULT_LINKS.not_found)
  }

  handleErrorRedirect = (
    err: any,
    errorParams: TErrorParams = {
      isRedirect500: true,
      isRedirect404: false
    }
  ): void => {
    if (
      err.request.status === STATUS_CODES._500 &&
      Boolean(errorParams?.isRedirect500)
    ) {
      this.onError500()
    }
    if (
      err.request.status === STATUS_CODES._404 &&
      Boolean(errorParams?.isRedirect404)
    ) {
      this.onError404()
    }
  }
}

export default ErrorApiOverride
