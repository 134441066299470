import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { Typography } from '@/views/typography'
import { IEquipmentCell } from '@/api/interfaces/Equipment'
import { useCurrentUser } from '@/hooks'
import { CELL, USER_ROLES } from '@/const'
import styles from './style/index.module.scss'
import { renderText } from './renderText'

interface IProps extends IEquipmentCell {
  onClick?: (num: number) => void
}

const CardEquipment: FC<IProps> = ({
  onClick,
  cellNumber,
  url = '#',
  equipmentTitle,
  equipmentDesc,
  error = false,
  is_blocked: isBlocked,
  is_reserved: isReserved,
  reservedDesc,
  reservedTitle,
  ...props
}) => {
  const user = useCurrentUser()

  const isEquipment =
    props.cell_status === CELL.STATUS.OCCUPIED &&
    Boolean(props.equipment)

  const handleClick = (e: React.MouseEvent): void => {
    if (onClick !== undefined) {
      e.preventDefault()
      onClick(cellNumber)
    }
  }
  /**
   * !TODO: Поправить специфичность
   */
  const showReservedCard =
    user.role === USER_ROLES.engineer && isReserved
  const isTemporarilyRented =
    props.content_status === CELL.CONTENT.TEMP_RENT &&
    isEquipment

  const cardTitle = isBlocked
    ? 'Выключена'
    : renderText({
      isEquipment,
      showReserved: showReservedCard,
      equipmentText: equipmentTitle,
      reservedText: reservedTitle,
      defaultText: 'Пусто'
    })
  const cardDesc =
    !isBlocked &&
    renderText({
      isEquipment,
      showReserved: showReservedCard,
      equipmentText: equipmentDesc,
      reservedText: reservedDesc
    })

  return (
    <NavLink
      to={url}
      className={styles.card}
      onClick={handleClick}
    >
      <Typography
        size={36}
        className={classNames(styles.card__num, {
          [styles.card__num_equip]: isEquipment,
          [styles.card__num_error]:
            props.content_status ===
              CELL.CONTENT.NOT_WORKING && isEquipment,
          [styles.card__num_empty]: !isEquipment,
          [styles.card__num_blocked]: isBlocked,
          [styles.card__num_reserved]: showReservedCard,
          [styles.card__num_temp_rentend]:
            isTemporarilyRented
        })}
      >
        {cellNumber}
      </Typography>
      <Typography
        size={30}
        weight={700}
        className={styles.card_label}
      >
        {cardTitle}
      </Typography>
      <Typography
        size={24}
        color='gray'
        className={styles.card_serial}
      >
        {cardDesc}
      </Typography>
    </NavLink>
  )
}

export default CardEquipment
