import { IObjectPage } from '@/interfaces/ObjectPage'
import { usePage as usePageCms } from '@garpix/cms'

const useObjectPage = (): IObjectPage => {
  const value = usePageCms()
  const page = value?.page

  return {
    ...page.init_state.object,
    equipment: page.init_state?.equipment
  }
}

export default useObjectPage
