import React from 'react'
import { useSiteConfig } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import MainLayout from '@/components/mainLayout/MainLayout'
import { usePage } from '@garpix/cms'
import CSSTransitionGroup from '@/components/cssTransitionGroup/CSSTransitionGroup'
import style from './styles/index.module.scss'

const LoadableEquipmentPage: React.FC =
  (): React.ReactElement => {
    const navigate = useNavigate()
    const page = usePage()
    const config = useSiteConfig()

    /**
     * обработчик нажатий на кнопку вовзращения в меню
     */
    const handleClickBackToMenu = (): void => {
      navigate(config.links.verification)
    }

    /**
     * обработчик нажатий на кнопку для последущего редиректа -
     * на страницу выбора ячейки
     */
    const handleClickLoadMore = (): void => {
      navigate(page?.page?.init_state?.url_still)
    }

    return (
      <MainLayout>
        <div className={style.content__container}>
          <CSSTransitionGroup
            component={Typography}
            animation='fadeInTop'
            size={80}
            className={style.content__container_title}
          >
            Загрузить еще оборудование?
          </CSSTransitionGroup>
          <CSSTransitionGroup
            component={Button}
            onClick={handleClickLoadMore}
          >
            Загрузить
          </CSSTransitionGroup>
          <CSSTransitionGroup
            component={Button}
            color='white'
            onClick={handleClickBackToMenu}
          >
            Вернуться в меню
          </CSSTransitionGroup>
        </div>
      </MainLayout>
    )
  }

export default LoadableEquipmentPage
