import React, { useEffect, useState } from 'react'
import usePage from '@/hooks/usePage'
import NetworkConnectContainer from '@/components/connectNetwork'
import MainLayout from '@/components/mainLayout/MainLayout'
import WiFiList from '@/components/wifiList/WiFiList'
import { NetworkMessage } from '@/views/networkMessage'
import { useSiteConfig, useStores } from '@/hooks'
import { WiFiIcon } from '@/assets/icon'
import { DEFAULT_LINKS, EMPTY_CONSTS } from '@/const'
import { IWifi } from '@/api/interfaces/Network'
import { observer } from 'mobx-react-lite'
import { log } from '@/utils'

const LOADER_TIME = 5000

const NetworkConfigurationWiFi: React.FC =
  (): React.ReactElement => {
    const { api } = useStores()
    const page = usePage()
    const config = useSiteConfig()

    const [isWiFiEnable, setIsWiFiEnable] = useState<boolean>(
      page?.init_state?.connected_wifi) // is wifi Enable
    const [wifiToConnect, setWifiToConnect] = useState<IWifi | null>(EMPTY_CONSTS.NULL)
    const [isLoading, setIsLoading] = useState(false)
    const resetWifiToConnect = (): void => {
      setWifiToConnect(null)
    }
    let timeout
    /**
     * Ручка изменения статуса
     */
    const changeWiFiStatus = (value: boolean): void => {
      void setIsWiFiEnable(value)
    }

    /**
     * Обработчик перед запросом на connectToWiFiTurnOn
     */
    const beforeFetch = (): boolean => {
      const newStatus = !isWiFiEnable
      if (!isWiFiEnable) void setIsLoading(newStatus)
      return newStatus
    }

    /**
     * Обработчик resolve connectToWiFiTurnOn
     */
    const afterSuccess = (loaderStatus: boolean): void => {
      // если включение вайфая то лоадер на 5 сек
      const timer = loaderStatus ? LOADER_TIME : 0
      setTimeout(() => {
        setIsLoading(false)
      }, timer)
    }

    /**
 * Обработчик resolve connectToWiFiTurnOn
 */
    const afterError = (err: any): void => {
      log('ERROR toggleSwitch', 'error')
      console.log(err)
    }

    /**
   *   Включение Wifi модуля wiFiTurnOn
   */
    const enableWifi = (newloadingStatus: boolean): void => {
      api.network.wiFiTurnOn().then(() => {
        changeWiFiStatus(!isWiFiEnable)
        afterSuccess(newloadingStatus)
      })
        .catch(afterError)
    }
    /**
   *   Выключение Wifi модуля wiFiTurnOff
   */
    const disableWifi = (): void => {
      api.network.wiFiTurnOff().then(() => {
        void changeWiFiStatus(!isWiFiEnable)
      })
        .catch(afterError)
    }

    /**
     * Хэнллер Обработки события Свитчера
     * включения и выглючния вайфай модуля
     * @param e
     */
    const toggleSwitch = (): void => {
      // если предыдущий запрос не закончился то игнор
      if (isLoading) return
      const newloadingStatus = beforeFetch()
      // если включение вайфая то сет лоадинг
      if (isWiFiEnable) {
        disableWifi()
      } else {
        enableWifi(newloadingStatus)
      }
    }

    const linkBack = wifiToConnect !== null ? undefined : config?.links?.net_settings ?? DEFAULT_LINKS.net_settings
    const message = isLoading ? 'Включение модуля Wi-Fi' : 'Чтобы посмотреть доступные сети, включите Wi-Fi'
    /**
     * Чистка таймаута при UnMount
     */
    useEffect(() => {
      return () => {
        clearTimeout(timeout)
      }
    }, [])

    return (
      <MainLayout linkBack={linkBack} handleBack={resetWifiToConnect}>
        <NetworkConnectContainer
          label='Подключение Wi-Fi'
          isConnect={isWiFiEnable}
          toggleSwitch={toggleSwitch}
        >
          {isWiFiEnable && !isLoading
            ? (
              <WiFiList
                wifiToConnect={wifiToConnect}
                setWifiToConnect={setWifiToConnect}
              />
              )
            : (
              <NetworkMessage
                message={message}
                icon={WiFiIcon}
                spinner={isLoading}
              />
              )}
        </NetworkConnectContainer>
      </MainLayout>
    )
  }

export default observer(NetworkConfigurationWiFi)
